import { StyledWrapperPagination, StyledPagination } from './Pagination.styled';

interface IPaginationProps {
  count: number;
  pageNumber: number;
  onChange: (pageNumber: number) => void;
}

const PaginationPages = ({ count, pageNumber, onChange }: IPaginationProps) => {
  const validPageNumber = Number.isInteger(pageNumber) ? pageNumber : 1;
  const validCount = Number.isInteger(count) ? count : 0;

  const onChangePage = (event: React.ChangeEvent<unknown>, value: number) =>
    onChange && onChange(value);

  return (
    <>
      {validCount > 1 && (
        <StyledWrapperPagination spacing={1}>
          <StyledPagination
            boundaryCount={1}
            siblingCount={0}
            count={validCount}
            page={validPageNumber}
            onChange={onChangePage}
          />
        </StyledWrapperPagination>
      )}
    </>
  );
};

export default PaginationPages;
