import { Button, ButtonProps, styled } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import { ISTOK_WEB } from 'constants/fonts';

interface IBoxProps extends BoxProps {
  top?: string;
  right?: string;
  left?: string;
  bottom?: string;
}

export const ButtonsWrapper = styled(Box)<IBoxProps>(
  ({ top, right, bottom, left }) => ({
    display: 'flex',
    gap: '20px',
    flexDirection: 'row',
    height: 'fit-content',
    marginRight: right,
    marginLeft: left,
    marginBottom: bottom,
    marginTop: top,
  })
);

export const ButtonSetUpLater = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.secondary.light,
  fontFamily: ISTOK_WEB,
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '21px',
  letterSpacing: '-0.308px',
  '&::after': {
    content: '""',
    position: 'absolute',
    left: '5px',
    bottom: '12px',
    width: '90%',
    height: '1px',
    backgroundColor: theme.palette.secondary.light,
    transition: 'transform 0.3s ease',
  },
  '&:hover::after': {
    transform: 'scaleX(1.05)',
  },
}));
