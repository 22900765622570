import { useState } from 'react';
import { Box } from '@mui/material';
import { ADMIN } from 'constants/organizationsType';
import { CONNECTED_USER_HEAD_TABLE } from 'constants/tablesHead';
import { useNavigate } from 'react-router';
import { ROUTES } from 'routes/routes.const';
import { theme } from 'theme';
import { ChartBars, Section, SelectAction, TableBasis } from 'ui';
import { useAppSelector } from 'hooks';
import { getUsers } from 'reduxStore/users/usersSelectors';
import { getUpdatedUsersData } from 'utils';

const DashboardConnectedUsersView = () => {
  const [isWarning, setIsWarning] = useState(false);

  const navigate = useNavigate();

  const usersList = useAppSelector(getUsers);
  const updatedUsers = getUpdatedUsersData(usersList);

  const handleDeleteUser = () => {
    setIsWarning(true);
  };

  return (
    <>
      <Section
        title="Connected Users"
        type="graph"
        paddingLeft="0px"
        paddingLeftTitle="40px"
        background={theme.palette.background.paper}
        action={<SelectAction version="periodOfTime" name="Last Month" />}
      >
        <ChartBars
          dataset={[]}
          textTooltipAction="connected"
          textTooltip="users"
        />
      </Section>
      <Box width="100%" marginTop="33px" position="relative">
        <TableBasis
          title="User List"
          tableData={updatedUsers || []}
          columns={CONNECTED_USER_HEAD_TABLE}
          type={ADMIN}
          isCheckbox="true"
          icon="edit"
          isWarning={isWarning}
        />
        <Box position="absolute" top="20px" right="40px">
          <SelectAction
            version="actionUser"
            name="Action"
            onDelete={handleDeleteUser}
            onEdit={() => navigate(ROUTES.USERS_ADD_USER)}
          />
        </Box>
      </Box>
    </>
  );
};

export default DashboardConnectedUsersView;
