import {
  Box,
  BoxProps,
  Typography,
  TypographyProps,
  styled,
} from '@mui/material';
import { ROBOTO } from 'constants/fonts';

export const StyledWrapperCollors = styled(Box)<BoxProps>(() => ({
  boxSizing: 'border-box',
  width: '374px',
  height: '314px',
  padding: '20px 21px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledWrapperLable = styled(Box)<BoxProps>(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const StyledLable = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  marginBottom: '8px',
}));

export const StyledColorDot = styled(Box)<BoxProps>(() => ({
  width: '9px',
  height: '9px',
  borderRadius: '50%',
}));

export const StyledText = styled(Typography)<TypographyProps>(() => ({
  fontFamily: ROBOTO,
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '16px',
  letterSpacing: '0.15px',
}));
