import { Stack, Box } from '@mui/material';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import {
  StyledWrapper,
  StyledDivider,
  StyledWrapperActions,
  StyledTitle,
  StyledText,
  StyledWrapperIcon,
  StyledLine,
} from './DeleteGroupConfirm.styled';
import { FlatButton } from 'ui/buttons';
import { IconTrash } from 'ui/icons';
import { useAppDispatch } from 'hooks';
import {
  closeModal,
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';
import { StyledTextField } from 'ui/inputs/Input/Input.styled';

interface IDeleteGroupProps {
  status?: 'error' | 'success';
  textButton?: string;
  confirm?: 'true';
  endpointName?: string;
  titleConfirm?: string;
  textConfirm?: string;
}

const DeleteGroupConfirm = ({
  status,
  textButton,
  confirm,
  endpointName,
  titleConfirm,
  textConfirm,
}: IDeleteGroupProps) => {
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleClickNext = () => {
    if (status === 'error') {
      dispatch(openModal());
      dispatch(setContent('DeletedSuccess'));
      dispatch(setModalProps({ status: 'success', radius: '16px' }));
    } else {
      handleCloseModal();
    }
  };

  return (
    <StyledWrapper>
      <StyledLine />
      <Stack
        direction="row"
        spacing="36px"
        justifyContent="flex-start"
        alignItems="flex-start"
        padding="40px"
      >
        <StyledWrapperIcon status={status}>
          {status === 'error' ? (
            <IconTrash />
          ) : (
            <CheckOutlinedIcon color="primary" fontSize="large" />
          )}
        </StyledWrapperIcon>
        <Box>
          <StyledTitle>{titleConfirm}</StyledTitle>
          {status === 'error' ? (
            <StyledText>{textConfirm}</StyledText>
          ) : (
            <StyledText>
              Deleted successfully. Redirecting back <br />
              to the page...
            </StyledText>
          )}
          {confirm ? (
            <StyledText confirm="true">"DELETE GROUP"</StyledText>
          ) : (
            <StyledText textTransform={'uppercase'}>
              {endpointName ? `“{${endpointName}”}?` : ''}
            </StyledText>
          )}
          {confirm && (
            <Box paddingTop="33px" width="432px">
              <StyledTextField label="aaaa" />
            </Box>
          )}
        </Box>
      </Stack>
      <StyledDivider />
      <StyledWrapperActions>
        {status === 'error' && (
          <FlatButton variant="outlined" onClick={handleCloseModal}>
            Cancel
          </FlatButton>
        )}
        <FlatButton variant="contained" onClick={handleClickNext}>
          {textButton}
        </FlatButton>
      </StyledWrapperActions>
    </StyledWrapper>
  );
};

export default DeleteGroupConfirm;
