import { useState, useEffect, useMemo } from 'react';
import { Divider, MenuItem, Typography, Box } from '@mui/material';
import {
  WrapperSelect,
  SelectContainer,
  LabelSelect,
  SelectField,
  IconItemListMenu,
  ItemListMenu,
  SubHeaderList,
  WrapperListItem,
  WrapperItem,
} from './SelectOrganization.styled';
import { IconDots } from 'ui';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  getClients,
  getIsMSP,
  getMainOrganization,
} from 'reduxStore/organization/organizationSelectors';
import { theme } from 'theme';
import { IClient } from 'types/organizations.type';
import { IOrganization } from 'types/organizations.type';
import {
  resetSelectedClient,
  setSelectedClientRedux,
} from 'reduxStore/organization/organizationSlice';
import { AddButton } from 'ui/buttons/actionButtons';
import {
  setCreatingOrganization,
  setOrganizationLevel,
} from 'reduxStore/createProfile/createProfileSlice';
import { CHILD } from 'constants/organizationsType';
import { ROUTES } from 'routes/routes.const';
import { useNavigate } from 'react-router';

const SelectOrganization = () => {
  const [selectedClient, setSelectedClient] = useState<string>('');
  const [isSelectActive, setIsSelectActive] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [value, setValue] = useState<string>('');

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const mainOrganization: IOrganization | null =
    useAppSelector(getMainOrganization);
  const isMsp = useAppSelector(getIsMSP);
  const clients: IClient[] | null = useAppSelector(getClients);

  const organizationName = useMemo(
    () => mainOrganization?.name,
    [mainOrganization]
  );

  const selectedOrganization = useMemo(
    () => clients?.filter((client: IClient) => client.name === selectedClient),
    [clients, selectedClient]
  );

  useEffect(() => {
    if (!!selectedClient && selectedOrganization) {
      dispatch(setSelectedClientRedux(selectedOrganization));
    } else {
      dispatch(resetSelectedClient());
    }
    // eslint-disable-next-line
  }, [selectedClient, clients]);

  useEffect(() => {
    if (!mainOrganization) {
      setValue(selectedClient || '');
    } else if (isMsp && selectedClient) {
      setValue(`${organizationName} / ${selectedClient}`);
    } else {
      setValue(organizationName || '');
    }
  }, [selectedClient, organizationName, isMsp, mainOrganization]);

  const handleSelect = (value: string) => {
    setSelectedClient(value);
    setIsSelectActive(false);
  };

  const handleToggleExpand = () => {
    setExpanded(prevState => !prevState);
  };

  const handleSelectParentOrganization = () => {
    if (expanded) {
      setIsSelectActive(false);
      setSelectedClient('');
    }
  };

  return (
    <WrapperSelect>
      <SelectContainer
        variant="outlined"
        isactive={isSelectActive ? 'true' : 'false'}
      >
        <LabelSelect>Now Managing</LabelSelect>

        <SelectField
          open={isSelectActive}
          onOpen={() => (isMsp || clients) && setIsSelectActive(true)}
          value={value}
          hideicon={!isMsp || !clients ? 'true' : 'false'}
          label="Select Company"
          MenuProps={{
            PaperProps: {
              style: {
                width: '291px',
                maxHeight: '321px',
                borderBottomLeftRadius: '26px',
                borderBottomRightRadius: '26px',
                background: theme.palette.blue.light,
                border: `1px solid ${theme.palette.blue.light}`,
                overflow: 'auto',
                boxShadow:
                  '0px 50px 100px -20px rgba(50, 50, 93, 0.25), 0px 30px 60px -30px rgba(0, 0, 0, 0.30)',
                transform: 'translateY(-2px)',
              },
            },
            sx: {
              '.MuiList-root.MuiMenu-list': {
                paddingTop: isMsp ? 0 : '8px',
                paddingBottom: isMsp ? 0 : '8px',
              },
            },
          }}
          onClose={() => setIsSelectActive(false)}
        >
          <MenuItem sx={{ display: 'none' }} value={value}>
            <IconItemListMenu isactive="true" isselected="true" />
            {!selectedClient
              ? organizationName || ''
              : isMsp && `${organizationName}/`}
            <Typography color={theme.palette.primary.main}>
              {selectedClient}
            </Typography>
          </MenuItem>

          {isMsp && mainOrganization ? (
            <WrapperListItem>
              <SubHeaderList
                onClick={handleToggleExpand}
                onDoubleClick={handleSelectParentOrganization}
              >
                <Box>
                  <IconDots />
                  {organizationName}
                </Box>
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </SubHeaderList>
              {expanded && (
                <Divider
                  sx={{
                    marginRight: '8px',
                    marginLeft: '8px',
                    border: '1px solid',
                    borderColor: '#B7B7B7',
                  }}
                />
              )}
              {expanded &&
                (clients != null && clients.length > 0 ? (
                  clients.map((client: IClient) => (
                    <ItemListMenu
                      key={client.name}
                      value={client.name}
                      onClick={() => handleSelect(client.name)}
                    >
                      <IconItemListMenu
                        isactive={
                          selectedClient === client.name ? 'true' : 'false'
                        }
                      />
                      {client.name}
                    </ItemListMenu>
                  ))
                ) : (
                  <AddButton
                    color="white"
                    needsborder="false"
                    onClick={() => {
                      dispatch(setCreatingOrganization(true));
                      dispatch(setOrganizationLevel(CHILD));
                      navigate(ROUTES.ORGANIZATION_PROFILE);
                    }}
                  >
                    Add client
                  </AddButton>
                ))}
            </WrapperListItem>
          ) : (
            <WrapperItem>
              {clients?.map((client: IClient) => (
                <ItemListMenu
                  key={client.name}
                  value={client.name}
                  onClick={() => handleSelect(client.name)}
                >
                  <IconItemListMenu
                    isactive={selectedClient === client.name ? 'true' : 'false'}
                  />
                  {client.name}
                </ItemListMenu>
              ))}
            </WrapperItem>
          )}
        </SelectField>
      </SelectContainer>
    </WrapperSelect>
  );
};

export default SelectOrganization;
