import { createAsyncThunk } from '@reduxjs/toolkit';
import * as api from 'services/api/organization';

const getUserOrganizations = createAsyncThunk(
  'organization/getUserOrganizations',
  async (_, { rejectWithValue }) => {
    try {
      const result = await api.postGetUserOrganizations();
      return result;
    } catch (error: any) {
      console.error('Get user organizations error: ', error);
      return rejectWithValue(error.message);
    }
  }
);

export { getUserOrganizations };
