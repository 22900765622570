import {
  StyledWrapperTab,
  StyledWrapperTitle,
  StyledText,
  StyledData,
  StyledWrapperIcon,
} from './TabsForAdminMenu.styled';

interface ITabsAdminMenuProps {
  title: string;
  data: string;
  icon: JSX.Element;
}

const TabsForAdminMenu = ({ title, icon, data }: ITabsAdminMenuProps) => {
  return (
    <StyledWrapperTab>
      <StyledWrapperTitle>
        <StyledWrapperIcon>{icon}</StyledWrapperIcon>
        <StyledText>{title}</StyledText>
      </StyledWrapperTitle>
      <StyledData>{data}</StyledData>
    </StyledWrapperTab>
  );
};

export default TabsForAdminMenu;
