import { styled } from '@mui/material';
import { ReactComponent as IconSpeed } from '../../../assets/images/speed.svg';

interface IIconSpeedProps {
  size?: string;
}

export const StyledIconSpeed = styled(IconSpeed)<IIconSpeedProps>(
  ({ size }) => ({
    cursor: 'pointer',
    height: size || '40px',
    width: size || '40px',
    color: 'currentColor',
  })
);
