import { Box, BoxProps, styled } from '@mui/material';

export const StyledWrapperIcon = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  border: '1px solid currentColor',
}));
