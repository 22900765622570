import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Wrapper, Title, Content } from './Confirmation.styled';
import { FlatButton } from 'ui/buttons';
import { IconButton } from 'ui/icons';
import { Box } from '@mui/material';
import { useAppDispatch } from 'hooks';
import { closeModal } from 'reduxStore/modal/modalSlice';

const Confirmation = () => {
  const dispatch = useAppDispatch();

  const handleConfirm = () => {};

  const handleBack = () => {
    dispatch(closeModal());
  };

  return (
    <Wrapper>
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Title>Confirmation</Title>
        <IconButton width="34px" isColor onClick={handleBack}>
          <ArrowBackIcon color="primary" />
        </IconButton>
      </Box>
      <Box display="flex" justifyContent="center">
        <Content>
          Are you sure to change <br /> View to
          <Content iscontrast="true">Valvpn?</Content>
        </Content>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        gap="20px"
      >
        <FlatButton variant="outlined" height="47px" onClick={handleBack}>
          Revert back
        </FlatButton>
        <FlatButton variant="contained" height="47px" onClick={handleConfirm}>
          Yes
        </FlatButton>
      </Box>
    </Wrapper>
  );
};

export default Confirmation;
