import { ReactComponent as IconWindow } from '../../../assets/images/window.svg';
import { ReactComponent as IconWarningBlocked } from '../../../assets/images/warningBlocked.svg';
import { Box, BoxProps, styled } from '@mui/material';

export const StyledWrapperIcon = styled(Box)<BoxProps>(() => ({
  position: 'relative',
  width: '47px',
  height: '40px',
}));

export const StyledIconWarning = styled(IconWarningBlocked)(() => ({
  position: 'absolute',
  top: '10px',
  left: '60%',
  transform: 'translateX(-50%)',
  zIndex: 2,
  cursor: 'pointer',
  height: '26px',
  width: '26px',
  fill: 'currentColor',
}));

export const StyledIconWindow = styled(IconWindow)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 1,
  cursor: 'pointer',
  height: '40px',
  width: '47px',
  fill: 'currentColor',
}));
