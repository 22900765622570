import { StyledFlatButton } from './FlatButton.styled';
import AddIcon from '@mui/icons-material/Add';

interface IFlatButtonProps {
  variant?: 'contained' | 'outlined';
  type?: 'button' | 'submit' | 'reset';
  isIcon?: 'true';
  width?: string;
  height?: string;
  isDisabled?: boolean;
  onClick?: () => void;
  children: React.ReactNode | string;
}

const FlatButton = ({
  variant,
  type,
  isIcon,
  width,
  height,
  isDisabled,
  onClick,
  children,
}: IFlatButtonProps) => {
  return (
    <StyledFlatButton
      variant={variant}
      type={type}
      isicon={isIcon}
      disabled={isDisabled}
      width={width}
      height={height}
      onClick={onClick}
      endIcon={isIcon && <AddIcon fontSize="large" />}
    >
      {children}
    </StyledFlatButton>
  );
};

export default FlatButton;
