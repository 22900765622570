import {
  StyledWrapperProgress,
  StyledProgressCircularMain,
  StyledProgressCircularUnder,
  StyledInsideBlock,
  StyledCaption,
} from './ProgressCircle.styled';

interface IProgressProps {
  value: number;
  size: string;
  isCaption?: boolean;
}

const ProgressCircle = ({ value, size, isCaption }: IProgressProps) => {
  return (
    <StyledWrapperProgress>
      <StyledProgressCircularMain
        variant="determinate"
        value={value}
        size={size}
        thickness={3}
      />
      <StyledProgressCircularUnder
        variant="determinate"
        value={100}
        size={size}
        thickness={3}
      />
      {isCaption && (
        <StyledInsideBlock>
          <StyledCaption
            fontSize="27px"
            variant="caption"
            component="div"
            color="text.secondary"
          >{`${Math.round(value)}`}</StyledCaption>
          <StyledCaption fontSize="23px">%</StyledCaption>
        </StyledInsideBlock>
      )}
    </StyledWrapperProgress>
  );
};

export default ProgressCircle;
