import { RootState } from 'reduxStore/store';

const getIsCreatingOrganization = (state: RootState) =>
  state.createProfile.creatingOrganization;
const getOrganizationLevel = (state: RootState) =>
  state.createProfile.organizationLevel;

// create / update organization
const getOrganizationId = (state: RootState) =>
  state.createProfile.organizationID;
const getOrganizationValues = (state: RootState) =>
  state.createProfile.organizationValues;

// import users
const getUsersListImported = (state: RootState) =>
  state.createProfile.usersListImported;

const getCreateProfileError = (state: RootState) => state.createProfile.error;
const getClouds = (state: RootState) => state.createProfile.clouds;
export {
  getIsCreatingOrganization,
  getOrganizationLevel,
  getOrganizationId,
  getOrganizationValues,
  getUsersListImported,
  getCreateProfileError,
  getClouds,
};
