import { IconUserPlus, IconUserStar, IconEdit } from 'ui/icons';
import SyncIcon from '@mui/icons-material/Sync';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

export const ACTION_USER = [
  {
    title: 'Add User',
    icon: <IconUserPlus />,
  },
  {
    title: 'Sync',
    icon: <SyncIcon color="primary" />,
  },
  {
    title: 'Delete',
    icon: <DeleteForeverOutlinedIcon color="error" />,
  },
];

export const VARIANT2 = [
  {
    title: 'Edit',
    icon: <IconEdit version="action" />,
  },
  {
    title: 'Deploy Agent',
    icon: <IconUserStar />,
  },
  {
    title: 'Delete',
    icon: <DeleteForeverOutlinedIcon color="error" />,
  },
];

export const VARIANT3 = [
  { title: 'Last hour' },
  { title: 'Last 4 hours' },
  { title: 'Last 12 hours' },
  { title: 'Last 24 hours' },
  { title: 'Last 48 hours' },
  { title: 'Last 3 days' },
  { title: 'Last 7 days' },
  { title: 'Last 30 days' },
  { title: 'Custom' },
];
