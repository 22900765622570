import { ReactComponent as IconThreat } from '../../../assets/images/threatIcon.svg';
import { ReactComponent as IconSetting } from '../../../assets/images/setting.svg';
import { ReactComponent as IconWarning } from '../../../assets/images/warning.svg';
import { Box, BoxProps, styled } from '@mui/material';

export const StyledIconThreat = styled(IconThreat)(() => ({
  cursor: 'pointer',
  height: '24px',
  width: '24px',
}));

export const StyledWrapperIcon = styled(Box)<BoxProps>(() => ({
  position: 'relative',
  width: '21px',
  height: '21px',
}));

export const StyledIconWarning = styled(IconWarning)(() => ({
  position: 'absolute',
  top: '5px',
  left: '0px',
  zIndex: 2,
  cursor: 'pointer',
  height: '19px',
  width: '19px',
  fill: '#fff',
}));

export const StyledIconSettings = styled(IconSetting)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 1,
  cursor: 'pointer',
  height: '19px',
  width: '19px',
  fill: 'currentColor',
}));
