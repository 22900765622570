import { createAsyncThunk } from '@reduxjs/toolkit';
import * as api from 'services/api/createProfile';
import {
  ICreateOrganizationBody,
  IImportUsersBody,
  IUpdateOrganizationBody,
  ISetUpSelectedCloudsBody,
} from 'types/api.type';

const createOrganization = createAsyncThunk(
  'createProfile/createOrganization',
  async (body: ICreateOrganizationBody, { rejectWithValue }) => {
    try {
      const id = await api.postCreateOrganization(body);
      return id;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'Creating organization error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const updateOrganization = createAsyncThunk(
  'createProfile/updateOrganization',
  async (body: IUpdateOrganizationBody, { rejectWithValue }) => {
    try {
      await api.postUpdateOrganization(body);
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'Updating organization error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const importUsersList = createAsyncThunk(
  'createProfile/importUsers',
  async (body: IImportUsersBody, { rejectWithValue }) => {
    try {
      const result = await api.postImportUsers(body);
      return result;
    } catch (error: any) {
      console.error('Error:', error.message);
      return rejectWithValue(error.message);
    }
  }
);

const retrieveClouds = createAsyncThunk(
  'createProfile/retrieveClouds',
  async (orgId: number | null, { rejectWithValue }) => {
    try {
      const result = await api.postGetClouds(orgId);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving clouds error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);
const setUpClouds = createAsyncThunk(
  'createProfile/setUpClouds',
  async (body: ISetUpSelectedCloudsBody, { rejectWithValue }) => {
    try {
      const result = await api.postSetUpClouds(body);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'setting up clouds error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export {
  createOrganization,
  updateOrganization,
  importUsersList,
  retrieveClouds,
  setUpClouds,
};
