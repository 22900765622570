import { useEffect, useState, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import instance from '../services/api';

const useGetAPI = <T>(
  url: string,
  body?: { [key: string]: string | number }
) => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);

  const fetchData = useCallback(async () => {
    if (!url) {
      setLoading(false);
      setData(null);
      return;
    }
    try {
      setLoading(true);
      const response: AxiosResponse<T> = await instance.post(url, body);
      const { data } = response;
      if (data) {
        setData(data);
      } else {
        setData(null);
      }
    } catch (error: any) {
      setData(null);
      setError(error.response.data.message || 'Error');
    } finally {
      setLoading(false);
    }
  }, [url]); // eslint-disable-line

  useEffect(() => {
    fetchData();
  }, [fetchData, url]);

  return [data, loading, error, setData];
};

export default useGetAPI;
