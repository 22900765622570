import { Outlet } from 'react-router';

const SecurityGroupPage = () => {
  return (
    <>
      <div>Security Group Page</div>
      <Outlet />
    </>
  );
};

export default SecurityGroupPage;
