import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { StyledWrapperIcon } from './IconUsers.styled';

interface IIconProps {
  size?: 'large';
}

const IconUsers = ({ size }: IIconProps) => {
  return (
    <StyledWrapperIcon size={size}>
      <GroupsOutlinedIcon fontSize={size === 'large' ? 'large' : 'small'} />
    </StyledWrapperIcon>
  );
};

export default IconUsers;
