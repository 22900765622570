import { StyledIconHorn } from './IconHorn.styled';

interface IIconHornProps {
  size?: string;
}

const IconHorn = ({ size }: IIconHornProps) => {
  return <StyledIconHorn size={size} />;
};
export default IconHorn;
