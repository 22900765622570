import { StyledIconSpeed } from './IconSpeed.styled';

interface IIconSpeedProps {
  size?: string;
}

const IconSpeed = ({ size }: IIconSpeedProps) => {
  return <StyledIconSpeed size={size} />;
};

export default IconSpeed;
