import { Avatar, Box, Stack } from '@mui/material';
import { CompanyName, Subtitle } from './CompanyIdentity.styled';
import { IconEdit } from 'ui/icons';

interface CompanyIdentityProps {
  avatarUrl: string;
  companyName: string;
  subtitle?: string;
  onEdit?: () => void;
}

const CompanyIdentity = ({
  avatarUrl,
  companyName,
  subtitle,
  onEdit,
}: CompanyIdentityProps) => {
  return (
    <Box display="flex" alignItems="center" gap="12px">
      <Avatar
        src={avatarUrl ?? ''}
        alt={companyName}
        variant="square"
        sx={{ width: '60px', height: '68px' }}
      />
      <Stack spacing={'6px'}>
        <Box display="flex" alignItems="center" gap="52px">
          {subtitle && (
            <Subtitle variant="subtitle2" component="p" color="textSecondary">
              {subtitle}
            </Subtitle>
          )}
          {onEdit && (
            <IconEdit
              version="action"
              styles={{ width: '24px', height: '24px' }}
            />
          )}
        </Box>
        <CompanyName variant="h6">{companyName}</CompanyName>
      </Stack>
    </Box>
  );
};

export default CompanyIdentity;
