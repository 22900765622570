import { ITableColumn, ITableHeader } from 'types';

export const CONFIRMATION_IMPORTING_HEAD_TABLE: ITableColumn[] = [
  { label: 'First Name', key: 'first_name', disableSorting: false },
  { label: 'Last Name', key: 'last_name', disableSorting: false },
  { label: 'Department', key: 'department', disableSorting: false },
  { label: 'Title', key: 'title', disableSorting: false },
  { label: 'E-mail', key: 'email', disableSorting: false },
  // { label: 'Administrator', value: 'switch_admin', disableSorting: false },
];

export const CONNECTED_USER_HEAD_TABLE: ITableColumn[] = [
  { label: 'First Name', key: 'first_name', disableSorting: false },
  { label: 'Last Name', key: 'last_name', disableSorting: false },
  { label: 'E-mail', key: 'email', disableSorting: false },
  { label: 'Status', key: 'status', disableSorting: false },
  { label: 'Group', key: 'group', disableSorting: false },
  { label: 'Device', key: 'dev_count', disableSorting: false },
];

export const DASHBOARD_USER_CONNECTED_USER_EDIT_HEAD_TABLE: ITableColumn[] = [
  { label: 'Device Name', key: 'device name', disableSorting: false },
  { label: 'Platform', key: 'platform', disableSorting: false },
  { label: 'Ip Address', key: 'ip address', disableSorting: false },
  { label: 'Bandwidth Used', key: 'bandwidth used', disableSorting: false },
  { label: 'Status', key: 'status', disableSorting: false },
  { label: 'Up-Time', key: 'up-time', disableSorting: false },
];

export const DEVICE_LIST_HEAD_TABLE: ITableColumn[] = [
  { label: 'Device', key: 'device', disableSorting: false },
  {
    label: 'First & Last name',
    key: 'first & last name',
    disableSorting: false,
  },
  { label: 'Status', key: 'status', disableSorting: false },
  { label: 'Bandwidth', key: 'bandwidth', disableSorting: false },
];

export const DASHBOARD_MAIN_DEVICE_LIST_HEAD_TABLE: ITableColumn[] = [
  { label: 'Device Name', key: 'device', disableSorting: false },
  { label: 'Type', key: 'type', disableSorting: false },
  { label: 'Owner', key: 'owner', disableSorting: false },
  { label: 'IP Address', key: 'ipAddress', disableSorting: false },
  { label: 'Bandwidth Used', key: 'bandwidth', disableSorting: false },
  { label: 'Last Connected', key: 'lastConnected', disableSorting: false },
];

export const DASHBOARD_USER_CONNECTED_DEVICES_DEVICE_DETAILS_HEAD_TABLE: ITableColumn[] =
  [
    { label: 'Activity Name', key: 'activity name', disableSorting: false },
    { label: 'Status', key: 'status', disableSorting: false },
    { label: 'Logs', key: 'logs', disableSorting: false },
    { label: 'Packet Size', key: 'packet size', disableSorting: false },
  ];

export const TEMPLATE_DATA_FILE: ITableHeader[] = [
  { key: 'email', label: 'email*' },
  { key: 'first_name', label: 'First Name *' },
  { key: 'last_name', label: 'Last Name *' },
  { key: 'department', label: 'Department' },
  { key: 'title', label: 'Title' },
  { key: 'is_admin', label: 'is Admin?' },
];

export const ORGANIZATION_REGIONS: ITableHeader[] = [
  { label: 'Name', key: 'Name', format: 'uppercase' },
  { label: 'Cloud', key: 'Cloud', format: 'uppercase' },
  { label: 'Clients', key: 'Clients' },
  { label: 'Costs', key: 'Costs', format: '$' },
];

export const ORGANIZATION_CLIENTS: ITableHeader[] = [
  { label: 'Organization', key: 'name', format: 'uppercase' },
  { label: 'Admin Contact', key: 'contact_name', format: 'uppercase' },
  { label: 'Email', key: 'contact_email' },
  { label: 'Earnings', key: 'earnings', format: '$' },
  { label: 'Devices', key: 'dev_count' },
  { label: 'Status', key: 'status' },
];
