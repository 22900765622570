import { ITabMenuProps } from 'types';

export const initiateTabValueForNavTabs = (
  options: ITabMenuProps[],
  pathname: string,
  isMainNavigation?: boolean
) => {
  const path = isMainNavigation
    ? `/${pathname.split('/').filter(Boolean)[0]}`
    : pathname;
  const activeTabIndex = options.findIndex(option => option.url === path);

  return activeTabIndex !== -1 ? activeTabIndex : 0;
};
