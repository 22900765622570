import { styled } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import Stack, { StackProps } from '@mui/material/Stack';

export const StyledWrapper = styled(Stack)<StackProps>(() => ({
  background: 'transparent',
}));

export const StyledWrapperSearch = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '25px',
}));

export const StyledBoxScroll = styled(Box)<BoxProps>(({ theme }) => ({
  paddingRight: '3px',
  background: theme.palette.background.paper,
  borderRadius: '21px',
}));

export const StyledBoxTable = styled(Box)<BoxProps>(({ theme }) => ({
  borderRadius: '21px',
  paddingBottom: '13px',
  background: theme.palette.background.paper,
  paddingRight: '10px',
  overflowY: 'auto',

  '&::-webkit-scrollbar': {
    width: '8px',
    heidht: '301px',
  },

  '&::-webkit-scrollbar-track': {
    background: theme.palette.background.paper,
    borderRadius: '16px',
    marginTop: '12px',
  },

  '&::-webkit-scrollbar-thumb': {
    background: '#CECECE',
    borderRadius: '16px',
    width: '6px',
  },
}));
