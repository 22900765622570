import { styled } from '@mui/material';
import { ReactComponent as IconAmazon } from '../../../assets/images/amazon.svg';
import Box from '@mui/material/Box';

export const StyledWrapperBox = styled(Box)(({ theme }) => ({
  height: '46px',
  width: '46px',
  borderRadius: '50%',
  background: theme.palette.common.white,
  alignContent: 'center',
}));

export const StyledIconAmazon = styled(IconAmazon)(() => ({
  height: '43px',
  width: '29px',
}));
