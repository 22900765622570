import { styled } from '@mui/material';
import { Typography, TypographyProps } from '@mui/material';
import { Box, BoxProps } from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import OutputIcon from '@mui/icons-material/Output';
import { IButtonType } from 'types';
import { MSP } from 'constants/organizationsType';

interface IBoxProps extends BoxProps, IButtonType {}

interface IIconButtonProps extends IconButtonProps, IButtonType {}

interface ITypographyProps extends TypographyProps, IButtonType {}

export const StyledBox = styled(Box)<IBoxProps>(({ theme, buttontype }) => ({
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    color: buttontype === MSP ? theme.palette.primary.main : 'inherit',
    background: 'inherit',
    '& .MuiIconButton-root': {
      color: buttontype === MSP ? theme.palette.primary.main : 'inherit',
    },
    '& .MuiTypography-root': {
      color: buttontype === MSP ? theme.palette.primary.main : 'inherit',
    },
  },
}));

export const StyledIconButton = styled(IconButton)<IIconButtonProps>(
  ({ theme, buttontype }) => ({
    gap: '7px',
    color: theme.palette.silver.contrastText,
    '&:hover': {
      color: buttontype === MSP ? theme.palette.primary.main : 'inherit',
    },
  })
);

export const StyledOutputIcon = styled(OutputIcon)({
  color: 'inherit',
});

export const StyledTypography = styled(Typography)<ITypographyProps>(
  ({ theme, buttontype }) => ({
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '-0.352px',
    color:
      buttontype === MSP
        ? theme.palette.silver.contrastText
        : theme.palette.secondary.main,
  })
);
