import { get0AuthState } from "./sessionStorageHelpers";

// auth
export const validate0AuthState = (returnedState: string | null) => {
  if (!returnedState) return false;

  const savedState = get0AuthState();
  if (returnedState === savedState) {
    return true;
  } else {
    console.error('Validate State failed: Invalid auth state.');
    return false;
  }
};

// forms
export const isValidEmail = (email: string) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

export const hasValidDomain = (email: string, domain: string) => {
  const emailDomain = email.split('@')[1];
  return emailDomain === domain;
};
