import { Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SyncIcon from '@mui/icons-material/Sync';
import { IconButton, SelectAction, TableBasis } from 'ui';
import { Search } from 'ui/inputs';
import { useAppDispatch } from 'hooks';
import {
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';
import {
  StyledWrapper,
  StyledWrapperSearch,
  StyledBoxTable,
  StyledBoxScroll,
} from './UsersShowAllData.styled';
import { IAdminType, ITableColumn, ITableRow } from 'types';

interface IShowAllData extends IAdminType {
  icon?: 'edit' | 'remove' | 'check' | 'switch' | 'view';
  isCheckbox?: 'true';
  title?: string;
  columns: ITableColumn[];
  tableData: ITableRow[];
}

const UsersShowAllData = ({
  title,
  type,
  isCheckbox,
  icon,
  columns,
  // tableData
}: IShowAllData) => {
  const dispatch = useAppDispatch();

  const tableData = [
    {
      FirstName: 'TeroBlaze S1',
      LastName: 'PC',
      Email: '185.53.178.9',
      Status: '112.1MB',
      Group: 'Healthy',
      Device: '01/01/2023 11:12:00GMT',
    },
    {
      FirstName: 'TeroBlaze S1',
      LastName: 'PC',
      Email: '185.53.178.9',
      Status: '112.1MB',
      Group: 'Healthy',
      Device: '01/01/2023 11:12:00GMT',
    },
    {
      FirstName: 'TeroBlaze S1',
      LastName: 'PC',
      Email: '185.53.178.9',
      Status: '112.1MB',
      Group: 'Healthy',
      Device: '01/01/2023 11:12:00GMT',
    },
    {
      FirstName: 'TeroBlaze S1',
      LastName: 'PC',
      Email: '185.53.178.9',
      Status: '112.1MB',
      Group: 'Healthy',
      Device: '01/01/2023 11:12:00GMT',
    },
    {
      FirstName: 'TeroBlaze S1',
      LastName: 'PC',
      Email: '185.53.178.9',
      Status: '112.1MB',
      Group: 'Healthy',
      Device: '01/01/2023 11:12:00GMT',
    },
    {
      FirstName: 'TeroBlaze S1',
      LastName: 'PC',
      Email: '185.53.178.9',
      Status: '112.1MB',
      Group: 'Healthy',
      Device: '01/01/2023 11:12:00GMT',
    },
  ];

  const handleDeleteUser = () => {
    dispatch(openModal());
    dispatch(setContent('DeleteUserConfirm'));
    dispatch(
      setModalProps({
        status: 'error',
        radius: '16px',
        isNextClick: true,
        zIndex: 1000,
      })
    );
  };

  return (
    <StyledWrapper spacing="13px">
      <StyledWrapperSearch>
        <Search />
        <IconButton width="57px" titleTooltip="Add">
          <AddIcon color="primary" />
        </IconButton>
        <IconButton width="57px" titleTooltip="Synchronize">
          <SyncIcon color="primary" />
        </IconButton>
      </StyledWrapperSearch>

      <StyledBoxScroll>
        <StyledBoxTable
          position="relative"
          maxHeight="1011px"
          overflow="hidden"
          width="65vw"
        >
          <TableBasis
            title={title}
            type={type}
            isCheckbox={isCheckbox}
            icon={icon}
            columns={columns}
            tableData={tableData}
          />

          <Box position="absolute" top="20px" right="40px">
            <SelectAction
              version="actionUser"
              name="Action"
              onDelete={handleDeleteUser}
            />
          </Box>
        </StyledBoxTable>
      </StyledBoxScroll>
    </StyledWrapper>
  );
};

export default UsersShowAllData;
