import axios, {
  InternalAxiosRequestConfig,
  AxiosError,
  AxiosHeaders,
} from 'axios';
import { IAppStore } from '../../types';
import { resetAuth, setAuthError } from 'reduxStore/auth/authSlice';
import { AUTH_TIMEOUT } from 'constants/errors';

interface IServerResponse {
  reason?: string;
  message?: string;
}

let store: IAppStore;

export const injectStore = (_store: IAppStore): void => {
  store = _store;
};

const instance = axios.create({ baseURL: 'https://dev.api.kylada.com/v1/' });

instance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const state = store.getState();

    if (state.auth && state.auth.token) {
      config.headers = new AxiosHeaders({
        ...config.headers,
        Authorization: `Bearer ${state.auth.token}`,
      });
    }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => response,
  async (error: AxiosError) => {
    if (
      error.response &&
      error.response.status === 401 &&
      (error.response.data as IServerResponse).reason === 'invalid'
    ) {
      store.dispatch(resetAuth());
      store.dispatch(setAuthError(AUTH_TIMEOUT));
    }
    return Promise.reject(error);
  }
);

export default instance;
