import { ChangeEvent, forwardRef } from 'react';

interface IInputInvisible {
  accept: string;
  onUpload: (file: File) => void;
}

const InputInvisible = forwardRef<HTMLInputElement, IInputInvisible>(
  (props, ref) => {
    const { accept, onUpload } = props;

    const handleFile = (file: File) => {
      if (!file) return;
      onUpload(file);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (files && files.length > 0) {
        handleFile(files[0]);
      }
    };

    return (
      <input
        type="file"
        ref={ref}
        hidden
        accept={accept}
        onChange={handleChange}
      />
    );
  }
);

export default InputInvisible;
