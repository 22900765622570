import { styled } from '@mui/material';
import Table, { TableProps } from '@mui/material/Table';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableContainer, {
  TableContainerProps,
} from '@mui/material/TableContainer';
import TableHead, { TableHeadProps } from '@mui/material/TableHead';
import TableRow, { TableRowProps } from '@mui/material/TableRow';
import Box, { BoxProps } from '@mui/material/Box';
import { ISTOK_WEB } from 'constants/fonts';

export const StyledWrapperBox = styled(Box)<BoxProps>(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxHeight: '1152px',
}));

export const StyledTableContainer = styled(TableContainer)<TableContainerProps>(
  ({ theme }) => ({
    background: theme.palette.common.white,
    width: '100%',
    height: 'fit-content',
    maxHeight: '1052px',
    paddingTop: '43px',
  })
);

export const StyledTable = styled(Table)<TableProps>(() => ({
  borderSpacing: '0 9px',
}));

export const StyledTableHead = styled(TableHead)<TableHeadProps>(
  ({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    position: 'sticky',
    top: 0,
    zIndex: 1,
    marginBottom: '19px',
    cursor: 'pointer',
    '& .MuiTableCell-head:not(:first-of-type)': {
      textAlign: 'center',
    },
    '& .MuiTableCell-head': {
      color: theme.palette.silver.contrastText,
      fontFamily: ISTOK_WEB,
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '24px',
      letterSpacing: '-0.352px',
      textTransform: 'capitalize',
    },
  })
);

export const StyledTableRow = styled(TableRow)<TableRowProps>(({ theme }) => ({
  height: '50px',
  cursor: 'pointer',
  paddingLeft: '2px',
  paddingRight: '2px',
  borderBottom: '0.8px solid #F4EAF4',
  '&:hover': {
    backgroundColor: theme.palette.action.disabled,
  },
}));

export const StyledTableCell = styled(TableCell)<TableCellProps>(
  ({ theme }) => ({
    color: theme.palette.silver.contrastText,
    fontFamily: ISTOK_WEB,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.352px',
    border: 'none',
    '&:not(:first-of-type)': {
      textAlign: 'center',
    },
  })
);

export const StyledActionBlock = styled(Box)<BoxProps>(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  width: 'fit-content',
  height: 'fit-content',
  transform: 'translateY(-50%)',
}));
