import { IconDollar, IconOffice, OrganizationTabsMenu } from 'ui';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import { ROUTES } from 'routes/routes.const';

const OrganizationMspMenu = () => {
  const options = [
    {
      title: 'My MSP',
      icon: <IconOffice />,
      url: ROUTES.ORGANIZATION,
    },
    {
      title: 'my Clients',
      icon: <IconOffice />,
      url: ROUTES.ORGANIZATION_CLIENTS,
    },
    {
      title: 'Costs',
      icon: <IconDollar />,
      url: ROUTES.ORGANIZATION_COSTS,
    },
    {
      title: 'revenue',
      icon: <AccountBalanceWalletOutlinedIcon />,
      url: ROUTES.ORGANIZATION_REVENUE,
    },
  ];

  return <OrganizationTabsMenu options={options} />;
};

export default OrganizationMspMenu;
