import { ILogInBody } from '../types';
import { save0AuthState } from './sessionStorageHelpers';
import { ROUTES } from '../routes/routes.const';

export const generateState = () => {
  const array = new Uint8Array(16);
  window.crypto.getRandomValues(array);
  return Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('');
};

export const generateAuthUrl = () => {
  const domain = process.env.REACT_APP_OKTA_DOMAIN;
  const clientId = process.env.REACT_APP_OKTA_CLIENT_ID;
  const audience = process.env.REACT_APP_OKTA_AUDIENCE;

  const redirectUri = encodeURIComponent(
    `${process.env.REACT_APP_REDIRECT_URL}${ROUTES.REDIRECT}`
  );
  const state = encodeURIComponent(generateState());
  const scope = encodeURIComponent('openid profile email');
  save0AuthState(state);
  return (
    `https://${domain}/authorize?` +
    `response_type=code&` +
    `client_id=${clientId}&` +
    `redirect_uri=${redirectUri}&` +
    `scope=${scope}&` +
    `audience=${audience}&` +
    `state=${state}`
  );
};

export const getPopupWindowParams = (width: number, height: number) => {
  const left = window.screen.width / 2 - width / 2;
  const top = window.screen.height / 2 - height / 2;
  const adjustedWidth = Math.min(width, window.screen.width);
  const adjustedHeight = Math.min(height, window.screen.height);
  return `width=${adjustedWidth},height=${adjustedHeight},top=${top},left=${left}`;
};

export const createLoginBody = (code: string): ILogInBody => {
  return {
    provider: 'okta',
    code,
    redirect_uri: process.env.REACT_APP_REDIRECT_URL || window.location.origin,
  };
};
