import {
  StyledStepper,
  StyledStep,
  StyledStepButton,
} from './StepperProgress.styled';
import { theme } from 'theme';
import { IStepNewProfile } from 'constants/stepsNewProfile';

interface IStepperProgressProps {
  onChangeStep?: (step: number) => void;
  currentStep: number;
  options: IStepNewProfile[];
}

const StepperProgress = ({ currentStep, options }: IStepperProgressProps) => {
  return (
    <StyledStepper nonLinear activeStep={currentStep}>
      {options.map(({ step }, index) => (
        <StyledStep key={index} completed={index < currentStep - 1}>
          <StyledStepButton
            bordercolor={
              currentStep === step
                ? theme.palette.primary.main
                : index < currentStep - 1
                  ? theme.palette.success.dark
                  : theme.palette.shades.main
            }
            filter={
              currentStep === step
                ? `drop-shadow(0px 0px 2px ${theme.palette.shades.light})`
                : 'none'
            }
          />
        </StyledStep>
      ))}
    </StyledStepper>
  );
};

export default StepperProgress;
