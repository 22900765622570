import { styled } from '@mui/material';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import Box, { BoxProps } from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { IOrganizationsType } from 'types';
import { COMPANY } from 'constants/organizationsType';

interface ILinkProps extends IOrganizationsType {}

interface IBoxProps extends BoxProps, IOrganizationsType {}

export const StyledTabs = styled(Tabs)<TabsProps>(({ theme }) => ({
  overflow: 'visible',
  paddingTop: '30px',
  '& .MuiTabs-flexContainer': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  '& .Mui-selected': {
    position: 'relative',
    outline: `2px solid ${theme.palette.primary.main}`,
    boxShadow: '0px 6.512px 19.537px 0px rgba(255, 255, 255, 0.56)',
    '& .css-1fledft': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

export const StyledLink = styled(Link)<ILinkProps>(({ type }) => ({
  overflow: 'visible',
  '&.MuiButtonBase-root': {
    overflow: 'visible',
    width: type === COMPANY ? '242px' : '197px',
    height: type === COMPANY ? '126px' : '103px',
    padding: 0,
    transition: '0.15s linear',
    borderRadius: '4px',
    boxShadow:
      type === 'company'
        ? '0px 8px 24px 0px rgba(149, 157, 165, 0.20)'
        : '0px 6.512px 19.537px 0px rgba(255, 255, 255, 0.56)',
  },
  '&.MuiButtonBase-root-MuiTab-root.MuiButtonBase-root': {
    overflow: 'visible',
  },
}));

export const tabProps = {
  styled: {
    '& .MuiTab-iconWrapper': {
      position: 'absolute',
      left: '50%',
      top: '-21px',
      zIndex: 20,
      transform: 'translateX(-50%)',
      width: '43px',
      height: '43px',
      borderRadius: '50%',
      border: '2px solid',
      borderColor: 'info.main',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'primary.main',
      backgroundColor: 'common.white',
      '&:hover': {
        backgroundColor: 'primary.main',
        color: 'common.white',
      },
    },
    '&.Mui-selected .MuiTab-iconWrapper': {
      backgroundColor: 'primary.main',
      color: 'common.white',
    },
  },
};

export const StyledWrapperIcon = styled(Box)<IBoxProps>(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  top: '-21px',
  zIndex: 10,
  transform: 'translateX(-50%)',
  width: '43px',
  height: '43px',
  borderRadius: '50%',
  border: `2px solid ${theme.palette.info.main}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));
