import { List } from '@mui/material';
import { DividerVariants, ItemOfList } from './CloudList.styled';
import CheckBox from '../../ui/CheckBox/CheckBox';
import {
  addOption,
  removeOption,
} from 'reduxStore/createProfile/createProfileSlice';
import { useDispatch } from 'react-redux';
import { IRegionFromServerReformatedType } from 'types/organizations.type';
import { useState } from 'react';

interface ICloudList {
  regionsList: IRegionFromServerReformatedType[];
  status: string;
  cloudProvider: string;
}

const CloudList = ({ regionsList, cloudProvider }: ICloudList) => {
  const dispatch = useDispatch();
  const [regions, setRegions] = useState(regionsList);
  const handleCheckboxChange =
    (regionId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        dispatch(addOption({ cloudProvider, regionId }));
        setRegions(prev => {
          return prev.map((region: IRegionFromServerReformatedType) => {
            if (region.defaultRegion.id === regionId) region.checked = true;
            return { ...region };
          });
        });
      } else {
        dispatch(removeOption({ cloudProvider, regionId }));
        setRegions(prev => {
          return [
            ...prev.map((region: IRegionFromServerReformatedType) => {
              if (region.defaultRegion.id === regionId) region.checked = false;
              return { ...region };
            }),
          ];
        });
      }
    };
  return (
    <List>
      {regions.map(region => (
        <ItemOfList key={region.defaultRegion.id}>
          <CheckBox
            label={`${region.defaultRegion.name} (${region.defaultRegion.locale})
            ${region.defaultRegion.is_coming_soon ? ' - Coming Soon' : ''}`}
            fontWeight="400"
            checkboxSize="small"
            textTransform="capitalize"
            checked={region.checked}
            onChange={handleCheckboxChange(region.defaultRegion.id)}
            gap="15px"
            checkboxColor="dark"
          ></CheckBox>
          <DividerVariants />
        </ItemOfList>
      ))}
    </List>
  );
};

export default CloudList;
