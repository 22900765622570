import { styled } from '@mui/material';
import { ReactComponent as IconHorn } from '../../../assets/images/horn.svg';

interface IIconHornProps {
  size?: string;
}

export const StyledIconHorn = styled(IconHorn)<IIconHornProps>(({ size }) => ({
  cursor: 'pointer',
  height: size ? size : '20px',
  width: size ? size : '20px',
  color: 'currentColor',
}));
