import { StyledButtonPaymentCard } from './ButtonPaymentCard.styled';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useAppDispatch } from 'hooks';
import {
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';

const ButtonPaymentCard = () => {
  const dispatch = useAppDispatch();

  const handleOpenModal = () => {
    dispatch(openModal());
    dispatch(setContent('BackupCard'));
    dispatch(setModalProps({ radius: '18px' }));
  };

  return (
    <StyledButtonPaymentCard
      endIcon={<AddCircleIcon color="primary" fontSize="large" />}
      onClick={handleOpenModal}
    >
      Backup Card
    </StyledButtonPaymentCard>
  );
};

export default ButtonPaymentCard;
