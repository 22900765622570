import { theme } from 'theme';

export const LINES = [
  {
    dataKey: 'AWS',
    color: theme.palette.black.contrastText,
  },
  {
    dataKey: 'Google Cloud',
    color: theme.palette.extra.light,
  },
  {
    dataKey: 'Azure',
    color: theme.palette.red.light,
  },
  {
    dataKey: 'Oracle',
    color: theme.palette.primary.main,
  },
];
