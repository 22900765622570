import { ILoadableState } from 'types/store.type';
import { PayloadAction } from '@reduxjs/toolkit';

export const setLoading = (state: ILoadableState) => {
  state.loading = true;
  state.error = null;
};

export const setError = (state: ILoadableState, action: PayloadAction<any>) => {
  state.loading = false;
  state.error = action.payload;
};
