import { Button, ButtonProps, styled } from '@mui/material';
import { Typography, TypographyProps } from '@mui/material';
import { ButtonsOrgTypeStatus } from 'views/CreateProfile/OrgProfile/OrgProfile';
import { POPPINS } from 'constants/fonts';

interface IButtonProps extends ButtonProps {
  status?: ButtonsOrgTypeStatus;
}

interface ITypographyProps extends TypographyProps {
  status?: ButtonsOrgTypeStatus;
}

export const StyledButton = styled(Button)<IButtonProps>(
  ({ theme, status }) => ({
    backgroundColor:
      status === 'active'
        ? theme.palette.extra.main
        : theme.palette.secondary.contrastText,
    border: '1px solid #767676',
    borderRadius: '10px',
    boxShadow: 'none',
    maxWidth: '227px',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    minHeight: '111px',
    padding: '8px 10px 12px',
    '&:hover': {
      backgroundColor:
        status === 'active'
          ? theme.palette.extra.main
          : theme.palette.secondary.contrastText,
    },
    transition: '0.3s',
  })
);

export const StyledTypographyTitle = styled(Typography)<ITypographyProps>(
  ({ theme, status }) => ({
    fontFamily: POPPINS,
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '150%',
    letterSpacing: '-0.352px',
    fontStyle: 'normal',
    color:
      status === 'active'
        ? theme.palette.common.white
        : theme.palette.text.primary,
    transition: 'color 0.3s',
  })
);

export const StyledTypographyText = styled(Typography)<ITypographyProps>(
  ({ theme, status }) => ({
    fontFamily: POPPINS,
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '150%',
    letterSpacing: '-0.264px',
    fontStyle: 'normal',
    color:
      status === 'active'
        ? theme.palette.common.white
        : theme.palette.text.primary,
    paddingTop: '12px',
    paddingBottom: '12px',
    transition: 'color 0.3s',
  })
);
