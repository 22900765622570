import { StylesType } from 'types';
import {
  Wrapper,
  WrapperAmount,
  Title,
  StyledAmount,
  IconBox,
  InsideIconBox,
} from './Amount.styled';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

interface IAmountProps {
  amount: string;
  text: string;
  styles?: StylesType;
}

const Amount = ({ amount, text, styles }: IAmountProps) => {
  return (
    <Wrapper sx={styles}>
      <IconBox>
        <InsideIconBox>
          <AttachMoneyIcon color="primary" fontSize="small" />
        </InsideIconBox>
      </IconBox>
      <WrapperAmount>
        <Title>{text}</Title>
        <StyledAmount>${amount}</StyledAmount>
      </WrapperAmount>
    </Wrapper>
  );
};

export default Amount;
