import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { setError, setLoading } from '../extraReducersHelpers';
import {
  IClient,
  IOrganization,
  IUserOrganizations,
} from 'types/organizations.type';
import { getUserOrganizations } from './organizationOperations';
import { ILoadableState } from 'types/store.type';

interface IOrganizationState extends ILoadableState {
  userOrganizations: IUserOrganizations | null;
  mainOrganization: IOrganization | null;
  clients: IClient[] | null;
  selectedClient: IClient[] | null;
}

const initialState: IOrganizationState = {
  userOrganizations: null,
  mainOrganization: null,
  clients: null,
  selectedClient: null,
  loading: false,
  error: null,
};

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    resetOrganization: () => initialState,
    setSelectedClientRedux(state, action: PayloadAction<IClient[]>) {
      state.selectedClient = action?.payload;
    },
    resetSelectedClient(state) {
      state.selectedClient = null;
    },
  },
  extraReducers: builder => {
    builder
      // getUserOrganizations
      .addCase(getUserOrganizations.pending, setLoading)
      .addCase(
        getUserOrganizations.fulfilled,
        (state, { payload }: PayloadAction<IUserOrganizations>) => {
          state.loading = false;
          state.userOrganizations = payload;
          state.mainOrganization = payload.main;
          state.clients = payload.clients;
        }
      )
      .addCase(getUserOrganizations.rejected, setError);
  },
});

export const {
  resetOrganization,
  setSelectedClientRedux,
  resetSelectedClient,
} = organizationSlice.actions;

export default organizationSlice.reducer;
