import { MainNavigationMenu, IconThreat, IconUsers } from 'ui';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import { IAdminType } from 'types';
import { SUPER_ADMIN } from 'constants/organizationsType';

interface INavigationProps extends IAdminType {}

const NavigationAdmin = ({ type }: INavigationProps) => {
  const navigationOptions = [
    {
      title: 'Dashboard ',
      url: 'www.kylada.com',
      icon: <DashboardOutlinedIcon key="dashboard" />,
    },
    {
      title: 'Users',
      url: 'www.kylada.com',
      icon: <IconUsers key="users" />,
    },
    {
      title: 'Security Group',
      url: 'www.kylada.com',
      icon: <HttpsOutlinedIcon key="securityGroup" />,
    },
    {
      title: 'Security Endpoints',
      url: 'www.kylada.com',
      icon: <VerifiedUserOutlinedIcon key="security" />,
    },
    {
      title: 'Services',
      url: 'www.kylada.com',
      icon: <DesignServicesOutlinedIcon key="services" />,
    },
    {
      title: 'Network',
      url: 'www.kylada.com',
      icon: <LanguageOutlinedIcon key="network" />,
    },
    {
      title: 'Organization',
      url: 'www.kylada.com',
      icon: <CorporateFareOutlinedIcon key="organization" />,
    },
    {
      title: 'Threat Manager',
      url: 'www.kylada.com',
      icon: <IconThreat key="Threat" type={type} />,
    },
  ];

  return <MainNavigationMenu options={navigationOptions} type={SUPER_ADMIN} />;
};

export default NavigationAdmin;
