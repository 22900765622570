import { useState } from 'react';
import {
  WrapperChart,
  ContainerChart,
  FixedYAxisContainer,
} from './ChartBars.styled';
import { Bar, XAxis, YAxis, Cell, Tooltip, ComposedChart } from 'recharts';
import { IDatasetChartBars } from 'types';
import { useTheme } from '@mui/material';
import TooltipCustom from './Tooltip';
import { ISTOK_WEB } from 'constants/fonts';
import { calculateMaxDomain, generateEmptyArrLastPeriod } from 'utils';

interface IChartBarsProps {
  dataset: IDatasetChartBars[] | null;
  roundedBar?: boolean;
  textTooltip?: string;
  textTooltipAction?: string;
}

const ChartBars = ({
  dataset,
  roundedBar,
  textTooltip,
  textTooltipAction,
}: IChartBarsProps) => {
  const [hoveredBarIndex, setHoveredBarIndex] = useState<number | null>(null);
  const theme = useTheme();

  const handleBarMouseEnter = (index: number) => {
    setHoveredBarIndex(index);
  };

  const handleBarMouseLeave = () => {
    setHoveredBarIndex(null);
  };

  const chosenPeriod = 30; // Todo: is will be changed after using select period

  const dataToUse =
    dataset && dataset.length > 0
      ? dataset
      : generateEmptyArrLastPeriod(chosenPeriod);
  const maxDomain =
    dataset && dataset.length > 0 ? calculateMaxDomain(dataset) : 22;
  const barWidth = roundedBar ? 49 : 58;
  const numberOfBars = dataToUse.length;
  const chartWidth = barWidth * numberOfBars;

  const chartSetting = {
    series: [{ dataKey: 'value' }],
    slotProps: { legend: { hidden: true } },
    height: roundedBar ? 224 : 351,
  };

  const tickStyles = {
    color: theme.palette.gray.main,
    fontSize: '10px',
    fontFamily: ISTOK_WEB,
    fontWeight: 400,
    letterSpacing: '-0.198px',
  };

  return (
    <ContainerChart>
      <FixedYAxisContainer>
        <ComposedChart
          width={70}
          height={roundedBar ? 224 : 351}
          data={dataToUse}
          margin={{ top: 20, right: 0, bottom: 15, left: 0 }}
        >
          <YAxis
            height={10}
            tickMargin={9}
            tick={tickStyles}
            tickLine={false}
            tickCount={12}
            domain={[0, maxDomain]}
            stroke={
              roundedBar
                ? `${theme.palette.action.hover}`
                : `${theme.palette.gray.main}`
            }
          />
        </ComposedChart>
      </FixedYAxisContainer>

      <WrapperChart isroundedbar={roundedBar ? 'true' : 'false'}>
        <ComposedChart
          width={chartWidth}
          data={dataToUse}
          barSize={24}
          margin={{
            top: 20,
            right: 0,
            bottom: 0,
            left: 0,
          }}
          {...chartSetting}
        >
          <XAxis
            dataKey="label"
            height={roundedBar ? 13 : 15}
            tickLine={false}
            padding={{ left: 0, right: 0 }}
            stroke={
              roundedBar
                ? `${theme.palette.action.hover}`
                : `${theme.palette.gray.main}`
            }
            tickMargin={1}
            tick={tickStyles}
          />
          <YAxis
            height={10}
            hide={true}
            tickMargin={9}
            tick={tickStyles}
            tickLine={false}
            tickCount={12}
            domain={[0, maxDomain]}
            stroke={
              roundedBar
                ? `${theme.palette.action.hover}`
                : `${theme.palette.gray.main}`
            }
          />
          {dataset && dataset.length > 0 && (
            <Tooltip
              viewBox={{ x: 0, y: 0, width: 400, height: 400 }}
              content={({ payload }) => (
                <TooltipCustom
                  valueTooltip={payload && payload[0] ? payload[0].value : ''}
                  textTooltipFirst={textTooltip}
                  textTooltipAction={textTooltipAction}
                />
              )}
            />
          )}
          <Bar
            dataKey="value"
            background={{ fill: theme.palette.background.paper }}
            radius={roundedBar ? [10, 10, 0, 0] : [0, 0, 0, 0]}
            onMouseEnter={(_, index) => handleBarMouseEnter(index)}
            onMouseLeave={handleBarMouseLeave}
            layout="vertical"
          >
            {dataToUse?.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  hoveredBarIndex === index
                    ? theme.palette.secondary.main
                    : index % 2 === 0 && roundedBar
                      ? theme.palette.gray.main
                      : theme.palette.primary.main
                }
              />
            ))}
          </Bar>
        </ComposedChart>
      </WrapperChart>
    </ContainerChart>
  );
};

export default ChartBars;
