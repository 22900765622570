import { styled } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';

export const WrapperTable = styled(Box)<BoxProps>(({ theme }) => ({
  height: 'fit-content',
  maxHeight: '100%',
  overflowY: 'auto',
  scrollBehavior: 'smooth',
  borderRadius: '10px',
  border: `1px solid ${theme.palette.silver.contrastText}`,
  marginBottom: '30px',
  paddingBottom: '58px',
  overflow: 'hidden',
}));
