import { styled } from '@mui/material';
import Stack, { StackProps } from '@mui/material/Stack';
import Box, { BoxProps } from '@mui/material/Box';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { COMPANY } from 'constants/organizationsType';
import { IOrganizationsType } from 'types';
import { INTER } from 'constants/fonts';

interface IStackProps extends StackProps, IOrganizationsType {}

interface IBoxProps extends BoxProps, IOrganizationsType {}

interface ITypographyProps extends TypographyProps, IOrganizationsType {}

export const StyledWrapperTab = styled(Stack)<IStackProps>(
  ({ theme, type }) => ({
    overflow: 'visible',
    position: 'relative',
    boxSizing: 'border-box',
    width: '100%',
    // minWidth: '242px',
    height: '100%',
    justifyContent: 'center',
    alignItems: type === COMPANY ? 'start' : 'center',
    paddingLeft: type === COMPANY ? '19px' : 0,
    borderRadius: '4px',
    border: 'none',
    paddingTop: type === COMPANY ? 0 : '16px',
    background: theme.palette.common.white,
    color: theme.palette.secondary.main,
  })
);

export const StyledWrapperIcon = styled(Box)<IBoxProps>(({ theme }) => ({
  width: '52px',
  height: '52px',
  borderRadius: '50%',
  border: `2px solid ${theme.palette.info.main}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.primary.main,
}));

export const StyledTextDataTab = styled(Typography)<ITypographyProps>(
  ({ theme, type }) => ({
    color: theme.palette.secondary.main,
    fontFamily: INTER,
    fontSize: type === COMPANY ? '32px' : '26px',
    fontWeight: 600,
    lineHeight: '36px',
    letterSpacing: '-0.704px',
  })
);

// export const StyledWrapperIconPositionTop = styled(Box)<IBoxProps>(
//   ({ theme, type }) => ({
//     position: 'absolute',
//     left: '50%',
//     top: '-21px',
//     zIndex: 20,
//     transform: 'translateX(-50%)',
//     width: '43px',
//     height: '43px',
//     borderRadius: '50%',
//     border: `2px solid ${theme.palette.info.main}`,
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     color: theme.palette.primary.main,
//     backgroundColor: theme.palette.common.white,
//     '&:hover': {
//       backgroundColor: theme.palette.primary.main,
//       color: theme.palette.common.white,
//     },
//   })
// );
