import { IDatasetChartBars } from 'types';

export interface IObjToCalculateMaxDomain {
  data: IDatasetChartBars[];
}

const calculateMaxDomain = (data: IDatasetChartBars[]): number => {
  const maxValue = data.reduce(
    (acc, current) => Math.max(acc, current.value),
    0
  );
  return Math.ceil(maxValue / 100) * 100;
};

export default calculateMaxDomain;
