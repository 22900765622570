import { Outlet, useLocation } from 'react-router';
import { StepperCreateProfile } from 'views';
import { ROUTES } from '../../routes/routes.const';
import {
  StyledContainer,
  StyledBox,
  StyledWrapper,
  StyledWrapperWhite,
} from './CreateProfileLayoutPage.styled';
import { Box } from '@mui/material';
import { theme } from 'theme';

const CreateProfileLayoutPage = () => {
  const location = useLocation();

  return (
    <StyledBox overflow="hidden">
      <StyledContainer>
        <Box>
          <StepperCreateProfile />
          <StyledWrapper>
            <StyledWrapperWhite
              background={
                location.pathname === ROUTES.ORGANIZATION_PROFILE
                  ? 'F9F9F9'
                  : theme.palette.background.paper
              }
            >
              <Outlet />
            </StyledWrapperWhite>
          </StyledWrapper>
        </Box>
      </StyledContainer>
    </StyledBox>
  );
};

export default CreateProfileLayoutPage;
