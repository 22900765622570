import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { validate0AuthState } from 'utils';
import { STATUS_ERROR, STATUS_SUCCESS } from 'constants/auth';
import { FullScreenLoader } from 'ui';

const RedirectPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const closePopup = (status: string, code?: string) => {
      if (window.opener) {
        window.opener.postMessage({ status, code }, window.location.origin);
        window.close();
      }
    };
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const returnedState = urlParams.get('state');

    if (!code || !validate0AuthState(returnedState)) {
      closePopup(STATUS_ERROR);
      navigate('/', { replace: true });
      return;
    }

    closePopup(STATUS_SUCCESS, code);
  }, []); // eslint-disable-line

  return <FullScreenLoader />;
};

export default RedirectPage;
