import { useNavigate } from 'react-router';
import { WrapperLink, LinkSignIn, TextToLink } from './BasicLinks.styled';

interface ILinkProps {
  text?: string;
  nameLink?: string;
  to?: string;
  onClick?: () => void;
}

const BasicLinks = ({ text, nameLink, to, onClick }: ILinkProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (to) {
      navigate(to);
    }
    onClick && onClick();
  };

  return (
    <WrapperLink>
      <TextToLink>
        {text}
        <LinkSignIn onClick={() => handleClick()} underline="none">
          {nameLink}.
        </LinkSignIn>
      </TextToLink>
    </WrapperLink>
  );
};

export default BasicLinks;
