import { useState, SyntheticEvent } from 'react';
import { StyledTabs, StyledLink } from '../StatsTabsMenu.styled';
import {
  TabsForUserMenu,
  IconMonitor,
  IconBug,
  IconHorn,
  IconCapa,
  IconUser,
  IconSpeed,
} from 'ui';
import { IMenuPropsUsers, IOrganizationsType } from 'types';
import { ROUTES } from 'routes/routes.const';
import { Box, Tab } from '@mui/material';
import { useGetNavItem } from 'hooks';
import { MSP } from 'constants/organizationsType';

interface INavTabsProps extends IOrganizationsType {
  menuData?: IMenuPropsUsers | undefined;
}

const UsersStatsTabsMenu = ({ menuData, type }: INavTabsProps) => {
  const options = [
    {
      title: 'Connected Users',
      data: `${menuData?.connectedUsers}` || '-',
      icon: <IconUser />,
      url: type === MSP ? ROUTES.ORGANIZATION : ROUTES.USERS,
    },
    {
      title: 'Connected Devices',
      data: `${menuData?.connectedDevices}` || '-',
      icon: <IconMonitor />,
      url: ROUTES.USERS_CONNECTED_DEVICES,
      // type === MSP
      //   ? `${ROUTES.ORGANIZATION}/${ROUTES.ORGANIZATION_CONNECTED_DEVICES}`
      //   : `${ROUTES.USERS}/${ROUTES.USERS_CONNECTED_DEVICES}`,
    },
    {
      title: 'BANDWIDTH CONSUMED',
      data: `${menuData?.bandwidthConsumed}` || '-',
      icon: type === MSP ? <IconCapa /> : <IconSpeed size="24px" />,
      url: ROUTES.USERS_BANDWIDTH_CONSUMED,
      // type === MSP
      //   ? `${ROUTES.ORGANIZATION}/${ROUTES.ORGANIZATION_BANDWIDTH_CONSUMED}`
      //   : `${ROUTES.USERS}/${ROUTES.USERS_BANDWIDTH_CONSUMED}`,
    },
    {
      title: 'THREATS blocked',
      data: `${menuData?.threatsBlocked}` || '-',
      icon: <IconBug />,
      url: ROUTES.USERS_THREATS_BLOCKED,
      // type === MSP
      //   ? `${ROUTES.ORGANIZATION}/${ROUTES.ORGANIZATION_THREATS_BLOCKED}`
      //   : `${ROUTES.USERS}/${ROUTES.USERS_THREATS_BLOCKED}`,
    },
    {
      title: 'Quarantine',
      data: `${menuData?.quarantine}` || '-',
      icon: <IconHorn />,
      url: ROUTES.USERS_QUARANTINE,
      // type === MSP
      //   ? `${ROUTES.ORGANIZATION}/${ROUTES.ORGANIZATION_QUARANTINE}`
      //   : `${ROUTES.USERS}/${ROUTES.USERS_QUARANTINE}`,
    },
  ];

  const activeIndex = useGetNavItem('/users', options);
  const [value, setValue] = useState(activeIndex);

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const tabProps = {
    sx: {
      '& .MuiTab-iconWrapper': {
        position: 'absolute',
        left: '50%',
        top: '-21px',
        zIndex: 20,
        transform: 'translateX(-50%)',
        width: '43px',
        height: '43px',
        borderRadius: '50%',
        border: '2px solid',
        borderColor: 'info.main',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'primary.main',
        backgroundColor: 'common.white',
        '&:hover': {
          backgroundColor: 'primary.main',
          color: 'common.white',
        },
      },
      '&.Mui-selected .MuiTab-iconWrapper': {
        backgroundColor: 'primary.main',
        color: 'common.white',
      },
    },
  };

  return (
    <StyledTabs value={value} onChange={handleChange}>
      {options.map((option, index) => (
        <Tab
          key={`navigation-tab_user-${index}`}
          icon={
            type === MSP ? (
              <Box className="MuiTab-iconWrapper">{option.icon}</Box>
            ) : (
              ''
            )
          }
          type={type}
          component={StyledLink}
          to={option.url}
          {...tabProps}
          label={
            <TabsForUserMenu
              type={type}
              title={option.title}
              data={option.data}
              icon={option.icon}
            />
          }
        />
      ))}
    </StyledTabs>
  );
};

export default UsersStatsTabsMenu;
