import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { INTER } from 'constants/fonts';

export const StyledWrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
  width: 'fit-content',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'transparent',
}));

export const StyledCircle = styled(Box)<BoxProps>(({ theme }) => ({
  width: '62px',
  height: '62px',
  background: theme.palette.background.paper,
  border: `2px solid ${theme.palette.blue.dark}`,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  stroke: theme.palette.primary.main,
}));

export const StyledTypographyData = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontFamily: INTER,
  fontSize: '36px',
  fontWeight: 600,
  lineHeight: '36px',
  letterSpacing: '-0.792px',
}));
