import { Outlet } from 'react-router';
import { StyledBox, StyledContainer } from './AuthLayoutPage.styled';

const AuthLayoutPage = () => {
  return (
    <StyledBox overflow="hidden">
      <StyledContainer>
        <Outlet />
      </StyledContainer>
    </StyledBox>
  );
};

export default AuthLayoutPage;
