import { ReactNode } from 'react';
import { StyledButton } from './ActionButton.styled';
import { ButtonProps } from '@mui/material';
import { StylesType } from 'types';

interface ActionButtonProps extends ButtonProps {
  styles?: StylesType;
  size?: 'large' | 'small';
  endIcon?: ReactNode;
  startIcon?: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  needsborder?: 'false' | 'true';
  children: ReactNode | string;
}

const ActionButton = ({
  styles,
  size,
  endIcon,
  startIcon,
  onClick,
  disabled,
  needsborder,
  children,
}: ActionButtonProps) => {
  return (
    <StyledButton
      type="button"
      variant="outlined"
      sx={styles}
      size={size}
      endIcon={endIcon}
      startIcon={startIcon}
      disabled={disabled}
      needsborder={needsborder}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  );
};

export default ActionButton;
