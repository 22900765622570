import { PieChart } from '@mui/x-charts/PieChart';
import {
  StyledWrapperCollors,
  StyledLable,
  StyledColorDot,
  StyledText,
  StyledWrapperLable,
} from './ChartsPie.styled';
import { getColorForCategory } from 'utils';
import { IDataCategoryChartsPie } from 'types';

const ChartsPie = ({
  dataCategory,
}: {
  dataCategory: IDataCategoryChartsPie[];
}) => {
  const data = dataCategory.map(category => ({
    label: category.label,
    value: category.value,
    color: getColorForCategory(category.label),
  }));

  const sizing = {
    margin: { bottom: 41, top: 12, right: 12 },
    width: 272,
    height: 162,
    legend: { hidden: true },
  };

  return (
    <StyledWrapperCollors>
      <PieChart
        series={[
          {
            data,
            highlightScope: { faded: 'global', highlighted: 'item' },
            paddingAngle: 2,
            innerRadius: 2,
            outerRadius: 81,
          },
        ]}
        {...sizing}
        skipAnimation={true}
      />
      <StyledWrapperLable>
        {data.map((item, index) => (
          <StyledLable key={index}>
            <StyledColorDot style={{ backgroundColor: item.color }} />
            <StyledText>{item.label}</StyledText>
          </StyledLable>
        ))}
      </StyledWrapperLable>
    </StyledWrapperCollors>
  );
};

export default ChartsPie;
