import { SyntheticEvent, useState } from 'react';
import DevicesIcon from '@mui/icons-material/Devices';
import { StyledTabs, StyledTab } from './AdminStatsTabsMenu.styled';
import { TabsForAdminMenu } from 'ui';
import { IconUsers, IconSpeed, IconQuarentine, IconBlocked } from 'ui/icons';
import { IMenuPropsUsers } from 'types';

interface INavTabsProps {
  menuData?: IMenuPropsUsers | undefined;
}

const AdminStatsTabsMenu = ({ menuData }: INavTabsProps) => {
  const [value, setValue] = useState(0);

  const options = [
    {
      title: 'organizations',
      data: `${menuData?.connectedUsers}` || '-',
      icon: <IconUsers size="large" />,
    },
    {
      title: 'Connected Devices',
      data: `${menuData?.connectedDevices}` || '-',
      icon: <DevicesIcon fontSize="large" />,
    },
    {
      title: 'Bandwidth Consumed',
      data: `${menuData?.bandwidthConsumed}` || '-',
      icon: <IconSpeed />,
    },
    {
      title: 'Threat Blocked',
      data: `${menuData?.threatsBlocked}` || '-',
      icon: <IconBlocked />,
    },
    {
      title: 'Quarentine',
      data: `${menuData?.quarantine}` || '-',
      icon: <IconQuarentine />,
    },
  ];

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <StyledTabs value={value} onChange={handleChange}>
      {options.map((option, index) => (
        <StyledTab
          key={`navigation-tab_user-${index}`}
          label={
            <TabsForAdminMenu
              title={option.title}
              data={option.data}
              icon={option.icon}
            />
          }
        />
      ))}
    </StyledTabs>
  );
};

export default AdminStatsTabsMenu;
