import { useAppSelector } from 'hooks';
import { Navigate } from 'react-router';
import { getIsAuth } from 'reduxStore/auth/authSelectors';
import { userGotOrganizations } from 'reduxStore/organization/organizationSelectors';
import { ROUTES } from 'routes/routes.const';

interface IPublicRouteProps {
  children: JSX.Element;
}

const PublicRoute = ({ children }: IPublicRouteProps) => {
  const isAuth = useAppSelector(getIsAuth);
  const userHasOrganizations = useAppSelector(userGotOrganizations);

  if (isAuth) {
    const redirectRoute = userHasOrganizations
      ? ROUTES.DASHBOARD
      : ROUTES.ORGANIZATION_PROFILE;
    return <Navigate to={redirectRoute} replace />;
  }

  return children;
};

export default PublicRoute;
