import { styled } from '@mui/material';
import { Typography, TypographyProps } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import Stack, { StackProps } from '@mui/material/Stack';
import { INTER } from 'constants/fonts';

export const StyledWrapperBox = styled(Box)<BoxProps>(() => ({
  width: '411px',
  height: '586px',
  padding: '57px 38px 54px',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
}));

export const StyledWrapperTitle = styled(Stack)<StackProps>(({ theme }) => ({
  gap: '3px',
  alignItems: 'center',
  color: theme.palette.primary.main,
  marginBottom: '41px',
}));

export const StyledWrapperTextFields = styled(Stack)<StackProps>(() => ({
  width: '100%',
  height: '229px',
  gap: '34px',
  paddingBottom: '28px',
}));

export const StyledTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  textAlign: 'center',
  fontFamily: INTER,
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '36px',
  letterSpacing: '-0.528px',
}));

export const StyledWrapperActions = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  marginTop: '56px',
}));
