import { styled } from '@mui/material/styles';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import Tab, { TabProps } from '@mui/material/Tab';
import Box, { BoxProps } from '@mui/material/Box';
import { COLORS } from 'theme/colors.const';
import { Typography, TypographyProps } from '@mui/material';
import { INTER } from 'constants/fonts';

export const TabsWrapper = styled(Tabs)<TabsProps>(() => ({
  background: 'transparent',
  overflow: 'visible',
  margin: '0 -76px',
  '& .MuiTabs-flexContainer': {
    display: 'flex',
    flexDirection: 'row',
    gap: '30px',
    justifyContent: 'space-between',
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

export const TabForCloud = styled(Tab)<TabProps>(({ theme }) => ({
  position: 'relative',
  overflow: 'visible',
  width: '234px',
  height: '124px',
  transition: '0.15s linear',
  borderRadius: '8px',
  border: `2px solid ${theme.palette.primary.main}`,
  background: COLORS.blue100,
  boxShadow: '0px 4px 4px 0px rgba(3, 123, 192, 0.41)',
  '&.Mui-selected': {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

export const CloudInfo = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '3px',
  justifyContent: 'center',
  alignItems: 'center',
  '&::-webkit-scrollbar': {
    width: '14px',
    background: theme.palette.blue.light,
    borderBottomRightRadius: '18px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#85A7BA',
    borderBottomRightRadius: '18px',
  },
}));

export const TabsTitle = styled(Typography)<TypographyProps>(() => ({
  variant: 'subtitle2',
  textTransform: 'capitalize',
  color: 'inherit',
  fontWeight: '600',
  fontFamily: INTER,
}));
