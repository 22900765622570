import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IModalProps } from 'ui/Modal/Modal';

interface IModalState {
  isOpen: boolean;
  content: string | null;
  modalProps: IModalProps | null;
}

const initialState: IModalState = {
  isOpen: false,
  content: null,
  modalProps: null,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal(state) {
      state.isOpen = true;
    },
    closeModal(state) {
      state.isOpen = false;
      state.content = null;
      state.modalProps = null;
    },
    setContent(state, action: PayloadAction<string>) {
      state.content = action.payload;
    },
    setModalProps(state, action: PayloadAction<IModalProps>) {
      state.modalProps = action.payload;
    },
  },
});

export const { openModal, closeModal, setContent, setModalProps } =
  modalSlice.actions;

export default modalSlice.reducer;
