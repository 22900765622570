import { styled } from '@mui/material/styles';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import Box, { BoxProps } from '@mui/material/Box';

interface IDialogProps extends DialogProps {
  position?: 'top' | 'right';
  top?: string;
  status?: 'error' | 'success';
  isadmin?: boolean;
  radius?: string;
  background?: string;
  zindex?: number;
}
interface IBoxProps extends BoxProps {
  status?: 'error' | 'success';
}

export const StyledWrapperDialog = styled(Box)<BoxProps>(() => ({
  borderRadius: '21px',
  height: 'fit-content',
  width: 'fit-content',
}));

export const StyledDialog = styled(Dialog)<IDialogProps>(
  ({ theme, position, top, isadmin, status, radius, background, zindex }) => ({
    '& .MuiPaper-root': {
      zIndex: zindex || 100,
      position: 'relative',
      minHeight: '21px',
      minWidth: '327px',
      height: 'fit-content',
      width: 'fit-content',
      marginTop: top ? top : 0,
      marginBottom: '37px',
      background: background || theme.palette.common.white,
      boxShadow: background && 'none',
      borderRadius: isadmin
        ? '18px'
        : status === 'error' || status === 'success'
          ? '16px'
          : radius,
    },
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.49)',
      backdropFilter: 'blur(2px)',
    },
    '& .MuiDialog-container': {
      transform: position === 'right' ? 'translateX(229px)' : 'translateX(0)',
      alignItems: position === 'top' ? 'start' : 'center',
    },
  })
);

export const StyledLine = styled(Box)<IBoxProps>(({ theme, status }) => ({
  position: 'absolute',
  top: '-10px',
  left: 0,
  zIndex: 2,
  height: status === 'error' || 'success' ? '15px' : 0,
  width: '100%',
  background:
    status === 'error'
      ? theme.palette.error.contrastText
      : status === 'success'
        ? theme.palette.primary.main
        : 'none',
  border: 'transparent',
}));
