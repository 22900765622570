import { styled } from '@mui/material';
import Stack, { StackProps } from '@mui/material/Stack';
import Pagination, { PaginationProps } from '@mui/material/Pagination';

export const StyledWrapperPagination = styled(Stack)<StackProps>(() => ({
  alignItems: 'flex-end',
  marginRight: '0px',
}));

export const StyledPagination = styled(Pagination)<PaginationProps>(
  ({ theme }) => ({
    '& .MuiPaginationItem-root': {
      color: theme.palette.silver.contrastText,
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '-0.352px',
      padding: 0,
      margin: 0,
      borderRadius: '0',
      '&:hover': {
        disableRipple: true,
        background: 'transparent',
        transition: 'none',
      },
      '&.Mui-selected': {
        color: theme.palette.primary.main,
        textDecoration: 'underline',
        backgroundColor: '#fff',
        disableRipple: true,
        '&:hover': {
          disableRipple: true,
          background: 'transparent',
          transition: 'none',
        },
      },
    },
    '& .MuiPaginationItem-icon': {
      color: theme.palette.secondary.contrastText,
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '18px',
    },
    '& .Mui-disabled': {
      display: 'none',
    },
  })
);
