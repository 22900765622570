import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { signIn } from 'reduxStore/auth/authOperations';
import { clear0AuthState } from 'utils';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ROUTES } from 'routes/routes.const';
import { getIsAuth } from 'reduxStore/auth/authSelectors';
import { userGotOrganizations } from 'reduxStore/organization/organizationSelectors';
import { getUserOrganizations } from 'reduxStore/organization/organizationOperations';

import {
  setCreatingOrganization,
  setOrganizationLevel,
} from 'reduxStore/createProfile/createProfileSlice';
import { PARENT } from 'constants/organizationsType';
import { FullScreenLoader } from 'ui';

const AuthHandler = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const authCode = useRef(location.state?.code);
  const hasInitiatedRequest = useRef(false);

  const [isReady, setIsReady] = useState(false);

  const isAuth = useAppSelector(getIsAuth);
  const userHasOrganizations = useAppSelector(userGotOrganizations);

  const navigateTo = (dest: string) => {
    navigate(dest, {
      replace: true,
    });
  };

  useEffect(() => {
    const processAuthentication = async () => {
      const code = authCode.current;
      if (!code) {
        navigateTo('/');
        return;
      }

      try {
        await dispatch(signIn(code)).unwrap();
        await dispatch(getUserOrganizations()).unwrap();
        setIsReady(true);
      } catch (error) {
        console.error('Authentication or organizations fetch failed:', error);
        navigateTo(ROUTES.SIGNIN);
      } finally {
        clear0AuthState();
      }
    };

    if (!hasInitiatedRequest.current) {
      hasInitiatedRequest.current = true;
      processAuthentication();
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!isReady) return;

    if (isAuth) {
      if (!userHasOrganizations) {
        dispatch(setCreatingOrganization(true));
        dispatch(setOrganizationLevel(PARENT));
      }
      const destination = userHasOrganizations
        ? ROUTES.DASHBOARD
        : ROUTES.ORGANIZATION_PROFILE;
      navigateTo(destination);
    }
  }, [isReady, isAuth, userHasOrganizations, navigate]); // eslint-disable-line

  return <FullScreenLoader />;
};

export default AuthHandler;
