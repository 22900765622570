import { ROUTES } from '../routes/routes.const';

export interface IStepNewProfile {
  path: string;
  step: number;
}

export const STEPS_NEW_PROFILE: IStepNewProfile[] = [
  {
    path: ROUTES.ORGANIZATION_PROFILE,
    step: 1,
  },
  { path: ROUTES.BILLING, step: 2 },
  { path: ROUTES.IMPORT, step: 3 },
  {
    path: ROUTES.CONFIGURE_FIRST_ENDPOINT,
    step: 4,
  },
  {
    path: ROUTES.SELECT_CLOUD,
    step: 5,
  },
];
