import { theme } from 'theme';

const getColorForCategory = (category: string): string => {
  switch (category) {
    case 'Google':
      return theme.palette.info.dark;
    case 'Microsoft':
      return theme.palette.success.contrastText;
    case 'Meta':
      return theme.palette.warning.contrastText;
    case 'Doubleclick':
      return theme.palette.warning.dark;
    default:
      return theme.palette.shades.dark;
  }
};

export default getColorForCategory;
