import dayjs from 'dayjs';

const generateEmptyArrLastPeriod = (days: number) => {
  const dateToday = dayjs();
  const lastPeriod = [];

  for (let i = 0; i < days; i++) {
    const date = dateToday.subtract(i, 'day');
    lastPeriod.push({ value: 0, label: date.format('MMM D') });
  }

  return lastPeriod.reverse();
};

export default generateEmptyArrLastPeriod;
