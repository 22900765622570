import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import { Typography, TypographyProps } from '@mui/material';
import { ISTOK_WEB, POPPINS } from 'constants/fonts';

export const OrgTypeButtonsWrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  gap: '33px',
  alignItems: 'stretch',
}));

export const OrgTypeTitle = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    fontFamily: POPPINS,
    fontSize: '24px',
    fontWeight: '500',
    lineHeight: '150%',
    letterSpacing: '-0.528px',
    fontStyle: 'normal',
    color: theme.palette.text.primary,
    marginBottom: '5px',
  })
);

export const ChargeInfo = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontFamily: ISTOK_WEB,
  color: theme.palette.text.disabled,
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '150%',
  letterSpacing: '-0.352px',
  paddingTop: '5px',
}));

export const Error = styled(Typography)<TypographyProps>(({ theme }) => ({
  position: 'absolute',
  top: '-50px',
  left: '50%',
  transform: 'translateX(-50%)',
  color: theme.palette.error.main,
  fontFamily: ISTOK_WEB,
  fontSize: '16px',
  lineHeight: '150%',
  fontWeight: '400',
  textAlign: 'center',
  marginTop: '10px',
  letterSpacing: '-0.352px',
}));
