import { useState } from 'react';
import ListItemText from '@mui/material/ListItemText';
import { SelectChangeEvent } from '@mui/material/Select';
import Box from '@mui/material/Box';
import FlatButton from 'ui/buttons/FlatButton/FlatButton';
import {
  StyledFormControl,
  StyledTypography,
  StyledInputLabel,
  StyledSelect,
  StyledCheckbox,
  StyledMenuItem,
  MenuProps,
} from './SelectMultipleChoice.styled';

interface ISelectMultipleChoice {
  version: 'providers' | 'regions';
}

const listProviders = [
  'af-south-1',
  'ap-east-1',
  'ap-northeast-1',
  'af-south-12',
  'ap-east-12',
  'ap-northeast-12',
  'af-south-13',
  'ap-east-13',
  'ap-northeast-13',
];

const listRegions = [
  'af-south-1',
  'ap-east-1',
  'ap-northeast-1',
  'af-south-12',
  'ap-east-12',
  'ap-northeast-12',
  'af-south-13',
  'ap-east-13',
  'ap-northeast-13',
];

const SelectMultipleChoice = ({ version }: ISelectMultipleChoice) => {
  const [regionName, setRegionName] = useState<string[]>([]);
  const options = version === 'regions' ? listRegions : listProviders;

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event;
    setRegionName(
      typeof value === 'string'
        ? (value as unknown as string).split(',')
        : (value as string[])
    );
  };

  return (
    <div>
      <StyledFormControl>
        <StyledTypography variant="subtitle2" textTransform="capitalize">
          {version === 'regions' ? 'Select Regions' : 'Cloud Providers'}
        </StyledTypography>
        <StyledInputLabel shrink htmlFor="select-cloud">
          Select Cloud
        </StyledInputLabel>
        <StyledSelect
          id="select-cloud"
          multiple
          value={regionName}
          onChange={handleChange}
          renderValue={() => ''}
          MenuProps={MenuProps}
          displayEmpty
        >
          {options.map(name => (
            <StyledMenuItem key={name} value={name}>
              <StyledCheckbox checked={regionName.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </StyledMenuItem>
          ))}
          <Box marginLeft="21px">
            <FlatButton variant="contained" width="118px" height="36px">
              Ok
            </FlatButton>
          </Box>
        </StyledSelect>
      </StyledFormControl>
    </div>
  );
};

export default SelectMultipleChoice;
