import { useEffect, useState } from 'react';
import { ROUTES } from '../../../routes/routes.const';
import { useNavigate } from 'react-router';
import { Box } from '@mui/material';
import { ChartBars, Section, SelectAction, TableBasis } from 'ui';
import { theme } from 'theme';
import {
  CONNECTED_USER_HEAD_TABLE,
  DEVICE_LIST_HEAD_TABLE,
} from 'constants/tablesHead';
import { useAppSelector } from 'hooks';
import { getIsOpen } from 'reduxStore/modal/modalSelectors';
import { ADMIN } from 'constants/organizationsType';
import { getUsers } from 'reduxStore/users/usersSelectors';
import { getUpdatedUsersData } from 'utils';

// const DashboardUsers = () => {
const UsersMainView = () => {
  const navigate = useNavigate();
  const [isWarning, setIsWarning] = useState(false);
  const isOpenModal = useAppSelector(getIsOpen);

  const usersList = useAppSelector(getUsers);
  const updatedUsers = getUpdatedUsersData(usersList);

  // const datasetChartBars = [
  //   {
  //     value: 5,
  //     label: 'Jan',
  //   },
  //   {
  //     value: 3,
  //     label: 'Fev',
  //   },
  //   {
  //     value: 4,
  //     label: 'Mar',
  //   },
  //   {
  //     value: 19,
  //     label: 'Apr',
  //   },
  //   {
  //     value: 21,
  //     label: 'May',
  //   },
  //   {
  //     value: 9,
  //     label: 'June',
  //   },
  //   {
  //     value: 79,
  //     label: 'July',
  //   },
  //   {
  //     value: 7,
  //     label: 'Aug',
  //   },
  //   {
  //     value: 12,
  //     label: 'Sept',
  //   },
  //   {
  //     value: 2,
  //     label: 'Oct',
  //   },
  //   {
  //     value: 10,
  //     label: 'Nov',
  //   },
  //   {
  //     value: 5,
  //     label: 'Dec',
  //   },
  //   {
  //     value: 4,
  //     label: 'Mar',
  //   },
  //   {
  //     value: 19,
  //     label: 'Apr',
  //   },
  //   {
  //     value: 22,
  //     label: 'May',
  //   },
  //   {
  //     value: 9,
  //     label: 'June',
  //   },
  //   {
  //     value: 7,
  //     label: 'July',
  //   },
  //   {
  //     value: 9,
  //     label: 'Aug',
  //   },
  //   {
  //     value: 19,
  //     label: 'Sept',
  //   },
  //   {
  //     value: 20,
  //     label: 'Oct',
  //   },
  //   {
  //     value: 10,
  //     label: 'Nov',
  //   },
  //   {
  //     value: 9,
  //     label: 'Dec',
  //   },
  //   {
  //     value: 19,
  //     label: 'Sept',
  //   },
  //   {
  //     value: 20,
  //     label: 'Oct',
  //   },
  //   {
  //     value: 10,
  //     label: 'Nov',
  //   },
  //   {
  //     value: 5,
  //     label: 'Dec',
  //   },
  //   {
  //     value: 10,
  //     label: 'Nov',
  //   },
  //   {
  //     value: 9,
  //     label: 'Dec',
  //   },
  //   {
  //     value: 19,
  //     label: 'Sept',
  //   },
  //   {
  //     value: 20,
  //     label: 'Oct',
  //   },
  //   {
  //     value: 10,
  //     label: 'Nov',
  //   },
  //   {
  //     value: 5,
  //     label: 'Dec',
  //   },
  // ];

  useEffect(() => {
    if (!isOpenModal) {
      setIsWarning(false);
    }
  }, [isOpenModal]);

  const handleEditUser = () => {};

  const handleDeleteUser = () => {
    setIsWarning(true);
  };

  return (
    <Box position="relative">
      <Section
        title="Connected Users"
        paddingLeft="0px"
        paddingLeftTitle="40px"
        background={theme.palette.background.paper}
        action={<SelectAction version="periodOfTime" name="Last Month" />}
      >
        <ChartBars
          dataset={[]}
          textTooltip="users"
          textTooltipAction="connected"
        />
      </Section>
      <Box width="100%" marginTop="21px" position="relative">
        <TableBasis
          title="User List"
          type={ADMIN}
          columns={CONNECTED_USER_HEAD_TABLE}
          tableData={updatedUsers || []}
          isCheckbox="true"
          icon="edit"
          isWarning={isWarning}
          onClick={() => navigate(`${ROUTES.USERS_EDIT_USER}/${12}`)} // todo: replace 12 for dynamic userID
        />
        <Box position="absolute" top="20px" right="40px">
          <SelectAction
            version="actionUser"
            name="Action"
            onDelete={handleDeleteUser}
            // onEdit={() => navigate(ROUTES.USERS_ADD_USER)}
          />
        </Box>
      </Box>
      <Box width="100%" marginTop="21px">
        <TableBasis
          title="Device List"
          type={ADMIN}
          columns={DEVICE_LIST_HEAD_TABLE}
          tableData={[]}
          icon="remove"
          onClick={handleEditUser}
        />
      </Box>
    </Box>
  );
};

export default UsersMainView;
