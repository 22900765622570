import { useState } from 'react';
import { Table, TableBody, TableSortLabel } from '@mui/material';
import { ITableColumn } from 'types';
import {
  StyledWrapperBox,
  StyledTableContainer,
  StyledTableHead,
  StyledTableRow,
  StyledTableCell,
  StyledActionBlock,
} from './TableSortable.styled';

interface ITableSortableProps {
  action?: JSX.Element;
  tableData: { [x: string]: string }[];
  columns: ITableColumn[];
}

const TableSortable = ({ tableData, columns, action }: ITableSortableProps) => {
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const cellValue = (
    rowData: { [x: string]: string },
    columnData: ITableColumn
  ) => {
    return rowData[columnData.label]?.toString() || '-';
  };

  const handleSortColumn = (columnLabel: string) => {
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  };

  const sortedTableData = [...tableData].sort((a, b) => {
    const aValue = a[columns[0].label];
    const bValue = b[columns[0].label];
    if (aValue === bValue) return 0;
    return sortDirection === 'asc'
      ? aValue < bValue
        ? -1
        : 1
      : aValue > bValue
        ? -1
        : 1;
  });

  return (
    <StyledWrapperBox>
      <StyledActionBlock>{action}</StyledActionBlock>
      <StyledTableContainer>
        <Table>
          <StyledTableHead>
            <StyledTableRow>
              <StyledTableCell>
                <TableSortLabel
                  active={true}
                  direction={sortDirection}
                  onClick={() => handleSortColumn(columns[0].label)}
                >
                  {columns[0].key}
                </TableSortLabel>
              </StyledTableCell>
              {columns.slice(1).map(column => (
                <StyledTableCell key={column.label}>
                  {column.key}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </StyledTableHead>
          <TableBody>
            {sortedTableData.map((rowData, index) => (
              <StyledTableRow key={index}>
                {columns.map(column => (
                  <StyledTableCell key={column.label}>
                    {cellValue(rowData, column)}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </StyledWrapperBox>
  );
};

export default TableSortable;
