import { StyledWrapperBox, StyledIconAmazon } from './IconAmazon.styled';

const IconGoogle = () => {
  return (
    <StyledWrapperBox>
      <StyledIconAmazon />
    </StyledWrapperBox>
  );
};

export default IconGoogle;
