import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  StyledDemoContainer,
  StyledDateTimePicker,
  StyledTypography,
} from './DateTimePicker.styled';

const DateTimePicker = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledDemoContainer components={['DateTimePicker']}>
        <StyledTypography>Start Time</StyledTypography>
        <StyledDateTimePicker />
      </StyledDemoContainer>
      <StyledDemoContainer components={['DateTimePicker']}>
        <StyledTypography>End Time</StyledTypography>
        <StyledDateTimePicker />
      </StyledDemoContainer>
    </LocalizationProvider>
  );
};

export default DateTimePicker;
