import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ISTOK_WEB } from 'constants/fonts';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { SvgIconProps } from 'material-ui';
import { IconButton, IconButtonProps } from '@mui/material';
import { COLORS } from 'theme/colors.const';

export const MainWrapper = styled(Box)<BoxProps>(() => ({
  width: '14vw',
  minWidth: '210px',
  display: 'flex',
  gap: '36px',
  flexDirection: 'column',
  alignItems: 'center',
  background: 'transparent',
}));

export const UploadImageWrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: 'fit-content',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#F9F9F9',
}));

export const Circle = styled(Box)<BoxProps>(({ theme }) => ({
  width: '94px',
  height: '94px',
  background: '#98D0FF',
  borderColor: theme.palette.blue.dark,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '13px',
  overflow: 'hidden',
  objectFit: 'cover',
}));

export const Caption = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
  fontFamily: ISTOK_WEB,
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '-0.352px',
  marginBottom: '12px',
}));

export const ErrorMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontFamily: ISTOK_WEB,
  fontSize: '12px',
  lineHeight: '16px',
  textAlign: 'center',
  marginTop: '10px',
  letterSpacing: '0.1px',
}));

export const DeleteIconButton = styled(IconButton)<IconButtonProps>(() => ({
  position: 'absolute',
  top: '-3px',
  right: '-3px',
  width: '22px',
  height: '22px',
  cursor: 'pointer',
  opacity: 0,
  transition: 'opacity 0.2s ease-in-out, background 0.2s ease-in-out',
  '&:hover': {
    background: COLORS.superSilver,
  },
}));

export const DeleteIcon = styled(HighlightOffIcon)<SvgIconProps>(
  ({ theme }) => ({
    fill: theme.palette.text.disabled,
    width: '18px',
    height: '18px',
  })
);
