import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import { INTER } from 'constants/fonts';
import { COLORS } from 'theme/colors.const';

interface StyledButtonProps extends ButtonProps {
  size?: 'large' | 'small';
  needsborder?: 'false' | 'true';
}

export const StyledButton = styled(Button)<StyledButtonProps>(
  ({ theme, size = 'large', needsborder = 'true' }) => ({
    display: 'flex',
    width: 'fit-content',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2px',
    padding: size === 'large' ? '7px 18px' : '2px 8px',

    borderRadius: '21px',
    border: needsborder === 'true' ? '1px solid' : '0px',
    // default color styles - blue button with purple border and white text
    borderColor: COLORS.superLilac,
    background: theme.palette.primary.main,
    color: theme.palette.common.white,

    fontFamily: INTER,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    letterSpacing: '-0.264px',
    textTransform: 'uppercase',

    // default hover styles

    '&:hover': {
      background: theme.palette.common.white,
      color: theme.palette.primary.main,
    },
  })
);
