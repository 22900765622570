import { CHILD } from 'constants/organizationsType';
import { array, object, string } from 'yup';

const createValidationSchema = (organizationLevel) => {
	const schemaFields = {
		name: string().required('Company Name is required'),
		street: string().required('Address is required'),
		city: string().matches(/^[A-Za-z ]+$/, 'Сity must consist of Latin letters and spaces only').required('City is required'),
		state: string().matches(/^[A-Za-z ]+$/, 'State must consist of Latin letters and spaces only').required('State is required'),
		zipCode: string().matches(/^[0-9]{5}$/, 'Zip code must be exactly 5 digits').required('Zip code is required'),
		admins: array(),
	};

	if (organizationLevel === CHILD) {
		schemaFields.margin = string()
			.matches(/^(0|[1-9]\d*)(\.\d{1,2})?$/, 'Invalid number format')
			.test('maxValue', 'Margin cannot be more than 100', value => {
				const number = parseFloat(value);
				return number <= 100;
			})
			.required('Margin is required');
		schemaFields.admins = array().min(1, 'Administrators are required');
	}

	return object(schemaFields);
};

export default createValidationSchema;
