import { ThemeProvider } from '@mui/material';
import { theme } from 'theme';
import {
  IconButton,
  Switch,
  Logo,
  Section,
  UsersStatsTabsMenu,
  FlatButton,
  ButtonOval,
  Pagination,
  StatusBox,
  KyladaLink,
  NavigationUser,
  NavigationAdmin,
  RadioButtonsGroup,
  BasicLinks,
  SelectOrganization,
  AdminStatsTabsMenu,
  InfoText,
  TableSortable,
  SelectMain,
  SecuredByStripe,
  CreatingNewServices,
  CheckBox,
  SelectCloudProvider,
  SelectMultipleChoice,
  Amount,
  ChartsPie,
  LineGraph,
  ThreatDetails,
} from 'ui';
import { Search } from 'ui/inputs';
import { OPTIONS } from 'constants/radioButtonsGroup';
import { IDataCategoryChartsPie } from 'types';

function TestPage() {
  const menu = {
    connectedUsers: '354/158',
    connectedDevices: '354/158',
    bandwidthConsumed: '354/158',
    threatsBlocked: '354/158',
    quarantine: '354/158',
  };

  const columns = [
    { label: 'Device Name', key: 'Device Name', disableSorting: false },
    { label: 'Platform', key: 'Platform', disableSorting: false },
    { label: 'Ip Address', key: 'Ip Address', disableSorting: false },
    { label: 'Bandwidth Used', key: 'Bandwidth Used', disableSorting: true },
    { label: 'sTATUS', key: 'sTATUS', disableSorting: true },
    { label: 'Up-Time', key: 'Up-Time', disableSorting: true },
  ];

  const tableData = [
    {
      'Device Name': 'TeroBlaze S1',
      Platform: 'PC',
      'Ip Address': '185.53.178.9',
      'Bandwidth Used': '112.1MB',
      sTATUS: 'Healthy',
      'Up-Time': '01/01/2023 11:12:00GMT',
    },
    {
      'Device Name': 'PeroBlaze S1',
      Platform: 'PC',
      'Ip Address': '185.53.178.9',
      'Bandwidth Used': '112.1MB',
      sTATUS: 'Healthy',
      'Up-Time': '01/01/2023 11:12:00GMT',
    },
    {
      'Device Name': 'AeroBlaze S1',
      Platform: 'PC',
      'Ip Address': '185.53.178.9',
      'Bandwidth Used': '112.1MB',
      sTATUS: 'Healthy',
      'Up-Time': '01/01/2023 11:12:00GMT',
    },
    {
      'Device Name': 'GeroBlaze S1',
      Platform: 'PC',
      'Ip Address': '185.53.178.9',
      'Bandwidth Used': '112.1MB',
      sTATUS: 'Healthy',
      'Up-Time': '01/01/2023 11:12:00GMT',
    },
    {
      'Device Name': 'DeroBlaze S1',
      Platform: 'PC',
      'Ip Address': '185.53.178.9',
      'Bandwidth Used': '112.1MB',
      sTATUS: 'Healthy',
      'Up-Time': '01/01/2023 11:12:00GMT',
    },
    {
      'Device Name': 'BeroBlaze S1',
      Platform: 'PC',
      'Ip Address': '185.53.178.9',
      'Bandwidth Used': '112.1MB',
      sTATUS: 'Healthy',
      'Up-Time': '01/01/2023 11:12:00GMT',
    },
  ];

  const testData = [
    { label: 'Google', value: 40 },
    { label: 'Microsoft', value: 15 },
    { label: 'Meta', value: 20 },
    { label: 'Doubleclick', value: 25 },
  ];

  const dataLineGraph = [
    {
      time: '20:20',
      AWS: 4,
      'Google Cloud': 2,
      Azure: 1,
      Oracle: 7,
    },
    {
      time: '2:34',
      AWS: 3,
      'Google Cloud': 13,
      Azure: 4,
      Oracle: 2,
    },
    {
      time: '17:14',
      AWS: 8,
      'Google Cloud': 9,
      Azure: 5,
      Oracle: 3,
    },
    {
      time: '24:23',
      AWS: 3,
      'Google Cloud': 7,
      Azure: 14,
      Oracle: 8,
    },
    {
      time: '6:42',
      AWS: 5,
      'Google Cloud': 6,
      Azure: 12,
      Oracle: 13,
    },
    {
      time: '7:40',
      AWS: 10,
      'Google Cloud': 3,
      Azure: 8,
      Oracle: 11,
    },
    {
      time: '17:49',
      AWS: 16,
      'Google Cloud': 10,
      Azure: 4,
      Oracle: 20,
    },
    {
      time: '13:08',
      AWS: 18,
      'Google Cloud': 13,
      Azure: 8,
      Oracle: 15,
    },
    {
      time: '23:23',
      AWS: 15,
      'Google Cloud': 9,
      Azure: 14,
      Oracle: 10,
    },
    {
      time: '2:30',
      AWS: 18,
      'Google Cloud': 11,
      Azure: 16,
      Oracle: 12,
    },
    {
      time: '22:41',
      AWS: 20,
      'Google Cloud': 16,
      Azure: 10,
      Oracle: 3,
    },
    {
      time: '4:15',
      AWS: 22,
      'Google Cloud': 19,
      Azure: 14,
      Oracle: 17,
    },
  ];

  return (
    <>
      <ThemeProvider theme={theme}>
        <Search />
        <IconButton />
        <Switch />
        <Section>
          <SelectMultipleChoice version="regions" />
          <SelectCloudProvider />
          <Logo />
          <LineGraph data={dataLineGraph} />
          <ThreatDetails data="CVE-2023-20198" />
          <FlatButton variant="outlined">Hello 1</FlatButton>
          <StatusBox status={'healthy'} starticon />
          <StatusBox status={'disable'} starticon />
          <StatusBox status={'attention'} starticon />
          <StatusBox status={'healthy'} />
          <StatusBox status={'disable'} />
          <StatusBox status={'attention'} />
          <StatusBox status={'available'} />
          <StatusBox status={'offline'} />
          <StatusBox status={'creating'} />
          <StatusBox status={'high'} />
          <StatusBox status={'medium'} />
          <StatusBox status={'low'} />
          <StatusBox status={'yes'} />
          <StatusBox status={'no'} />
          <KyladaLink />
          <RadioButtonsGroup
            buttontype="importUsers"
            options={OPTIONS}
            defaultValue="option1"
            name="group1"
          />
          <RadioButtonsGroup
            buttontype="selectRegion"
            options={OPTIONS}
            defaultValue="option1"
            name="group1"
          />
          <BasicLinks
            text="Already signed up for Kylada?"
            nameLink="Sign in."
          />
          <BasicLinks text="New to Kylada?" nameLink="Sign up now." />

          <SelectOrganization />
          <InfoText text="(Accepted formats: PNG, JPG, GIF. Maximum size: 2MB)" />
          <SelectMain label="Choose Service" />
          <SecuredByStripe />
          <CheckBox label="label" />
        </Section>
        <Pagination
          count={665}
          pageNumber={2}
          onChange={() => console.log('hello')}
        />
        <UsersStatsTabsMenu type="msp" menuData={menu} />
        <AdminStatsTabsMenu menuData={menu} />
        <NavigationAdmin type="admin" />
        <NavigationUser />
        <Section type="table">
          <TableSortable
            tableData={tableData}
            columns={columns}
            action={<ButtonOval>Add</ButtonOval>}
          />
        </Section>
        <CreatingNewServices progress={96} protocol="12547" />
        <Amount amount="1,245.00" text="" />
        <ChartsPie dataCategory={testData as IDataCategoryChartsPie[]} />
      </ThemeProvider>
    </>
  );
}

export default TestPage;
