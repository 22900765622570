import { styled } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import Divider, { DividerProps } from '@mui/material/Divider';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { INTER } from 'constants/fonts';

interface IBoxProps extends BoxProps {
  status?: 'error' | 'success';
}

interface ITypographyProps extends TypographyProps {
  confirm?: 'true';
}

export const StyledWrapper = styled(Box)<BoxProps>(() => ({
  width: '798px',
  height: 'fit-content',
  position: 'relative',
}));

export const StyledWrapperIcon = styled(Box)<IBoxProps>(({ status }) => ({
  width: '84px',
  height: '84px',
  borderRadius: '50%',
  backgroundColor: status === 'error' ? '#FDC4C4' : '#C4E2FD',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StyledTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontFamily: INTER,
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '36px',
  letterSpacing: '-0.528px',
  textTransform: 'capitalize',
}));

export const StyledText = styled(Typography)<ITypographyProps>(
  ({ theme, confirm }) => ({
    color: confirm ? theme.palette.primary.main : '#232323',
    fontFamily: INTER,
    fontSize: '20px',
    fontWeight: confirm ? 500 : 400,
    lineHeight: '30px',
    letterSpacing: '-0.44px',
  })
);

export const StyledWrapperActions = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '7px',
  alignItems: 'center',
  justifyContent: 'end',
  margin: '20px 40px',
  boxSizing: 'border-box',
}));

export const StyledDivider = styled(Divider)<DividerProps>(({ theme }) => ({
  width: '798px',
  height: '2px',
  background: theme.palette.secondary.contrastText,
}));

export const StyledLine = styled(Box)<IBoxProps>(({ theme, status }) => ({
  position: 'absolute',
  top: '-10px',
  left: 0,
  zIndex: 1,
  height: status === 'error' || status === 'success' ? '15px' : 0,
  width: '100%',
  background:
    status === 'error'
      ? theme.palette.error.contrastText
      : status === 'success'
        ? theme.palette.primary.main
        : 'none',
  border: 'transparent',
}));
