import { styled } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { INTER } from 'constants/fonts';

export const StyledWrapperProgress = styled(Box)<BoxProps>(() => ({
  position: 'relative',
  display: 'inline-flex',
  margin: '0 auto',
  width: 'fit-content',
  height: 'fit-content',
}));

export const StyledProgressCircularMain = styled(
  CircularProgress
)<CircularProgressProps>(({ theme }) => ({
  zIndex: 2,
  color: theme.palette.success.dark,
  '& .MuiCircularProgress-svg': {
    strokeLinecap: 'round',
  },
}));

export const StyledProgressCircularUnder = styled(
  CircularProgress
)<CircularProgressProps>(({ theme }) => ({
  position: 'absolute',
  zIndex: 1,
  color: theme.palette.shades.main,
}));

export const StyledInsideBlock = styled(Box)<BoxProps>(() => ({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledCaption = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    color: theme.palette.secondary.main,
    fontFamily: INTER,
    fontWeight: 600,
    lineHeight: '40.2px',
    letterSpacing: '-0.59px',
  })
);
