import { Outlet } from 'react-router';
import { UsersStatsTabsMenu } from 'ui';
import { useAppSelector } from 'hooks';
import { getUsers } from 'reduxStore/users/usersSelectors';
import { Box } from '@mui/material';

const UsersPage = () => {
  const usersList = useAppSelector(getUsers);

  const menu = {
    connectedUsers: `0/${usersList?.length || 0}`,
    connectedDevices: '354/158',
    bandwidthConsumed: '354/158',
    threatsBlocked: '354/158',
    quarantine: '354/158',
  };

  return (
    <>
      <UsersStatsTabsMenu type="company" menuData={menu} />
      <Box mt="33px">
        <Outlet />
      </Box>
    </>
  );
};

export default UsersPage;
