import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { ROUTES } from '../../../routes/routes.const';
import { useNavigate } from 'react-router-dom';
import { RadioButtonsGroup, InfoText, SectionTitle } from 'ui';
import { ButtonsContainerMSP } from 'views';
import { StyledText, WrapperTextField } from './Import.styled';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';
import TemplateFilePanel from './components/TemplateFile/TemplateFile';
import { getUsersListImported } from 'reduxStore/createProfile/createProfileSelectors';

interface ImportOption {
  value: string;
  label: string;
  onClick: () => void;
}

const Import = () => {
  const [selectedAction, setSelectedAction] = useState<ImportOption | null>(
    null
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const usersList = useAppSelector(getUsersListImported);

  useEffect(() => {
    if (selectedAction && selectedAction.onClick) {
      selectedAction.onClick();
    }
  }, [selectedAction]);

  const handleImportCSVFile = () => {
    dispatch(openModal());
    dispatch(setContent('UploadFile'));
    dispatch(setModalProps({ radius: '12px' }));
  };

  const handleClickImportDirectory = () => {};
  const handleClickInputManually = () => {};

  const optionsList = [
    {
      value: 'Import Directory (Google)',
      label: 'Import Directory (Google)',
      onClick: handleClickImportDirectory,
    },
    {
      value: 'Import a CSV file',
      label: 'Import a CSV file',
      onClick: handleImportCSVFile,
    },
    {
      value: 'Input Manually',
      label: 'Input Manually',
      onClick: handleClickInputManually,
    },
  ];

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAction(null);
    const selectedValue = event.target.value;
    const selectedOption = optionsList.find(
      option => option.value === selectedValue
    );
    if (selectedOption && !!selectedOption.onClick) {
      setSelectedAction(selectedOption);
    }
  };

  const handleClickNext = async () => {
    navigate(`${ROUTES.IMPORT}/${ROUTES.IMPORT_CONFIRMATION}`);
  };

  const handleClickSetUpLater = () => {
    navigate(ROUTES.CONFIGURE_FIRST_ENDPOINT);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      gap="47px"
      minHeight="70vh"
    >
      <Box>
        <SectionTitle>Import Users</SectionTitle>
        <Box marginTop="33px">
          <RadioButtonsGroup
            buttontype="importUsers"
            options={optionsList}
            name="group1"
            onChange={handleRadioChange}
          />
        </Box>
        <WrapperTextField>
          <InfoText>
            <StyledText>
              Import Directory: You’ll be able to download your users directly
              from your SSO provider, and select those users you want to have
              access to Kylada
            </StyledText>
            <br />
            <StyledText>
              For a sample CSV file with the correct headings, please download
              the
            </StyledText>
            <TemplateFilePanel />
          </InfoText>
        </WrapperTextField>
      </Box>

      <ButtonsContainerMSP
        titleButton="Next"
        setUpLater
        onClick={handleClickNext}
        onClickSetUpLater={handleClickSetUpLater}
        disabled={!usersList?.length}
      />
    </Box>
  );
};

export default Import;
