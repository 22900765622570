import { ReactNode } from 'react';
import { StyledBox } from './InfoText.styled';

interface IInfoTextProps {
  children?: ReactNode;
  text?: string;
}

const InfoText = ({ children, text }: IInfoTextProps) => {
  return <StyledBox>{children || text}</StyledBox>;
};

export default InfoText;
