import { useNavigate } from 'react-router';
import { setAuthError } from 'reduxStore/auth/authSlice';
import { useEffect, useState } from 'react';
import { STATUS_ERROR, STATUS_SUCCESS } from 'constants/auth';
import { AUTH_ERROR } from 'constants/errors';

type UseAuthListenerResult = [string | null, () => void];

const useAuthListener = (popupWindow: Window | null): UseAuthListenerResult => {
  const navigate = useNavigate();

  const [code, setCode] = useState<string | null>(null);

  useEffect(() => {
    const handleAuthMessage = (e: MessageEvent) => {
      if (e.origin !== window.location.origin) {
        return;
      }
      const { status, code } = e.data;

      if (status === STATUS_ERROR) {
        setAuthError(AUTH_ERROR);
        navigate('/', { replace: true });
      }
      if (status === STATUS_SUCCESS) {
        setCode(code);
      }
    };
    window.addEventListener('message', handleAuthMessage);

    return () => {
      window.removeEventListener('message', handleAuthMessage);
    };
  }, [popupWindow, navigate]);

  const resetCode = () => setCode(null);

  return [code, resetCode];
};

export default useAuthListener;
