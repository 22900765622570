import { useEffect, useState } from 'react';
import { WrapperTable } from './ImportConfirmation.styled';
import { SectionTitle, TableBasis } from 'ui';
import { CONFIRMATION_IMPORTING_HEAD_TABLE } from 'constants/tablesHead';
import { ButtonsContainerMSP } from 'views';
import { ADMIN } from 'constants/organizationsType';
import { ITableRow } from 'types';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { ROUTES } from 'routes/routes.const';
import { useAppSelector } from 'hooks';
import {
  getOrganizationId,
  getUsersListImported,
} from 'reduxStore/createProfile/createProfileSelectors';
import * as api from 'services/api/createProfile';
import { IUserType } from 'types/organizations.type';
import { COLORS } from 'theme/colors.const';
import { INTER } from 'constants/fonts';

const ImportConfirmation = () => {
  const [confirmatingValue, setConfirmatingValue] = useState<IUserType[] | []>(
    []
  );
  const [selectedIndexes, setSelectedIndexes] = useState<number[]>([]);
  const [errorDomain, setErrorDomain] = useState('');

  const navigate = useNavigate();

  const usersList = useAppSelector(getUsersListImported);
  const organizationId = useAppSelector(getOrganizationId);

  useEffect(() => {
    if (!usersList || usersList.length === 0) return;
    setConfirmatingValue(usersList);

    const selectedAllIndexes = usersList.map(
      (_: IUserType, index: number) => index
    );
    setSelectedIndexes(selectedAllIndexes);
  }, [usersList]);

  const handleChangeSwitch = (row: IUserType) => {
    setConfirmatingValue(prevState => {
      return prevState.map(user =>
        user.email === row.email ? { ...user, is_admin: !user.is_admin } : user
      );
    });
  };

  const handleCheckboxChange = (checkedIndexes: number[]) => {
    if (!usersList) return;

    setSelectedIndexes(checkedIndexes);
  };

  const handleClickNext = async () => {
    const selectedUsersList = confirmatingValue.filter((_, index) =>
      selectedIndexes.includes(index)
    );

    try {
      if (organizationId && confirmatingValue) {
        const body = {
          org_id: organizationId,
          users: selectedUsersList,
        };

        await api.postImportUsers(body);
        navigate(ROUTES.CONFIGURE_FIRST_ENDPOINT);
      }
    } catch (error: any) {
      setErrorDomain(error.response.data.message);
      console.error('Error:', error.message);
    }
  };

  return (
    <>
      <SectionTitle>Import Users</SectionTitle>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="67vh"
        mt="10px"
      >
        <WrapperTable>
          <TableBasis
            isCheckbox="true"
            type={ADMIN}
            tableData={usersList || []}
            columns={CONFIRMATION_IMPORTING_HEAD_TABLE}
            icon="switch"
            enabledSwitchFlag={(rowData: ITableRow) =>
              rowData.is_admin === true
            }
            maxheight="100%"
            onChangeSwitch={handleChangeSwitch}
            onCheckboxChange={handleCheckboxChange}
            selectedIndexes={selectedIndexes}
          />
        </WrapperTable>
        {!!errorDomain && (
          <Box marginTop="50px" display="flex" justifyContent="center">
            <Typography
              sx={{
                paddingBottom: '10px',
                color: COLORS.authError,
                fontFamily: INTER,
                fontSize: '16px',
                fontWeight: 800,
                lineHeight: '150%',
                letterSpacing: '-0.352px',
              }}
            >
              {errorDomain}
            </Typography>
          </Box>
        )}
        <ButtonsContainerMSP titleButton="Next" onClick={handleClickNext} />
      </Box>
    </>
  );
};

export default ImportConfirmation;
