import { styled } from '@mui/material';
import Container, { ContainerProps } from '@mui/material/Container';

export const WrapperContainer = styled(Container)<ContainerProps>(
  ({ theme }) => ({
    width: 'calc(100% - 242px)',
    height: 'fit-content',
    minHeight: '100vh',
    padding: '28px 79px 49px 49px',
    background: theme.palette.background.default,
    boxSizing: 'border-box',
  })
);
