import { IGetUser } from 'types';

const getUpdatedUsersData = (data: IGetUser[] | null) => {
  const newData = data?.map((client: IGetUser) => ({
    ...client,
    status: client.status.name,
  }));

  return newData;
};

export default getUpdatedUsersData;
