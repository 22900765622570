import {
  StyledButton,
  StyledTypographyText,
  StyledTypographyTitle,
} from './ButtonOrganizationType.styled';
import { OrgTypeOption } from 'types/organizations.type';
import { ButtonsOrgTypeStatus } from 'views/CreateProfile/OrgProfile/OrgProfile';

interface IButtonOrganizationTypeProps {
  onClick: (option: OrgTypeOption) => void;
  option: OrgTypeOption;
  status: ButtonsOrgTypeStatus;
}

const ButtonOrganizationType = ({
  onClick,
  option,
  status,
}: IButtonOrganizationTypeProps) => {
  const title = {
    msp: 'Managed Service Provider',
    company: 'Company Account',
  };
  const text = {
    msp: 'We manage many companies IT & security needs.',
    company: 'We manage our own internal IT & security needs.',
  };
  return (
    <StyledButton
      onClick={() => onClick(option)}
      variant="contained"
      type="button"
      status={status}
    >
      <StyledTypographyTitle status={status}>
        {title[option]}
      </StyledTypographyTitle>
      <StyledTypographyText status={status}>
        {text[option]}
      </StyledTypographyText>
    </StyledButton>
  );
};

export default ButtonOrganizationType;
