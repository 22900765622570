import instance from './api';

export const postGetUserOrganizations = async () => {
  const { data } = await instance.post('organizations');

  return data;
};

export const postGetOneOrganizationByID = async (body: { id: number }) => {
  const { data } = await instance.post('organization', body);

  return data;
};
