import ActionButton from '../ActionButton/ActionButton';
import { COLORS } from 'theme/colors.const';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { IAdminType } from 'types';
import { ADMIN } from 'constants/organizationsType';

interface IShowAllButtonProps extends IAdminType {
  onClick?: () => void;
}

const ShowAllButton = (props: IShowAllButtonProps) => {
  const { onClick, type } = props;
  const styles = {
    admin: {
      background: COLORS.white,
      color: COLORS.main,
      borderColor: COLORS.grayDarkBorder,
    },
    superAdmin: {
      background: COLORS.white,
      color: COLORS.main,
      borderColor: COLORS.main,
      borderRadius: '50%',
      width: '24px',
      height: '24px',
      padding: 0,
      minWidth: '24px',
      '&:hover': {
        background: COLORS.main,
        color: COLORS.white,
        borderColor: COLORS.white,
      },
    },
  };
  return (
    <>
      <ActionButton
        styles={type ? styles[type] : undefined}
        onClick={onClick}
        endIcon={type === ADMIN && <ArrowDropDownIcon fontSize="large" />}
      >
        {type === ADMIN ? 'Show All' : <KeyboardArrowUpOutlinedIcon />}
      </ActionButton>
    </>
  );
};

export default ShowAllButton;
