import { STEPS_NEW_PROFILE } from 'constants/stepsNewProfile';

const getStepByPathname = (pathname: string) => {
  const option = STEPS_NEW_PROFILE.find(option =>
    pathname.includes(option.path)
  );
  return option ? option.step : 1;
};

export default getStepByPathname;
