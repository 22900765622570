import { ITableRow } from 'types';

const parseCSV = (text: string) => {
  const rows = text.split('\n').filter(row => row.trim() !== '');
  const columns = rows[0].split(',');

  const data = rows.slice(1).map(row => {
    const values = row.split(',');
    const rowObject: ITableRow = {};

    columns.forEach((col, index) => {
      rowObject[col] = values[index];
    });

    return rowObject;
  });

  return { data, columns };
};

export default parseCSV;
