import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  StyledWrapper,
  StyledCircle,
  StyledTypographyData,
} from './ThreatDetails.styled';
import { IconBug } from 'ui';

interface IThreatDetails {
  data?: string;
}

const ThreatDetails = ({ data }: IThreatDetails) => {
  return (
    <StyledWrapper>
      <StyledCircle>
        <IconBug />
      </StyledCircle>
      <Box>
        <Typography variant="body1" textTransform="uppercase">
          Threat CVE Details
        </Typography>
        <StyledTypographyData>{data}</StyledTypographyData>
      </Box>
    </StyledWrapper>
  );
};

export default ThreatDetails;
