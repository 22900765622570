import { styled } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { ISTOK_WEB } from 'constants/fonts';

export const StyledWrapperBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: '327px',
  height: '252px',
  borderRadius: '12px',
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '13px',
}));

export const StyledText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontFamily: ISTOK_WEB,
  fontSize: '24px',
  fontWeight: 400,
  lineHeight: '36px',
  letterSpacing: '-0.528px',
}));
