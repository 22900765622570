import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { POPPINS } from 'constants/fonts';

export const WrapperCheckBoxGroup = styled('div')(({ theme }) => ({
  '&::-webkit-scrollbar': {
    width: '14px',
    background: theme.palette.blue.light,
    borderBottomRightRadius: '18px',
    borderTopRightRadius: '18px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#85A7BA',
    borderBottomRightRadius: '18px',
    borderTopRightRadius: '18px',
  },
  background: theme.palette.blue.contrastText,
  borderRadius: '18px',
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '23px',
  paddingBottom: '9px',
  maxHeight: '454px',
  overflowY: 'auto',
  minHeight: '100px',
}));

export const WrapperNoOptions = styled('div')(({ theme }) => ({
  '&::-webkit-scrollbar': {
    width: '14px',
    background: theme.palette.blue.light,
    borderBottomRightRadius: '18px',
    borderTopRightRadius: '18px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#85A7BA',
    borderBottomRightRadius: '18px',
    borderTopRightRadius: '18px',
  },
  background: theme.palette.blue.contrastText,
  borderRadius: '18px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: '100px',
}));

export const DividerLine = styled(Divider)(({ theme }) => ({
  width: '92%',
  height: '1px',
  color: theme.palette.extra.dark,
  marginLeft: '30px',
}));

export const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontFamily: POPPINS,
  fontSize: '24px',
  fontWeight: 500,
  lineHeight: '36px',
  letterSpacing: '-0.528px',
  paddingTop: '10px',
}));

export const TitleNoOptions = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    color: theme.palette.secondary.main,
    fontFamily: POPPINS,
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '36px',
    letterSpacing: '-0.528px',
    paddingTop: '30px',
  })
);
