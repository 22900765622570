import {
  Circle,
  TooltipContainer,
  WrapperTooltip,
  Arrow,
  TextTooltip,
  ValueTooltip,
  WrapperTextTooltip,
} from './ChartBars.styled';

interface ITooltipProps {
  valueTooltip: any;
  textTooltipFirst?: string;
  textTooltipAction?: string;
}

const TooltipCustom = ({
  valueTooltip,
  textTooltipFirst,
  textTooltipAction,
}: ITooltipProps) => {
  return (
    <WrapperTooltip>
      <Circle />
      <Arrow />
      <TooltipContainer>
        <WrapperTextTooltip>
          <ValueTooltip>{valueTooltip}</ValueTooltip>
          <TextTooltip>{textTooltipFirst}</TextTooltip>
          <TextTooltip>{textTooltipAction}</TextTooltip>
        </WrapperTextTooltip>
      </TooltipContainer>
    </WrapperTooltip>
  );
};

export default TooltipCustom;
