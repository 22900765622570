import { styled } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';

export const Container = styled(Box)<BoxProps>(({ theme }) => ({
  background: theme.palette.primary.dark,
  minWidth: '242px',
  minHeight: '100vh',
  position: 'relative',
  boxSizing: 'border-box',
}));

export const WrapperSidebar = styled(Box)<BoxProps>(({ theme }) => ({
  boxSizing: 'border-box',
  width: '242px',
  height: '100%',
  padding: '37px 20px 47px 16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  background: theme.palette.background.default,
  borderRight: `1px solid ${theme.palette.divider}`,
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 10,
}));

export const NavContainer = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '57px',
}));
