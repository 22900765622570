import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';

interface IButtonOvalProps extends ButtonProps {
  variant?: 'contained' | 'outlined' | 'text';
  buttoncolor?: 'blue' | 'black' | 'red' | 'white' | 'bluewhite' | 'blackwhite';
  isicon?: string;
  width?: string;
  height?: string;
}

export const StyledButtonOval = styled(Button)<IButtonOvalProps>(
  ({ theme, buttoncolor, isicon, variant, width, height }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',

    width: width ? width : 'fit-content',
    height: height ? height : 'fit-content',
    padding: isicon === 'true' ? '6px 18px' : '4px 6px',

    fontSize: isicon === 'true' ? '12px' : '8px',
    fontWeight: 500,
    lineHeight: isicon === 'true' ? '18px' : '12px',
    letterSpacing: isicon === 'true' ? '-0.264px' : '-0.176px',

    borderRadius: '21px',
    border: '1px solid',
    borderColor:
      buttoncolor === 'blue' || buttoncolor === 'bluewhite'
        ? '#AAC2FF'
        : buttoncolor === 'black' || buttoncolor === 'blackwhite'
          ? theme.palette.info.contrastText
          : buttoncolor === 'red'
            ? theme.palette.red.main
            : buttoncolor === 'white' && variant === 'outlined'
              ? theme.palette.silver.contrastText
              : theme.palette.primary.main,
    background:
      buttoncolor === 'blue' || buttoncolor === 'bluewhite'
        ? theme.palette.primary.main
        : buttoncolor === 'black' || buttoncolor === 'blackwhite'
          ? theme.palette.primary.dark
          : buttoncolor === 'red'
            ? theme.palette.error.light
            : theme.palette.common.white,
    color:
      buttoncolor === 'white' && variant === 'contained'
        ? theme.palette.primary.main
        : buttoncolor === 'white' && variant === 'outlined'
          ? theme.palette.secondary.main
          : theme.palette.common.white,
    textTransform: 'uppercase',
    '&:hover': {
      background:
        buttoncolor === 'blue' || buttoncolor === 'bluewhite'
          ? theme.palette.primary.dark
          : buttoncolor === 'black' || buttoncolor === 'blackwhite'
            ? theme.palette.primary.main
            : buttoncolor === 'red'
              ? theme.palette.error.light
              : theme.palette.common.white,
      color:
        buttoncolor === 'white'
          ? theme.palette.primary.main
          : theme.palette.common.white,
    },
  })
);
