import {
  StyledIconThreat,
  StyledIconSettings,
  StyledIconWarning,
  StyledWrapperIcon,
} from './IconThreat.styled';
import { IAdminType } from 'types';
import { SUPER_ADMIN } from 'constants/organizationsType';

interface IIconProps extends IAdminType {}

const IconThreat = ({ type }: IIconProps) => {
  return type === SUPER_ADMIN ? (
    <StyledWrapperIcon>
      <StyledIconSettings />
      <StyledIconWarning />
    </StyledWrapperIcon>
  ) : (
    <StyledIconThreat />
  );
};

export default IconThreat;
