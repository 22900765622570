import { Box } from '@mui/material';
import { ADMIN } from 'constants/organizationsType';
import { DEVICE_LIST_HEAD_TABLE } from 'constants/tablesHead';

import { theme } from 'theme';
import { ChartBars, Section, SelectAction, TableBasis } from 'ui';

const UserConnectedDevicesView = () => {
  const datasetChartBars = [
    {
      value: 5,
      label: 'Jan',
    },
    {
      value: 3,
      label: 'Fev',
    },
    {
      value: 4,
      label: 'Mar',
    },
    {
      value: 19,
      label: 'Apr',
    },
    {
      value: 21,
      label: 'May',
    },
    {
      value: 9,
      label: 'June',
    },
    {
      value: 79,
      label: 'July',
    },
    {
      value: 7,
      label: 'Aug',
    },
    {
      value: 12,
      label: 'Sept',
    },
    {
      value: 2,
      label: 'Oct',
    },
    {
      value: 10,
      label: 'Nov',
    },
    {
      value: 5,
      label: 'Dec',
    },
    {
      value: 4,
      label: 'Mar',
    },
    {
      value: 19,
      label: 'Apr',
    },
    {
      value: 22,
      label: 'May',
    },
    {
      value: 9,
      label: 'June',
    },
    {
      value: 7,
      label: 'July',
    },
    {
      value: 9,
      label: 'Aug',
    },
    {
      value: 19,
      label: 'Sept',
    },
    {
      value: 20,
      label: 'Oct',
    },
    {
      value: 10,
      label: 'Nov',
    },
    {
      value: 9,
      label: 'Dec',
    },
    {
      value: 19,
      label: 'Sept',
    },
    {
      value: 20,
      label: 'Oct',
    },
    {
      value: 10,
      label: 'Nov',
    },
    {
      value: 5,
      label: 'Dec',
    },
    {
      value: 10,
      label: 'Nov',
    },
    {
      value: 9,
      label: 'Dec',
    },
    {
      value: 19,
      label: 'Sept',
    },
    {
      value: 20,
      label: 'Oct',
    },
    {
      value: 10,
      label: 'Nov',
    },
    {
      value: 5,
      label: 'Dec',
    },
  ];

  const tableData = [
    {
      FirstName: 'TeroBlaze S1',
      LastName: 'PC',
      Email: '185.53.178.9',
      Status: '112.1MB',
      Group: 'Healthy',
      Device: '01/01/2023 11:12:00GMT',
    },
    {
      FirstName: 'TeroBlaze S1',
      LastName: 'PC',
      Email: '185.53.178.9',
      Status: '112.1MB',
      Group: 'Healthy',
      Device: '01/01/2023 11:12:00GMT',
    },
    {
      FirstName: 'TeroBlaze S1',
      LastName: 'PC',
      Email: '185.53.178.9',
      Status: '112.1MB',
      Group: 'Healthy',
      Device: '01/01/2023 11:12:00GMT',
    },
    {
      FirstName: 'TeroBlaze S1',
      LastName: 'PC',
      Email: '185.53.178.9',
      Status: '112.1MB',
      Group: 'Healthy',
      Device: '01/01/2023 11:12:00GMT',
    },
    {
      FirstName: 'TeroBlaze S1',
      LastName: 'PC',
      Email: '185.53.178.9',
      Status: '112.1MB',
      Group: 'Healthy',
      Device: '01/01/2023 11:12:00GMT',
    },
    {
      FirstName: 'TeroBlaze S1',
      LastName: 'PC',
      Email: '185.53.178.9',
      Status: '112.1MB',
      Group: 'Healthy',
      Device: '01/01/2023 11:12:00GMT',
    },
  ];

  return (
    <Box position="relative">
      <Section
        title="Connected Device"
        paddingLeft="0px"
        paddingLeftTitle="40px"
        background={theme.palette.background.paper}
        action={<SelectAction version="periodOfTime" name="Last Month" />}
      >
        <ChartBars
          dataset={datasetChartBars}
          textTooltipAction="connected"
          textTooltip="devices"
        />
      </Section>
      <Box width="100%" marginTop="21px" position="relative">
        <TableBasis
          title="Device List"
          type={ADMIN}
          columns={DEVICE_LIST_HEAD_TABLE}
          tableData={tableData}
          icon="remove"
        />
      </Box>
    </Box>
  );
};

export default UserConnectedDevicesView;
