import { styled } from '@mui/material/styles';
import Link, { LinkProps } from '@mui/material/Link';
import { COLORS } from 'theme/colors.const';

export const StyledLink = styled(Link)<LinkProps>(() => ({
  color: COLORS.minBlack,
  fontSize: '20px',
  fontWeight: 400,
  lineHeight: '30px',
  letterSpacing: '-0.44px',
}));
