import {
  ICreateOrganizationBody,
  IImportUsersBody,
  IUpdateOrganizationBody,
  ISetUpSelectedCloudsBody,
} from 'types/api.type';
import instance from './api';

export const postCreateOrganization = async (body: ICreateOrganizationBody) => {
  const { data } = await instance.post('create-org', body);

  return data;
};

export const postGetClouds = async (id: number | null) => {
  const { data } = await instance.post('clouds', { org_id: id });

  return data;
};

export const postSetUpClouds = async (body: ISetUpSelectedCloudsBody) => {
  const { data } = await instance.post('setup-cloud-regions', body);

  return data;
};

export const postUpdateOrganization = async (body: IUpdateOrganizationBody) => {
  const { data } = await instance.post('update-org', body);
  return data;
};

export const postImportUsers = async (body: IImportUsersBody) => {
  const { data } = await instance.post('import-users', body);

  return data;
};
