import { StyledWrapperIcon } from './IconQuarentine.styled';
import CoronavirusIcon from '@mui/icons-material/Coronavirus';

const IconQuarentine = () => {
  return (
    <StyledWrapperIcon>
      <CoronavirusIcon fontSize="large" />
    </StyledWrapperIcon>
  );
};

export default IconQuarentine;
