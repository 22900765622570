import {
  WrapperFormControl,
  Title,
  RadioGroupContainer,
  Label,
  ItemRadio,
  DividerLine,
} from './RadioButtonsGroup.styled';
import { IOptions } from 'types';

interface IRadioButtonsGroupProps {
  buttontype?: 'importUsers' | 'selectRegion';
  options: IOptions[];
  defaultValue?: string;
  name: string;
  title?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioButtonsGroup = ({
  buttontype,
  options,
  defaultValue,
  name,
  title,
  onChange,
}: IRadioButtonsGroupProps) => {
  return (
    <WrapperFormControl>
      <RadioGroupContainer
        defaultValue={defaultValue}
        name={name}
        buttontype={buttontype}
        onChange={onChange}
      >
        {title && <Title>{title}</Title>}
        {options.map((option, index) => (
          <div key={index} onClick={option?.onClick}>
            <Label
              buttontype={buttontype}
              value={option.value}
              control={<ItemRadio color="primary" />}
              label={option.label}
            />
            {buttontype === 'selectRegion' && index < options.length - 1 && (
              <DividerLine />
            )}
          </div>
        ))}
      </RadioGroupContainer>
    </WrapperFormControl>
  );
};

export default RadioButtonsGroup;
