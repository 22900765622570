import { ReactNode, useEffect, useState } from 'react';
import { Autocomplete } from '@mui/material';
import { StyledTextField } from 'ui/inputs/Input/Input.styled';
import { DeleteIcon, InputChip } from './TagsInput.styled';
import { hasValidDomain, isValidEmail } from 'utils';
import { OrgLevelOption } from 'types/organizations.type';
import { PARENT } from 'constants/organizationsType';

interface ITagsInputProps {
  required?: boolean;
  label?: string;
  placeholder?: string;
  background?: 'gray' | 'white';
  field?: any;
  form?: any;
  domain: string;
  orgLevel?: OrgLevelOption | null;
  helperText?: ReactNode;
  isSubmitting?: () => void;
}

const TagsInput = ({
  field,
  form,
  domain,
  orgLevel,
  helperText,
  isSubmitting,
  ...props
}: ITagsInputProps) => {
  const [tags, setTags] = useState<string[]>(field.value || []);
  const [inputValue, setInputValue] = useState('');
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (isSubmitting) {
      setShowError(true);
    }
  }, [isSubmitting]);

  const validateTag = (tag: string) => {
    if (!isValidEmail(tag)) {
      form.setFieldError(field.name, 'Invalid email format');
      return false;
    }
    if (orgLevel === PARENT && !hasValidDomain(tag, domain)) {
      form.setFieldError(field.name, `Email domain must be ${domain}`);
      return false;
    }
    return true;
  };

  const handleChange = (event: any, newValue: string[], reason: string) => {
    if (reason === 'createOption') {
      setShowError(true);
      const newTag = newValue[newValue.length - 1];

      if (validateTag(newTag)) {
        setTags(newValue);
        form.setFieldValue(field.name, newValue);
        form.setFieldError(field.name, undefined);
        setInputValue('');
      }
    } else if (reason === 'removeOption' || reason === 'clear') {
      setTags(newValue);
      form.setFieldValue(field.name, newValue);
    }
  };

  return (
    <Autocomplete
      sx={{
        '& .MuiFormControl-root': {
          height: 'auto',
        },
      }}
      multiple
      freeSolo
      disableClearable
      options={[]}
      value={tags}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={(event, newInputValue, reason) => {
        if (reason === 'input') setInputValue(newInputValue);
        if (!form.touched[field.name]) {
          form.setFieldTouched(field.name, true);
        }
      }}
      renderTags={(value, getTagProps) =>
        value.map((option: string, index: number) => {
          const { key, ...tagProps } = getTagProps({ index });
          return (
            <InputChip
              key={key}
              label={option}
              deleteIcon={<DeleteIcon />}
              {...tagProps}
            />
          );
        })
      }
      renderInput={params => (
        <StyledTextField
          {...params}
          {...props}
          onBlur={() => {
            setInputValue('');
            setShowError(true);
            form.setFieldTouched(field.name, true);
            form.validateField(field.name);
          }}
          height={!tags.length ? '44px' : 'auto'}
          multiline
          error={!!form.errors[field.name] && showError}
          helperText={showError && helperText}
          sx={{
            '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
              padding: '3px 4px 3px 10px',
            },
          }}
        />
      )}
    />
  );
};

export default TagsInput;
