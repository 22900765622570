import { Stack, Typography } from '@mui/material';
import {
  StyledWrapperTab,
  StyledWrapperIcon,
  StyledTextDataTab,
} from './TabsForUserMenu.styled';
import { IOrganizationsType } from 'types';
import { COMPANY } from 'constants/organizationsType';

interface ITabUsersProps extends IOrganizationsType {
  icon?: JSX.Element | undefined;
  title?: string | undefined;
  data?: string | undefined;
}

const TabsForUserMenu = ({ icon, title, data, type }: ITabUsersProps) => {
  return (
    <StyledWrapperTab type={type}>
      <Stack spacing="11px" direction="row">
        {type === COMPANY && (
          <StyledWrapperIcon type={type}>{icon}</StyledWrapperIcon>
        )}
        <Stack
          direction="column"
          alignItems={type === COMPANY ? 'start' : 'center'}
        >
          <Typography variant="h5">{title}</Typography>
          <StyledTextDataTab>{data}</StyledTextDataTab>
        </Stack>
      </Stack>
      {/* {type === MSP && (
        <StyledWrapperIconPositionTop>{icon}</StyledWrapperIconPositionTop>
      )} */}
    </StyledWrapperTab>
  );
};

export default TabsForUserMenu;
