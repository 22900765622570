import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { SelectCloudProvider, SectionTitle } from 'ui';
import { ButtonsContainerMSP } from 'views';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';
import { ROUTES } from 'routes/routes.const';
import {
  getOrganizationLevel,
  getOrganizationValues,
} from 'reduxStore/createProfile/createProfileSelectors';
import { CHILD } from 'constants/organizationsType';

const Clouds = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const orgName = useAppSelector(getOrganizationValues)?.name;
  const organizationLevel = useAppSelector(getOrganizationLevel);

  const handleOpenModal = () => {
    dispatch(openModal());
    dispatch(setContent('ConfirmCloudsAndRegion'));
    dispatch(setModalProps({ radius: '18px' }));
  };

  const handleSetUpLater = () => {
    const savedState = localStorage.getItem('DoNotShowModal');
    if (organizationLevel === CHILD) {
      dispatch(setModalProps({ radius: '18px', title: orgName }));
      navigate(ROUTES.ORGANIZATION_CLIENTS);
      dispatch(openModal());
      dispatch(setContent('AddClientConfirm'));
    } else {
      navigate(ROUTES.DASHBOARD, { state: { from: 'createMainOrganization' } });
      if (!savedState || JSON.parse(savedState) === false) {
        dispatch(openModal());
        dispatch(setContent('WelcomeToKylada'));
        dispatch(setModalProps({ radius: '18px' }));
      }
    }
  };

  return (
    <>
      <SectionTitle texttransform="none">
        Select your cloud providers
      </SectionTitle>
      <Box marginTop="14px" display="flex" flexDirection="column" gap="51px">
        <SelectCloudProvider />
        <ButtonsContainerMSP
          titleButton="Finish"
          setUpLater
          onClick={handleOpenModal}
          onClickSetUpLater={handleSetUpLater}
        />
      </Box>
    </>
  );
};

export default Clouds;
