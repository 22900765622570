import { Outlet } from 'react-router';
import RouteWatcher from 'components/RouteWatcher';
import { Modal } from 'ui';

function App() {
  return (
    <>
      <RouteWatcher />
      <Outlet />
      <Modal />
    </>
  );
}

export default App;
