export const ROUTES = {
  DASHBOARD: '/',
  SIGNIN: '/login',
  REDIRECT: '/redirect',
  PROCESS_AUTH: '/process-auth',

  ORGANIZATION_PROFILE: '/organization_profile',
  BILLING: '/billing',
  IMPORT: '/import',
  IMPORT_CONFIRMATION: 'import_confirmation',
  CONFIGURE_FIRST_ENDPOINT: '/configure_first_endpoint',
  SELECT_CLOUD: '/select_cloud',

  CONNECTED_DEVICES: '/connected_devices',
  MALWARE_BLOCKED: '/malware_blocked',
  ADS_BLOCKED: '/ads_blocked',
  CLOUDS_CENTER: '/clouds_center',

  USERS: '/users',
  //   USERS_CONNECTED_USERS: 'connected_users', // not used
  USERS_CONNECTED_DEVICES: '/users/connected_devices',
  USERS_BANDWIDTH_CONSUMED: '/users/bandwidth_consumed',
  USERS_THREATS_BLOCKED: '/users/threats_blocked',
  USERS_QUARANTINE: '/users/quarantine',
  USERS_EDIT_USER: '/users/edit_user',
  USERS_ADD_USER: '/users/add_new_user',

  SECURITY_GROUP: '/security_group',
  SECURITY_ENDPOINTS: '/security_endpoints',
  SERVICES: '/services',
  NETWORK: '/network',

  ORGANIZATION: '/organization',
  //   ORGANIZATION_MY_MSP: '/organization/my_msp', // not used
  ORGANIZATION_CLIENTS: '/organization/clients',
  ORGANIZATION_COSTS: '/organization/costs',
  ORGANIZATION_REVENUE: '/organization/revenue',
  // ORGANIZATION_CONNECTED_USERS: 'connected_users',
  // ORGANIZATION_CONNECTED_DEVICES: 'connected_devices',
  // ORGANIZATION_BANDWIDTH_CONSUMED: 'bandwidth_consumed',
  // ORGANIZATION_THREATS_BLOCKED: 'threats_blocked',
  // ORGANIZATION_QUARANTINE: 'quarantine',

  THREAT_MANAGER: '/threat_manager',
};

export const CREATE_ORG_ROUTES = [
  ROUTES.ORGANIZATION_PROFILE,
  ROUTES.BILLING,
  ROUTES.IMPORT,
  ROUTES.IMPORT_CONFIRMATION,
  ROUTES.CONFIGURE_FIRST_ENDPOINT,
  ROUTES.SELECT_CLOUD,
];
