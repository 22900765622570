import { useEffect, useState } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import {
  StyledBox,
  StyledFormControl,
  StyledSelect,
  StyledMenuItem,
  StyledDivider,
  MenuProps,
} from './SelectAction.styled';
import { DateTimePicker } from 'ui';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import {
  ACTION_USER,
  VARIANT2,
  VARIANT3,
} from '../../../constants/selectActions';
import { Box } from '@mui/material';
import { ISelectItemProps } from 'types';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getIsOpen } from 'reduxStore/modal/modalSelectors';
import { theme } from 'theme';
import {
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';

interface ISelectAction {
  version: 'actionUser' | 'action2' | 'periodOfTime';
  name: string;
  onDelete?: () => void;
  onDeploy?: () => void;
  onEdit?: () => void;
}

const SelectAction = ({
  version,
  name,
  onDelete,
  onDeploy,
  onEdit,
}: ISelectAction) => {
  const [selectedAction, setSelectedAction] = useState<string>('');
  const [selectedRadio, setSelectedRadio] = useState<string>(selectedAction);
  const [showDateTimePicker, setShowDateTimePicker] = useState(false);

  const isOpenModal = useAppSelector(getIsOpen);
  const dispatch = useAppDispatch();

  const actions = () => {
    if (version === 'actionUser') {
      return ACTION_USER;
    }
    if (version === 'action2') {
      return VARIANT2;
    }
    if (version === 'periodOfTime') {
      return VARIANT3;
    }
  };

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string;
    setSelectedAction(value);
    handleClickIcon();

    setSelectedRadio(value);
    if (value === 'Custom') {
      setShowDateTimePicker(true);
    } else {
      setShowDateTimePicker(false);
    }
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRadio(event.target.value);
  };

  useEffect(() => {
    setTimeout(() => {
      handleClickIcon();
    }, 400);
    // eslint-disable-next-line
  }, [selectedAction]);

  useEffect(() => {
    !isOpenModal && setSelectedAction('');
  }, [isOpenModal]);

  const handleDelete = () => {
    // setIsWarning(true);
    dispatch(openModal());
    dispatch(setContent('DeleteUserConfirm'));
    dispatch(
      setModalProps({
        status: 'error',
        radius: '16px',
        isNextClick: true,
      })
    );
    onDelete && onDelete();
  };

  const handleClickIcon = () => {
    if (!onDelete && !onEdit && !onDeploy) return;

    if (selectedAction === 'Delete') {
      handleDelete();
    } else if (selectedAction === 'Sync' || selectedAction === 'Deploy Agent') {
      onDeploy && onDeploy();
    } else if (selectedAction === 'Add User' || selectedAction === 'Edit') {
      onEdit && onEdit();
    }
  };

  return (
    <>
      <StyledBox>
        <StyledFormControl fullWidth>
          {selectedAction === 'Delete' && (
            <Box
              position="absolute"
              top="6px"
              right="12px"
              zIndex="1"
              color={theme.palette.common.white}
            >
              <DeleteForeverOutlinedIcon />
            </Box>
          )}
          <StyledSelect
            displayEmpty={true}
            value={selectedAction}
            warningaction={selectedAction === 'Delete' ? 'true' : 'false'}
            renderValue={() => {
              return selectedAction ? <>{selectedAction}</> : name;
            }}
            onChange={handleChange}
            MenuProps={MenuProps}
          >
            {actions()?.map(
              (
                variantItem: ISelectItemProps,
                index: number,
                array: ISelectItemProps[]
              ) => [
                <StyledMenuItem
                  key={`${variantItem.title}-${index}`}
                  value={variantItem.title}
                  version={version}
                >
                  {version === 'periodOfTime' ? (
                    <RadioGroup
                      value={selectedRadio}
                      onChange={handleRadioChange}
                    >
                      <FormControlLabel
                        value={variantItem.title}
                        control={<Radio size="small" />}
                        label={variantItem.title}
                        onChange={() => setSelectedAction(variantItem.title)}
                      />
                    </RadioGroup>
                  ) : (
                    <>
                      {variantItem.title} {variantItem.icon}
                    </>
                  )}
                </StyledMenuItem>,
                index !== array.length - 1 && (
                  <StyledDivider key={`divider-${index}`} version={version} />
                ),
              ]
            )}
          </StyledSelect>
        </StyledFormControl>
      </StyledBox>
      <Box>{showDateTimePicker && <DateTimePicker />}</Box>
    </>
  );
};

export default SelectAction;
