import { styled, Typography, TypographyProps } from '@mui/material';
import { INTER } from 'constants/fonts';

export const CompanyName = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontFamily: INTER,
  fontSize: '36px',
  fontWeight: 600,
  lineHeight: '100%',
  letterSpacing: '-0.792px',
}));

export const Subtitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontFamily: INTER,
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '150%',
  letterSpacing: '-0.792px',
  textTransform: 'uppercase',
}));
