import { Chip, ChipProps, styled } from '@mui/material';
import { SvgIconProps } from 'material-ui';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export const InputChip = styled(Chip)<ChipProps>(({ theme }) => ({
  backgroundColor: theme.palette.extra.main,
  color: theme.palette.blue.dark,
  paddingRight: '5px',
  '& span': {
    paddingTop: '3px',
  },
}));

export const DeleteIcon = styled(HighlightOffIcon)<SvgIconProps>(
  ({ theme }) => ({
    fill: theme.palette.blue.dark,
    width: '16px',
    height: '16px',
  })
);
