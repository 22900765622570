import { styled } from '@mui/material/styles';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import FormControlLabel, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import Radio, { RadioProps } from '@mui/material/Radio';
import Divider from '@mui/material/Divider';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { POPPINS } from 'constants/fonts';

interface IFormControlProps extends FormControlProps {
  buttontype?: 'importUsers' | 'selectRegion';
}

interface IRadioGroupProps extends RadioGroupProps {
  buttontype?: 'importUsers' | 'selectRegion';
}

interface IFormControlLabelProps extends FormControlLabelProps {
  buttontype?: 'importUsers' | 'selectRegion';
}

interface IRadioProps extends RadioProps {
  buttontype?: 'importUsers' | 'selectRegion';
}

export const WrapperFormControl = styled(FormControl)<IFormControlProps>(
  ({ buttontype }) => ({
    width: '100%',
    marginLeft: buttontype === 'importUsers' ? '76px' : '0px',
  })
);

export const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontFamily: POPPINS,
  fontSize: '24px',
  fontWeight: 500,
  lineHeight: '36px',
  letterSpacing: '-0.528px',
  paddingTop: '21px',
}));

export const RadioGroupContainer = styled(RadioGroup)<IRadioGroupProps>(
  ({ theme, buttontype }) => ({
    background:
      buttontype === 'selectRegion' ? theme.palette.blue.contrastText : 'none',
    borderRadius: buttontype === 'importUsers' ? '0px' : '18px',
  })
);

export const Label = styled(FormControlLabel)<IFormControlLabelProps>(
  ({ theme, buttontype }) => ({
    maxWidth: buttontype === 'importUsers' ? '517px' : '100%',
    width: '100%',
    height: buttontype === 'importUsers' ? '56px' : '100%',
    '& .MuiFormControlLabel-label': {
      color:
        buttontype === 'importUsers'
          ? theme.palette.primary.dark
          : theme.palette.secondary.dark,
      fontSize: '14px',
      fontWeight: buttontype === 'importUsers' ? 600 : 400,
      lineHeight: '21px',
      letterSpacing: '-0.308px',
      textTransform: 'capitalize',
    },
    marginTop: buttontype === 'selectRegion' ? '22px' : '0px',
    marginBottom: buttontype === 'importUsers' ? '25px' : '9px',
    paddingLeft: buttontype === 'importUsers' ? '22px' : '36px',
    background:
      buttontype === 'importUsers' ? theme.palette.action.disabled : 'none',
    borderRadius: buttontype === 'importUsers' ? '4px' : '0px',
  })
);

export const ItemRadio = styled(Radio)<IRadioProps>(({ buttontype }) => ({
  width: buttontype === 'importUsers' ? '24px' : '16px',
  height: buttontype === 'importUsers' ? '24px' : '16px',
  marginRight: buttontype === 'importUsers' ? '10px' : '8px',
}));

export const DividerLine = styled(Divider)(({ theme }) => ({
  width: '92%',
  height: '1px',
  color: theme.palette.extra.dark,
  marginLeft: '44px',
}));
