import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stripe from '../../assets/images/stripe.png';
import { INTER } from 'constants/fonts';

export const StyledWrapperBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  width: 'fit-content',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.background.paper,
}));

export const StyledStripe = styled(Box)<BoxProps>(() => ({
  width: '28px',
  height: '28px',
  background: `url(${Stripe})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontFamily: INTER,
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '21px',
  letterSpacing: '-0.308px',
  textTransform: 'capitalize',
}));
