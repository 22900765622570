import { styled } from '@mui/material/styles';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import Box, { BoxProps } from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { INTER } from 'constants/fonts';

export const TabsContainer = styled(Tabs)<TabsProps>(() => ({
  background: 'transparent',
  '& .MuiTabs-flexContainer': {
    display: 'flex',
    gap: '8px',
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

export const TabLink = styled(Link)(({ theme }) => ({
  '&.MuiButtonBase-root': {
    padding: '10px 20px',
    transition: '0.15s linear',
    borderRadius: '6px',
    borderColor: theme.palette.secondary.main,
    border: `1px solid`,
    background: '#DCF1FF',
    color: theme.palette.secondary.main,
    fontFamily: INTER,
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '12px',
    letterSpacing: '-0.264px',
  },
  height: '44px',
  minHeight: '44px !important',
}));

export const WrapperContent = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  justifyContent: 'center',
  alignItems: 'center',
}));
