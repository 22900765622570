import { Box } from '@mui/material';
import { AddButton } from 'ui/buttons/actionButtons';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  setCreatingOrganization,
  setOrganizationLevel,
} from 'reduxStore/createProfile/createProfileSlice';
import { ROUTES } from 'routes/routes.const';
import { CHILD, SUPER_ADMIN } from 'constants/organizationsType';
import { useNavigate } from 'react-router';
import { TableBasis } from 'ui';
import { ORGANIZATION_CLIENTS } from 'constants/tablesHead';
import {
  getClients,
  getSelectedClient,
} from 'reduxStore/organization/organizationSelectors';
import { IClient, IOrganization } from 'types/organizations.type';
// import {
//   openModal,
//   setContent,
//   setModalProps,
// } from 'reduxStore/modal/modalSlice';

const OrganizationClientsView = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const selectedClient = useAppSelector(getSelectedClient);
  const clients = useAppSelector(getClients);

  const tableData: IClient[] | IOrganization[] = selectedClient
    ? selectedClient
    : clients || [];

  const handleClickRow = (row: any) => {
    // dispatch(setSelectedClientRedux([row]));
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        position="absolute"
        right="0"
        top="8px"
      >
        {/* <AddButton //TODO it will be deleted
          onClick={() => {
            dispatch(openModal());
            dispatch(setContent('Confirmation'));
            dispatch(setModalProps({ radius: '18px' }));
          }}
        >
          Confirm Change
        </AddButton> */}
        <Box height="36px">
          <AddButton
            color="white"
            onClick={() => {
              dispatch(setCreatingOrganization(true));
              dispatch(setOrganizationLevel(CHILD));
              navigate(ROUTES.ORGANIZATION_PROFILE);
            }}
          >
            Add client
          </AddButton>
        </Box>
      </Box>
      <Box mt="18px">
        <TableBasis
          type={SUPER_ADMIN}
          tableData={tableData}
          columns={ORGANIZATION_CLIENTS}
          onClickShowMore={() => {}}
          onClick={handleClickRow}
        />
      </Box>
    </>
  );
};

export default OrganizationClientsView;
