import { StyledIconButton } from './IconButton.styled';
import { Tooltip } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface IIconButtonProps {
  width?: string;
  borderRadius?: string;
  borderSize?: string;
  children?: JSX.Element | undefined;
  isColor?: boolean;
  isUser?: 'true';
  titleTooltip?: string;
  onClick?: () => void;
}

const IconButton = ({
  width,
  borderRadius,
  borderSize,
  children,
  isColor,
  isUser,
  titleTooltip,
  onClick,
}: IIconButtonProps) => {
  return (
    <>
      {titleTooltip ? (
        <Tooltip
          title={
            <>
              {titleTooltip}
              <HighlightOffIcon fontSize="small" />
            </>
          }
          arrow
          placement="top"
        >
          <StyledIconButton
            width={width}
            borderradius={borderRadius}
            bordersize={borderSize}
            children={children}
            iscolor={isColor ? 'true' : 'false'}
            isuser={isUser}
            onClick={onClick}
          />
        </Tooltip>
      ) : (
        <StyledIconButton
          width={width}
          borderradius={borderRadius}
          bordersize={borderSize}
          children={children}
          iscolor={isColor ? 'true' : 'false'}
          isuser={isUser}
          onClick={onClick}
        />
      )}
    </>
  );
};

export default IconButton;
