import { useState, useEffect, ChangeEvent } from 'react';
import { StyledFormControlLabel, StyledCheckbox } from './CheckBox.styled';
import { CheckboxProps } from '@mui/material/Checkbox';
interface ICheckBox extends CheckboxProps {
  label: string;
  checkboxSize?: 'small' | 'large';
  textTransform?: 'lowercase' | 'capitalize' | 'uppercase' | 'none';
  padding?: string;
  fontWeight?: '400' | '500' | '600';
  localStorageKey?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  keyForControl?: number;
  value?: string;
  name?: string;
  gap?: string;
  checkboxColor?: 'dark' | 'bright';
}
const CheckBox = ({
  label,
  checkboxSize,
  textTransform,
  padding,
  fontWeight,
  localStorageKey,
  checked,
  keyForControl,
  value,
  name,
  gap,
  checkboxColor,
  onChange,
}: ICheckBox) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (localStorageKey) {
      const savedState = localStorage.getItem(localStorageKey);
      if (savedState !== null) {
        setIsChecked(JSON.parse(savedState));
      }
    }
  }, [localStorageKey]);

  useEffect(() => {
    if (localStorageKey) {
      localStorage.setItem(localStorageKey, JSON.stringify(isChecked));
    }
  }, [isChecked, localStorageKey]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    } else setIsChecked(prevState => !prevState);
  };

  return (
    <StyledFormControlLabel
      key={keyForControl}
      control={
        <StyledCheckbox
          onChange={handleChange}
          checkboxsize={checkboxSize}
          padding={padding}
          checked={checked}
          value={value}
          name={name}
          checkboxcolor={checkboxColor}
        />
      }
      label={label}
      checkboxsize={checkboxSize}
      texttransform={textTransform}
      padding={padding}
      fontWeight={fontWeight}
      gap={gap}
    />
  );
};

export default CheckBox;
