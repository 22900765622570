import { TEMPLATE_DATA_FILE } from 'constants/tablesHead';
import { LinkToExport } from '../../Import.styled';
import { ITableHeader, ITemplateFile } from 'types';

const TemplateFile = () => {
  const templateData: ITemplateFile[] = [
    {
      email: 'eric@logically.net',
      first_name: 'Eric',
      last_name: 'Menzel',
      department: 'Management',
      title: 'CEO',
      is_admin: true,
    },
    {
      email: 'jeff@logically.net',
      first_name: 'Jeff',
      last_name: 'Woolslayer',
      department: 'HR',
      title: 'HR Manager',
      is_admin: false,
    },
    {
      email: 'eugine@logically.net',
      first_name: 'Eugine',
      last_name: 'Kosenko',
      department: 'IT',
      title: 'CTO',
      is_admin: true,
    },
    {
      email: 'alice@logically.net',
      first_name: 'Alice',
      last_name: 'Cooper',
      department: 'Client Management',
      title: 'Managed Service Engineer 2',
      is_admin: false,
    },
  ];

  const convertToCSV = (data: ITemplateFile[]) => {
    const header = TEMPLATE_DATA_FILE.map((column: ITableHeader) => column.key);
    const rows = data.map((row: ITemplateFile) =>
      TEMPLATE_DATA_FILE.map((column: ITableHeader) => row[column.key]).join(
        ','
      )
    );
    return [header, ...rows].join('\n');
  };

  const downloadCSV = (data: ITemplateFile[]) => {
    const csvData = convertToCSV(data);
    if (csvData === '') {
      return;
    }

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `template.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <LinkToExport onClick={() => downloadCSV(templateData)}>
      Template Here.
    </LinkToExport>
  );
};

export default TemplateFile;
