import { useAppDispatch } from 'hooks';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { clearCreateProfileData } from 'reduxStore/createProfile/createProfileSlice';
import { CREATE_ORG_ROUTES } from 'routes/routes.const';

const RouteWatcher = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [lastPath, setLastPath] = useState('');

  useEffect(() => {
    const lastPathIsCreateProfile = CREATE_ORG_ROUTES.includes(`/${lastPath}`);

    const mainPath = location.pathname.split('/').filter(Boolean)[0];
    const currentPathIsNotCreateProfile = !CREATE_ORG_ROUTES.includes(
      `/${mainPath}`
    );

    if (
      (lastPathIsCreateProfile || lastPath === '') &&
      currentPathIsNotCreateProfile
    ) {
      dispatch(clearCreateProfileData());
    }

    setLastPath(mainPath);
  }, [location, dispatch]); // eslint-disable-line

  return null;
};

export default RouteWatcher;
