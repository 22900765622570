import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";

export const StyledSwitch = styled(Switch)<SwitchProps>(({ theme }) => ({
  width: '24px',
  height: '15px',
  padding: 0,
  display: 'flex',
  borderRadius: '10px',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(11px)',
      
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 4,
    color: theme.palette.secondary.main,
    '&.Mui-checked': {
      transform: 'translateX(10px)',
      color: theme.palette.common.white,
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: 7,
    height: 7,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 300,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 20 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
    border: '2px solid',
    borderColor: theme.palette.secondary.main,
    boxSizing: 'border-box',
  },

}))