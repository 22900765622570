import { ReactNode } from 'react';
import { StyledStatusBox } from './StatusBox.styled';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SignalWifiOffIcon from '@mui/icons-material/SignalWifiOff';
import AdjustIcon from '@mui/icons-material/Adjust';

interface IStatusBoxProps {
  status?:
    | 'healthy'
    | 'disable'
    | 'attention'
    | 'available'
    | 'offline'
    | 'creating'
    | 'high'
    | 'medium'
    | 'low'
    | 'yes'
    | 'no';
  starticon?: ReactNode;
  isstarticon?: 'true';
}

const StatusBox = ({ status, starticon, isstarticon }: IStatusBoxProps) => {
  const isHealthy = status === 'healthy';
  const isDisable = status === 'disable';
  const isAttention = status === 'attention';

  const hasStartIcon = !!starticon;

  return (
    <StyledStatusBox
      status={status}
      isstarticon={hasStartIcon ? 'true' : undefined}
    >
      {starticon && isHealthy ? (
        <FavoriteIcon fontSize="small" />
      ) : isDisable && starticon ? (
        <SignalWifiOffIcon fontSize="small" />
      ) : isAttention && starticon ? (
        <AdjustIcon fontSize="small" />
      ) : undefined}
      {status}
    </StyledStatusBox>
  );
};

export default StatusBox;
