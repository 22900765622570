import { styled } from '@mui/material/styles';
import Stepper, { StepperProps } from '@mui/material/Stepper';
import Step, { StepProps } from '@mui/material/Step';
import StepButton, { StepButtonProps } from '@mui/material/StepButton';
import { INTER } from 'constants/fonts';

interface IStepButtonProps extends StepButtonProps {
  bordercolor?: string;
  filter?: string;
}

export const StyledStepper = styled(Stepper)<StepperProps>(() => ({
  display: 'flex',
  gap: '12px',
  '& .MuiStepConnector-line': {
    display: 'none',
  },
}));

export const StyledStep = styled(Step)<StepProps>(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0px',
  '&.MuiStepLabel-iconContainer': {
    paddingLeft: '0px',
  },
}));

export const StyledStepButton = styled(StepButton)<IStepButtonProps>(
  ({ theme, bordercolor, filter }) => ({
    borderRadius: '50%',
    width: '36px',
    height: '36px',
    border: '4px solid',
    borderColor: bordercolor,
    backgroundColor: theme.palette.background.paper,
    boxSizing: 'border-box',
    filter: filter,
    cursor: 'default',
    '& .MuiStepIcon-text': {
      fill: theme.palette.secondary.main,
      fontFamily: INTER,
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '21px',
      letterSpacing: '-0.308px',
      boxSizing: 'border-box',
    },
    '&.Mui-active': {
      border: `4px solid ${theme.palette.primary.main}`,
    },
    padding: '0px',
    margin: '0px',
    '.MuiStepLabel-iconContainer': {
      padding: '0px',
    },
    '.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root.Mui-completed':
      {
        border: `4px solid ${theme.palette.success.dark}`,
        borderRadius: '50%',
        width: '36px',
        height: '36px',
        background: theme.palette.success.dark,
      },

    '&:not(.Mui-completed)': {
      '& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root': {
        fill: theme.palette.background.paper,
      },
    },
  })
);
