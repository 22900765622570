import { useRef } from 'react';
import {
  UploadImageWrapper,
  Circle,
  Caption,
  MainWrapper,
  ErrorMessage,
  DeleteIcon,
  DeleteIconButton,
} from './SectionUpload.styled';
import { ButtonUpload, InfoText } from 'ui';
import { InputInvisible } from 'ui/inputs';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { Box } from '@mui/material';

interface ISectionUploadProps {
  handleUpload: (image: File, error?: string | null) => Promise<void>;
  onDelete?: () => void;
  imageUrl: string;
  error?: string | null;
}

const SectionUpload = ({
  handleUpload,
  onDelete,
  imageUrl,
  error,
}: ISectionUploadProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClickUpload = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleDeleteImage = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    onDelete?.();
  };

  return (
    <MainWrapper>
      <UploadImageWrapper>
        <Box
          sx={{
            position: 'relative',
            '&:hover #delete-icon': {
              opacity: 1,
            },
          }}
        >
          <Circle id="image-wrapper">
            {imageUrl ? (
              <img
                style={{ objectFit: 'cover' }}
                src={imageUrl}
                alt="Company profile"
                width={94}
                height={94}
              />
            ) : (
              <PhotoCameraIcon color="secondary" fontSize="large" />
            )}
          </Circle>
          {imageUrl && (
            <DeleteIconButton
              id="delete-icon"
              disableFocusRipple={false}
              onClick={handleDeleteImage}
            >
              <DeleteIcon />
            </DeleteIconButton>
          )}
        </Box>

        <Caption>Company Profile picture</Caption>
        <InputInvisible
          ref={inputRef}
          accept="image/jpeg, image/png, image/gif"
          onUpload={handleUpload}
        />
        <ButtonUpload onClick={handleClickUpload}>Upload new</ButtonUpload>

        {error && <ErrorMessage>{error}</ErrorMessage>}
      </UploadImageWrapper>
      <InfoText text="(Accepted formats: PNG, JPG, GIF. Maximum size: 2MB)" />
    </MainWrapper>
  );
};

export default SectionUpload;
