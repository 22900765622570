import { StyledTitle } from './SectionTitle.styled';

interface ITitleProps {
  children: string;
  texttransform?: 'lowercase' | 'capitalize' | 'uppercase' | 'none';
}

const SectionTitle = ({ texttransform, children }: ITitleProps) => {
  return <StyledTitle texttransform={texttransform}>{children}</StyledTitle>;
};

export default SectionTitle;
