import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { SectionTitle, SecuredByStripe, CheckBox, IconButton } from 'ui';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { StyledTextField } from 'ui/inputs/Input/Input.styled';
import { ButtonsContainer } from 'views';
import { StyledWrapperBox } from './BackupCard.styled';
import { useAppDispatch } from 'hooks';
import { closeModal } from 'reduxStore/modal/modalSlice';

const BackupCard = () => {
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const initialValues = {
    cardHolderName: '',
    ccNumber: '',
    expiryDate: '',
    cvv: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
  };
  const handleSubmit = () => {};

  return (
    <StyledWrapperBox>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <SectionTitle>Backup Card</SectionTitle>
        <IconButton width="34px" isColor onClick={handleCloseModal}>
          <ArrowBackIcon color="primary" />
        </IconButton>
      </Box>
      <SecuredByStripe />
      <Box marginTop="49px">
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {() => (
            <Form>
              <Grid container spacing="38px">
                <Grid item xs={12}>
                  <Field
                    as={StyledTextField}
                    type="secondary"
                    name="cardHolderName"
                    label="Card Holder Name"
                    helperText={<ErrorMessage name="cardHolderName" />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={StyledTextField}
                    type="secondary"
                    name="ccNumber"
                    label="CC Number"
                    helperText={<ErrorMessage name="ccNumber" />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing="29px">
                    <Grid item xs={6}>
                      <Field
                        as={StyledTextField}
                        type="secondary"
                        name="expiryDate"
                        label="Expiry Date"
                        helperText={<ErrorMessage name="expiryDate" />}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        as={StyledTextField}
                        type="secondary"
                        name="cvv"
                        label="CVV"
                        helperText={<ErrorMessage name="cvv" />}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Box marginBottom="38px">
                    <CheckBox label="Same address as Company" />
                  </Box>
                  <Field
                    as={StyledTextField}
                    type="secondary"
                    name="address "
                    label="Address "
                    helperText={<ErrorMessage name="address " />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={StyledTextField}
                    type="secondary"
                    name="city"
                    label="City"
                    helperText={<ErrorMessage name="city" />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing="29px">
                    <Grid item xs={6}>
                      <Field
                        as={StyledTextField}
                        type="secondary"
                        name="state"
                        label="State"
                        helperText={<ErrorMessage name="state" />}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        as={StyledTextField}
                        type="secondary"
                        name="zipCode"
                        label="Zip code"
                        helperText={<ErrorMessage name="zipCode" />}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <ButtonsContainer
                titleButton="Next"
                onClick={handleSubmit}
                onClickBack={handleCloseModal}
                top="40px"
              />
            </Form>
          )}
        </Formik>
      </Box>
    </StyledWrapperBox>
  );
};

export default BackupCard;
