import { ChangeEvent, ReactElement } from 'react';
import { StyledTextField } from './Input.styled';
import { InputProps } from '@mui/material';

interface ICustomInputProps {
  type?: 'text' | 'email' | 'tel' | 'number';
  required?: boolean;
  label?: string;
  placeholder?: string;
  height?: string;
  background?: 'gray' | 'white';
  field?: any;
  form?: any;
  helperText?: ReactElement;
  InputProps?: InputProps;
  onInputChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Input = ({
  field,
  form,
  type,
  onInputChange,
  ...props
}: ICustomInputProps) => {
  const { onChange, ...fieldProps } = field;
  return (
    <StyledTextField
      id={`input-${field.name}`}
      {...fieldProps}
      onChange={onInputChange ?? onChange}
      {...props}
      type={type ?? 'text'}
      error={!!(form.touched[field.name] && form.errors[field.name])}
    />
  );
};

export default Input;
