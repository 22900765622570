import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment } from '@mui/material';
import { StyledTextField } from './Search.styled';

const Search = () => {
  return (
    <StyledTextField
      placeholder="Search"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton>
              <SearchIcon color="secondary" fontSize="large" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default Search;
