import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Logo, StepperProgress, ProgressCircle } from 'ui';
import {
  StyledBox,
  StyledHeaderWrapper,
  StyledProgressWrapper,
} from './StepperCreateProfile.styled';
import { useLocation, useNavigate } from 'react-router-dom';
import { STEPS_NEW_PROFILE } from '../../../../constants/stepsNewProfile';
import { getStepByPathname } from 'utils';

const StepperCreateProfile = () => {
  const totalSteps = 5;
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [progressValue, setProgressValue] = useState(1);

  const location = useLocation();
  const navigate = useNavigate();

  const pathname = location.pathname;
  const searchParams = new URLSearchParams(location.search);
  const stepParam = searchParams.get('step');

  const step = stepParam
    ? parseInt(stepParam, 10)
    : getStepByPathname(pathname);

  const stepFromPath = getStepByPathname(pathname);

  useEffect(() => {
    setCurrentStep(1);
  }, [pathname]);

  useEffect(() => {
    if (!stepParam) {
      searchParams.set('step', stepFromPath.toString());
      setCurrentStep(stepFromPath);
      setProgressValue((stepFromPath / totalSteps) * 100);
      navigate(`${pathname}?${searchParams.toString()}`, { replace: true });
    }
    //  eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    if (stepParam) {
      setCurrentStep(step);
      setProgressValue((step / totalSteps) * 100);
    }
  }, [step, stepParam]);

  return (
    <Box display="flex" justifyContent="center">
      <StyledBox>
        <StyledHeaderWrapper>
          <Logo />
          <StepperProgress
            currentStep={currentStep}
            options={STEPS_NEW_PROFILE}
          />
        </StyledHeaderWrapper>
        <StyledProgressWrapper>
          <ProgressCircle value={progressValue} size="50px" />
        </StyledProgressWrapper>
      </StyledBox>
    </Box>
  );
};

export default StepperCreateProfile;
