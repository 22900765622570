import { Outlet } from 'react-router';
import { DashboardStatsTabsMenu } from 'ui';
import { useAppSelector } from 'hooks';
import { getUsers } from 'reduxStore/users/usersSelectors';
import { Box } from '@mui/material';

const DashboardPage = () => {
  const usersList = useAppSelector(getUsers);

  const menu = {
    connectedUsers: `0/${usersList?.length || 0}`,
    connectedDevices: '0/0',
    malwareBlocked: '0/0',
    adsBlocked: '0/0',
    cloudCenter: '0/0',
  };

  return (
    <>
      <DashboardStatsTabsMenu type="company" menuData={menu} />
      <Box mt="33px">
        <Outlet />
      </Box>
    </>
  );
};

export default DashboardPage;
