import { ChangeEvent, useState } from 'react';
import { Grid, InputAdornment } from '@mui/material';
import Box from '@mui/material/Box';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { SectionTitle } from 'ui';
import { Input } from 'ui/inputs';
import { ROUTES } from 'routes/routes.const';
import { ButtonsContainerMSP } from 'views';
import InputMask from 'react-input-mask';
import {
  ChargeInfo,
  OrgTypeButtonsWrapper,
  OrgTypeTitle,
  Error,
} from './OrgProfile.styled';
import { useNavigate } from 'react-router';
import ButtonOrganizationType from '../../../ui/buttons/ButtonOrganizationType/ButtonOrganizationType';
import { OrgTypeOption } from 'types/organizations.type';
import TagsInput from 'ui/inputs/TagsInput/TagsInput';
import createValidationSchema from './validationSchema';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getDomain } from 'reduxStore/auth/authSelectors';
import { SectionUpload } from './components';
import { BYTES_IN_2MB } from 'constants/validation';
import { getBase64 } from 'utils';
import {
  CHILD,
  COMPANY,
  MSP,
  ORG_TYPE_OPTIONS,
  PARENT,
} from 'constants/organizationsType';
import { IOrgProfileFormValues } from 'types';
import { getOrganizationRequestBody } from 'utils/apiHelpers';
import {
  createOrganization,
  updateOrganization,
} from 'reduxStore/createProfile/createProfileOperations';
import {
  getCreateProfileError,
  getOrganizationId,
  getOrganizationLevel,
  getOrganizationValues,
} from 'reduxStore/createProfile/createProfileSelectors';
import {
  ICreateOrganizationBody,
  IUpdateOrganizationBody,
} from 'types/api.type';
import { getMainOrganizationID } from 'reduxStore/organization/organizationSelectors';
import { setOrganizationValues } from 'reduxStore/createProfile/createProfileSlice';

export type ButtonsOrgTypeStatus = 'active' | 'inactive';

const OrgProfile = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const organizationLevel = useAppSelector(getOrganizationLevel);
  const organizationID = useAppSelector(getOrganizationId);
  const organizationValues = useAppSelector(getOrganizationValues);
  const mspId = useAppSelector(getMainOrganizationID);
  const domain = useAppSelector(getDomain);
  const error = useAppSelector(getCreateProfileError);

  const setInitialOrgType = () => {
    if (organizationValues) {
      return organizationValues?.is_msp ? MSP : COMPANY;
    }
    return MSP;
  };

  const [base64Image, setBase64Image] = useState(
    organizationValues?.logo || ''
  );
  const [imageError, setImageError] = useState<string | null>(null);
  const [selectedOrgType, setSelectedOrgType] =
    useState<OrgTypeOption>(setInitialOrgType);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleDeleteImage = () => {
    setBase64Image('');
    setImageError(null);
  };

  const handleImageError = (error: string) => {
    setBase64Image('');
    setImageError(error);
    setTimeout(() => setImageError(null), 3000);
  };

  const handleUpload = async (image: File) => {
    if (!(image instanceof File)) {
      handleImageError('Invalid file type. Please upload a valid image file.');
      return;
    }
    setImageError(null);

    if (image.size > BYTES_IN_2MB) {
      handleImageError('Image size must be less than 2MB');
      return;
    }
    try {
      await getBase64(
        image,
        result => setBase64Image(result),
        () => handleImageError('Error uploading image. Please, try again')
      );
    } catch (err) {
      handleImageError('Error uploading image. Please, try again');
    }
  };

  const handleChangeOrgTypeOption = (option: OrgTypeOption) => {
    setSelectedOrgType(option);
  };

  const setInitialValues = (): IOrgProfileFormValues => {
    const values = {
      name: organizationValues?.name ?? '',
      admins: organizationValues?.admins ?? [],
      street: organizationValues?.address.street ?? '',
      city: organizationValues?.address.city ?? '',
      state: organizationValues?.address.state ?? '',
      zipCode: organizationValues?.address.zip_code ?? '',
    };
    if (organizationLevel === CHILD) {
      const margin = organizationValues?.margin?.toString() ?? '';
      return { ...values, margin };
    }
    return values;
  };

  const handleSubmit = async (values: IOrgProfileFormValues) => {
    const body = getOrganizationRequestBody(values, {
      orgType: organizationLevel === PARENT ? selectedOrgType : undefined,
      logo: base64Image,
      id: organizationID, // for update organization
      ...(organizationLevel === CHILD && { mspId }),
    });

    try {
      if (organizationID) {
        await dispatch(
          updateOrganization(body as IUpdateOrganizationBody)
        ).unwrap();
      } else {
        await dispatch(
          createOrganization(body as ICreateOrganizationBody)
        ).unwrap();
      }
      dispatch(setOrganizationValues(body));
      navigate(ROUTES.BILLING);
    } catch (error) {
      return;
    }
  };

  return (
    <Box component="section" position="relative">
      <SectionTitle>{`${organizationLevel === CHILD ? 'Client' : 'Organization'} Profile`}</SectionTitle>
      <Formik
        initialValues={setInitialValues()}
        validationSchema={createValidationSchema(organizationLevel)}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <Box display="flex" flexDirection="row" gap="15vw" marginTop="46px">
              <Box>
                <Grid container spacing="38px" width="100%" maxWidth="630px">
                  <Grid item xs={12}>
                    <Field
                      component={Input}
                      name="name"
                      label="Company Name"
                      helperText={<ErrorMessage name="name" />}
                      background="gray"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TagsInput}
                      name="admins"
                      label="Global Administrators"
                      placeholder="Add emails by pressing Enter..."
                      helperText={<ErrorMessage name="admins" />}
                      background="gray"
                      domain={domain}
                      orgLevel={organizationLevel}
                      isSubmitting={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={Input}
                      name="street"
                      label="Address"
                      helperText={<ErrorMessage name="street" />}
                      background="gray"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={Input}
                      name="city"
                      label="City"
                      helperText={<ErrorMessage name="city" />}
                      background="gray"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={Input}
                      name="state"
                      label="State"
                      helperText={<ErrorMessage name="state" />}
                      background="gray"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field name="zipCode">
                      {({ field, form }: any) => (
                        <InputMask
                          mask="99999"
                          maskChar="_"
                          {...field}
                          field={field}
                          form={form}
                        >
                          {(inputProps: any) => (
                            <Input
                              {...inputProps}
                              background="gray"
                              label="Zip code"
                              helperText={<ErrorMessage name="zipCode" />}
                              field={inputProps.field}
                              form={inputProps.form}
                            />
                          )}
                        </InputMask>
                      )}
                    </Field>
                  </Grid>
                </Grid>

                <Box marginTop="48px">
                  {organizationLevel === PARENT && (
                    <>
                      <OrgTypeTitle>Organization Type:</OrgTypeTitle>
                      <OrgTypeButtonsWrapper>
                        {ORG_TYPE_OPTIONS.map(option => (
                          <ButtonOrganizationType
                            key={option}
                            option={option}
                            status={
                              selectedOrgType === option ? 'active' : 'inactive'
                            }
                            onClick={handleChangeOrgTypeOption}
                          />
                        ))}
                      </OrgTypeButtonsWrapper>
                    </>
                  )}

                  {organizationLevel === CHILD && (
                    <Box
                      display="flex"
                      gap="22px"
                      alignItems="center"
                      marginBottom="72px"
                    >
                      <Box width="100%" maxWidth="168px">
                        <Field name="margin">
                          {({ field, form }: { field: any; form: any }) => (
                            <Input
                              field={field}
                              form={form}
                              label="Margin"
                              helperText={<ErrorMessage name="margin" />}
                              background="gray"
                              onInputChange={(
                                event: ChangeEvent<HTMLInputElement>
                              ) => {
                                const value = event.target.value;
                                if (!value || /^\d*\.?\d{0,2}$/.test(value)) {
                                  form.setFieldValue('margin', value);
                                }
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    %
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        </Field>
                      </Box>
                      <ChargeInfo>
                        How much you up-charge your client
                      </ChargeInfo>
                    </Box>
                  )}
                </Box>
              </Box>
              <SectionUpload
                handleUpload={handleUpload}
                onDelete={handleDeleteImage}
                imageUrl={base64Image}
                error={imageError}
              />
            </Box>
            <Box marginTop="47px">
              <ButtonsContainerMSP
                titleButton="Next"
                type="submit"
                onClick={() => setIsSubmitting(true)}
              />
            </Box>
          </Form>
        )}
      </Formik>

      {error && <Error>{error}</Error>}
    </Box>
  );
};

export default OrgProfile;
