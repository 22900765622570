import {
  StyledWrapperCard,
  StyledWrapperIconVisa,
  StyledBox,
  StyledTypography,
} from './PaymentCard.styled';
import { IconVisa, ButtonPaymentCard } from 'ui';

const PaymentCard = () => {
  return (
    <StyledWrapperCard>
      <StyledWrapperIconVisa>
        <IconVisa />
      </StyledWrapperIconVisa>
      <StyledBox>
        <StyledTypography>Add A backup Card ?</StyledTypography>
        <ButtonPaymentCard />
      </StyledBox>
    </StyledWrapperCard>
  );
};

export default PaymentCard;
