import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardBackground from '../../assets/images/paymentCard.jpg';
import { JURA } from 'constants/fonts';

export const StyledWrapperCard = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  backgroundImage: `url(${CardBackground})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '517px',
  height: '267px',
  border: `1px solid ${theme.palette.shades.dark}`,
  borderRadius: '16px',
}));

export const StyledWrapperIconVisa = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  marginTop: '16px',
  marginLeft: '36px',
}));

export const StyledBox = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '9px',
  marginLeft: '66px',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.background.paper,
  fontFamily: JURA,
  fontSize: '20px',
  fontWeight: 500,
  lineHeight: '30px',
  letterSpacing: '-0.44px',
}));
