import { styled } from '@mui/material/styles';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

interface IIconButtonProps extends IconButtonProps {
  width?: string;
  borderradius?: string;
  bordersize?: string;
  iscolor?: string;
  isuser?: 'true';
}

export const StyledIconButton = styled(IconButton)<IIconButtonProps>(
  ({ theme, width, borderradius, bordersize, iscolor, isuser }) => ({
    color: isuser ? theme.palette.common.white : theme.palette.primary.main,
    width: width ? width : '57px',
    height: width ? width : '57px',
    border: bordersize ? `${bordersize}px solid` : '1px solid',
    borderColor:
      iscolor === 'true'
        ? 'none'
        : isuser
          ? '#AAC2FF'
          : theme.palette.info.main,
    borderRadius: borderradius ? borderradius : '50%',
    backgroundColor:
      iscolor === 'true'
        ? '#ACE1FF'
        : isuser
          ? theme.palette.primary.main
          : theme.palette.background.paper,
    '&:hover, &:active': {
      backgroundColor:
        iscolor === 'true'
          ? theme.palette.silver.contrastText
          : isuser
            ? theme.palette.primary.dark
            : theme.palette.info.main,
    },
  })
);
