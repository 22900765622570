import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import Divider, { DividerProps } from '@mui/material/Divider';
import { theme } from 'theme';
import { PaperProps } from '@mui/material';
import { INTER } from 'constants/fonts';

interface IMenuItemProps extends MenuItemProps {
  version?: 'actionUser' | 'action2' | 'periodOfTime';
}

interface IDividerProps extends DividerProps {
  version?: 'actionUser' | 'action2' | 'periodOfTime';
}

interface ISelectProps {
  warningaction?: string;
  successaction?: string;
  version?: 'actionUser' | 'action2' | 'periodOfTime';
}

export const MenuProps: Partial<{ PaperProps: Partial<PaperProps> }> = {
  PaperProps: {
    style: {
      boxSizing: 'border-box',
      padding: '0px 10px',
      borderRadius: '9px',
      background: theme.palette.common.white,
      boxShadow:
        '6px 2px 16px 0px rgba(136, 165, 191, 0.48), -6px -2px 16px 0px rgba(255, 255, 255, 0.80)',
    },
  },
};

export const StyledBox = styled(Box)<BoxProps>(() => ({
  // width: '113px',
  width: 'fit-content',
  height: '36px',
  position: 'relative',
}));

export const StyledFormControl = styled(FormControl)<FormControlProps>(() => ({
  position: 'relative',
}));

export const StyledSelect = styled(Select)<ISelectProps>(
  ({ theme, warningaction, version }) => ({
    width: '100%',
    height: '36px',
    borderRadius: '21px',
    border: `1px solid ${theme.palette.silver.contrastText}`,
    background: 'rgba(255, 255, 255, 0)',
    padding: '6px 18x',
    color: theme.palette.secondary.main,
    fontFamily: INTER,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    letterSpacing: '-0.264px',
    textTransform: 'uppercase',
    backgroundColor:
      version === 'actionUser' ? theme.palette.shades.main : 'inherit',
    '&.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
      paddingRight: '52px',
    },
    '&.MuiSelect-icon': {
      color:
        warningaction === 'true' ? 'transparent' : theme.palette.secondary.main,
      padding: '0px 10px',
    },
    '&.Mui-focused': {
      background:
        warningaction === 'true'
          ? theme.palette.error.light
          : theme.palette.shades.main,
      color:
        warningaction === 'true'
          ? theme.palette.common.white
          : theme.palette.secondary.main,
      '&.Mui-selected': {
        background:
          warningaction === 'true'
            ? theme.palette.error.light
            : theme.palette.shades.main,
        color: theme.palette.common.white,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.text.disabled}`,
      },
    },
  })
);

export const StyledMenuItem = styled(MenuItem)<IMenuItemProps>(
  ({ theme, version }) => ({
    color: theme.palette.primary.dark,
    fontSize: '14px',
    fontFamily: INTER,
    fontWeight: 600,
    lineHeight: '21px',
    letterSpacing: '-0.308px',
    textTransform: version === 'periodOfTime' ? 'none' : 'uppercase',
    padding: '0',
    gap:
      version === 'actionUser' || version === 'periodOfTime' ? '0px' : '10px',
    justifyContent:
      version === 'actionUser'
        ? 'space-between'
        : version === 'periodOfTime'
          ? 'start'
          : 'center',
    '&:hover, &:focus': {
      color: theme.palette.primary.main,
      background: 'transparent',
    },
    '& +.MuiDivider-root': {
      margin: 0,
    },
  })
);

export const StyledDivider = styled(Divider)<IDividerProps>(({ version }) => ({
  display: 'block',
  width: version === 'actionUser' ? '91px' : '132px',
  height: '1px',
  background: '#D7D7D7',
}));
