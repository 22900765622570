import { styled } from '@mui/material/styles';
import FormControlLabel, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { INTER, POPPINS } from 'constants/fonts';

interface IFormControlLabelProps extends FormControlLabelProps {
  label: string;
  checkboxsize?: 'small' | 'large';
  texttransform?: 'lowercase' | 'capitalize' | 'uppercase' | 'none';
  padding?: string;
  fontWeight?: '400' | '500' | '600';
  gap?: string;
}

interface ICheckboxProps extends CheckboxProps {
  label?: string;
  checkboxsize?: 'small' | 'large';
  padding?: string;
  checkboxcolor?: 'dark' | 'bright';
}

export const StyledFormControlLabel = styled(
  FormControlLabel
)<IFormControlLabelProps>(
  ({ theme, checkboxsize, texttransform = 'none', fontWeight = 600, gap }) => ({
    gap: gap,
    width: 'fit-content',
    '& .MuiFormControlLabel-label': {
      color: theme.palette.primary.dark,
      fontFamily: INTER,
      fontWeight: fontWeight,
      fontSize: checkboxsize === 'small' ? '12px' : '14px',
      lineHeight: checkboxsize === 'small' ? '18px' : '21px',
      letterSpacing: checkboxsize === 'small' ? '-0.264px' : '-0.308px',
      textTransform: texttransform,
    },
  })
);

export const StyledCheckbox = styled(Checkbox)<ICheckboxProps>(
  ({ theme, checkboxsize, padding, checkboxcolor }) => ({
    width: checkboxsize === 'small' ? '18px' : 'initial',
    height: checkboxsize === 'small' ? '18px' : 'initial',
    color:
      checkboxcolor === 'dark'
        ? theme.palette.secondary.light
        : theme.palette.silver.contrastText,
    fontFamily: POPPINS,
    padding: padding,
  })
);
