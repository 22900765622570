import { Typography, TypographyProps, styled } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import { INTER } from 'constants/fonts';

interface IWrapperChartProps extends BoxProps {
  isroundedbar?: 'true' | 'false';
}

export const ContainerChart = styled(Box)<BoxProps>(({ theme }) => ({
  position: 'relative',
  maxWidth: '100%',
  overflowX: 'hidden',
  overflowY: 'auto',
  display: 'flex',
  paddingLeft: '60px',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '20px',
  alignSelf: 'stretch',
}));

export const FixedYAxisContainer = styled(Box)<BoxProps>(({ theme }) => ({
  position: 'absolute',
  left: '0px',
  top: '0px',
  zIndex: 1,
  width: '60px',
}));

export const WrapperChart = styled(Box)<IWrapperChartProps>(
  ({ theme, isroundedbar }) => ({
    width: '100%',
    height: isroundedbar === 'true' ? '251' : '370px',
    overflowX: 'auto',
    cursor: 'pointer',
    '&::-webkit-scrollbar': {
      width: '100%',
      height: '9px',
      borderRadius: '17px',
      background: theme.palette.silver.dark,
      marginLeft: '55px !important',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '17px',
      height: '9px',
      width: '258px',
    },
  })
);

export const WrapperTooltip = styled(Box)(({ theme }) => ({
  width: '156px',
  height: '55px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: theme.palette.secondary.main,
}));

export const Circle = styled(Box)<BoxProps>(({ theme }) => ({
  width: '14px',
  height: '14px',
  borderRadius: '50%',
  border: `2px solid ${theme.palette.primary.main}`,
  background: theme.palette.background.paper,
  position: 'relative',
  zIndex: 2,
}));

export const TooltipContainer = styled(Box)<BoxProps>(({ theme }) => ({
  width: '126px',
  height: '51px',
  borderRadius: '10px',
  border: `2px solid #86C0E1`,
  background: theme.palette.background.paper,
  display: 'flex',
  alignItems: 'center',
  gap: '1px',
  position: 'relative',
  zIndex: 1,
  '&::before': {
    content: "''",
    position: 'absolute',
    top: '50%',
    left: '-17px',
    transform: 'translateY(-50%)',
    width: '0',
    height: '0',
    borderRight: `25px solid ${theme.palette.common.white}`,
    borderTop: '7px solid transparent',
    borderBottom: '7px solid transparent',
  },
}));

export const Arrow = styled(Box)(() => ({
  position: 'absolute',
  zIndex: 0,
  top: '50%',
  left: '5px',
  transform: 'translateY(-50%)',
  width: '0',
  height: '0',
  borderRight: `25px solid #86C0E1`,
  borderTop: `7px solid transparent`,
  borderBottom: `7px solid transparent`,
}));

export const WrapperTextTooltip = styled(Box)<BoxProps>(() => ({
  fontFamily: INTER,
  fontSize: '12px',
  lineHeight: '18px',
  letterSpacing: '-0.264px',
  textTransform: 'uppercase',
  marginLeft: '17px',
  marginRight: '10px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
}));

export const ValueTooltip = styled(Typography)<TypographyProps>(() => ({
  fontWeight: 600,
  marginRight: '5px',
}));

export const TextTooltip = styled(Typography)<TypographyProps>(() => ({
  fontWeight: 400,
}));
