import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import { Link, LinkProps, Typography, TypographyProps } from '@mui/material';
import { COLORS } from 'theme/colors.const';
import { ISTOK_WEB } from 'constants/fonts';

export const WrapperTextField = styled(Box)<BoxProps>(() => ({
  maxWidth: '572px',
  marginTop: '29px',
  marginLeft: '-28px',
}));

export const StyledText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.text.disabled,
  fontFamily: ISTOK_WEB,
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '-0.352px',
}));

export const LinkToExport = styled(Typography)<TypographyProps>(() => ({
  color: '#61A7E8',
  fontFamily: ISTOK_WEB,
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '-0.352px',
  cursor: 'pointer',
}));

export const UploadingPanel = styled(Box)<BoxProps>(() => ({
  background: '#DAF0F2',
  border: '1px dashed #46C2CA',
  width: '682px',
  maxWidth: '60vw',
  height: '330px',
  maxHeight: '70vh',
  boxSizing: 'content-box',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const UploadingPanelText = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.green50,
  textAlign: 'center',
  fontFamily: ISTOK_WEB,
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '18px',
  letterSpacing: '-0.264px',
}));

export const UploadingPanelLink = styled(Link)<LinkProps>(() => ({
  color: COLORS.green50,
  textAlign: 'center',
  fontFamily: ISTOK_WEB,
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '18px',
  letterSpacing: '-0.264px',
  cursor: 'pointer',
}));
