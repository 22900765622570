import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

export const StyledWrapperBox = styled(Box)<BoxProps>(({ theme }) => ({
  minWidth: '517px',
  width: '30vw',
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.background.paper,
  padding: '47px 76px 54px 76px',
}));
