import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setError, setLoading } from 'reduxStore/extraReducersHelpers';
import {
  IUserType,
  OrgLevelOption,
  ICloudFromServerType,
  ICloudFromServerReformatedType,
  IRegionFromServerType,
  IRegionFromServerReformatedType,
} from 'types/organizations.type';
import { ILoadableState } from 'types/store.type';
import {
  createOrganization,
  retrieveClouds,
  updateOrganization,
} from './createProfileOperations';
import {
  ICreateOrganizationBody,
  IUpdateOrganizationBody,
} from 'types/api.type';

interface ICreateProfileState extends ILoadableState {
  creatingOrganization: boolean;
  organizationLevel: OrgLevelOption | null;
  // create / update organization
  organizationID: number | null;
  organizationValues: IUpdateOrganizationBody | ICreateOrganizationBody | null;
  // import users
  usersListImported: IUserType[] | null;
  clouds: ICloudFromServerReformatedType[];
}

const initialState: ICreateProfileState = {
  creatingOrganization: false,
  organizationLevel: null,
  // create/update organization
  organizationID: null,
  organizationValues: null,
  // import users
  usersListImported: null,
  clouds: [],
  loading: false,
  error: null,
};

const createProfileSlice = createSlice({
  name: 'createProfile',
  initialState,
  reducers: {
    setCreatingOrganization(state, action: PayloadAction<boolean>) {
      state.creatingOrganization = action.payload;
    },
    setOrganizationLevel(state, action: PayloadAction<OrgLevelOption | null>) {
      state.organizationLevel = action.payload;
    },
    setOrganizationValues(
      state,
      action: PayloadAction<
        IUpdateOrganizationBody | ICreateOrganizationBody | null
      >
    ) {
      state.organizationValues = action.payload;
    },
    setUsersList(state, action: PayloadAction<IUserType[] | null>) {
      state.usersListImported = action.payload;
    },
    addOption(
      state,
      action: PayloadAction<{ cloudProvider: string; regionId: number }>
    ) {
      const { cloudProvider, regionId } = action.payload;
      state.clouds.forEach((cloud: ICloudFromServerReformatedType) => {
        if (cloud.name === cloudProvider) {
          cloud.regions.forEach((region: IRegionFromServerReformatedType) => {
            if (region.defaultRegion.id === regionId) {
              region.checked = true;
            }
          });
        }
      });
    },
    removeOption(
      state,
      action: PayloadAction<{ cloudProvider: string; regionId: number }>
    ) {
      const { cloudProvider, regionId } = action.payload;
      state.clouds.forEach((cloud: ICloudFromServerReformatedType) => {
        if (cloud.name === cloudProvider) {
          cloud.regions.forEach((region: IRegionFromServerReformatedType) => {
            if (region.defaultRegion.id === regionId) {
              region.checked = false;
            }
          });
        }
      });
    },

    selectAllOptions(state, action: PayloadAction<{ cloudProvider: string }>) {
      const { cloudProvider } = action.payload;
      state.clouds.forEach((cloud: ICloudFromServerReformatedType) => {
        if (cloud.name === cloudProvider) {
          cloud.regions.forEach((region: IRegionFromServerReformatedType) => {
            region.checked = true;
          });
        }
      });
    },
    deselectAllOptions(
      state,
      action: PayloadAction<{ cloudProvider: string }>
    ) {
      const { cloudProvider } = action.payload;
      state.clouds.forEach((cloud: ICloudFromServerReformatedType) => {
        if (cloud.name === cloudProvider) {
          cloud.regions.forEach((region: IRegionFromServerReformatedType) => {
            region.checked = false;
          });
        }
      });
    },
    clearCreateProfileData() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      // createOrganization
      .addCase(createOrganization.pending, setLoading)
      .addCase(createOrganization.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.organizationID = parseInt(payload.id) ?? null;
      })
      .addCase(createOrganization.rejected, setError)
      // updateOrganization
      .addCase(updateOrganization.pending, setLoading)
      .addCase(updateOrganization.fulfilled, state => {
        state.loading = false;
      })
      .addCase(updateOrganization.rejected, setError)
      // retrieveClouds
      .addCase(retrieveClouds.pending, setLoading)
      .addCase(retrieveClouds.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload) {
          const updatedClouds: ICloudFromServerReformatedType[] =
            payload.clouds.map((cloud: ICloudFromServerType) => {
              const regions: IRegionFromServerReformatedType[] =
                cloud.regions.map((region: IRegionFromServerType) => ({
                  defaultRegion: { ...region },
                  checked: false,
                }));
              return {
                ...cloud,
                regions: regions,
              };
            });
          state.clouds = updatedClouds;
        }
      })
      .addCase(retrieveClouds.rejected, setError);
  },
});

export const {
  setCreatingOrganization,
  setOrganizationLevel,
  setOrganizationValues,
  setUsersList,
  addOption,
  removeOption,
  selectAllOptions,
  deselectAllOptions,
  clearCreateProfileData,
} = createProfileSlice.actions;

export default createProfileSlice.reducer;
