import { RootState } from 'reduxStore/store';

const getIsMSP = (state: RootState) =>
  state.organization.mainOrganization?.is_msp;

const getUserOrganizations = (state: RootState) =>
  state.organization.userOrganizations;

const getMainOrganization = (state: RootState) =>
  state.organization.mainOrganization;
const getMainOrganizationID = (state: RootState) =>
  state.organization.mainOrganization?.id;
const mainOrganizationExists = (state: RootState) =>
  !!state.organization.mainOrganization;

const userGotOrganizations = (state: RootState) => {
  const { userOrganizations } = state.organization;
  return (
    !!userOrganizations &&
    (!!userOrganizations.main || userOrganizations.clients.length > 0)
  );
};

const getClients = (state: RootState) => state.organization.clients;
const getSelectedClient = (state: RootState) =>
  state.organization.selectedClient;

export {
  getIsMSP,
  getUserOrganizations,
  getMainOrganization,
  getMainOrganizationID,
  mainOrganizationExists,
  userGotOrganizations,
  getClients,
  getSelectedClient,
};
