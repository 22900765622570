import { createSlice } from '@reduxjs/toolkit';
import { signIn } from './authOperations';

export interface IAuthError {
  title: string;
  text: string;
}
interface IAuthState {
  token: string | null;
  email: string | null;
  loading: boolean;
  error: IAuthError | null;
}

const initialState: IAuthState = {
  token: null,
  email: null,
  loading: false,
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuth: () => initialState,
    setAuthError: (state, { payload }) => {
      state.error = payload;
    },
    resetAuthError: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      // signIn
      .addCase(signIn.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signIn.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.token = payload?.token;
        state.email = payload?.email;
      })
      .addCase(signIn.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = (payload as IAuthError) ?? null;
      });
  },
});

export const { resetAuth, setAuthError, resetAuthError } = authSlice.actions;

export default authSlice.reducer;
