import {
  StyledStripe,
  StyledWrapperBox,
  StyledTypography,
} from './SecuredByStripe.styled';

const SecuredByStripe = () => {
  return (
    <StyledWrapperBox>
      <StyledStripe />
      <StyledTypography>secured by stripe</StyledTypography>
    </StyledWrapperBox>
  );
};

export default SecuredByStripe;
