import { styled } from '@mui/material';
import Container, { ContainerProps } from '@mui/material/Container';
import Box, { BoxProps } from '@mui/material/Box';

export const StyledContainer = styled(Container)<ContainerProps>(() => ({
  background: 'F9F9F9',
  width: '100%',
}));

export const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  background: theme.palette.background.paper,
  width: '100vw',
  minHeight: '100vh',
  height: 'fit-content',
  boxSizing: 'border-box',
}));
