import { useAppSelector } from 'hooks';
import { Navigate } from 'react-router';
import { getIsAuth } from 'reduxStore/auth/authSelectors';
import { userGotOrganizations } from 'reduxStore/organization/organizationSelectors';
import { ROUTES } from 'routes/routes.const';
import { getIsCreatingOrganization } from 'reduxStore/createProfile/createProfileSelectors';

interface IProtectedProfileRouteProps {
  children: JSX.Element;
}

const ProtectedProfileRoute = ({ children }: IProtectedProfileRouteProps) => {
  const isAuth = useAppSelector(getIsAuth);
  const userHasOrganizations = useAppSelector(userGotOrganizations);
  const isCreatingOrganization = useAppSelector(getIsCreatingOrganization);

  if (!isAuth) {
    return <Navigate to={ROUTES.SIGNIN} replace />;
  }

  if (userHasOrganizations && !isCreatingOrganization) {
    return <Navigate to={ROUTES.DASHBOARD} replace />;
  }

  return children;
};

export default ProtectedProfileRoute;
