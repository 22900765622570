import { Box, Typography, TypographyProps } from '@mui/material';
import { BoxProps, styled } from '@mui/material';
import { INTER } from 'constants/fonts';

export const Wrapper = styled(Box)<BoxProps>(() => ({
  width: 'fit-content',
  height: 'fit-content',
  display: 'flex',
  flexDirection: 'row',
  gap: '17px',
  justifyContent: 'space-between',
}));

export const IconBox = styled(Box)<BoxProps>(({ theme }) => ({
  borderRadius: '50%',
  width: '62px',
  height: '62px',
  border: `2px solid ${theme.palette.blue.dark}`,
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const InsideIconBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: '23px',
  height: '23px',
  background: theme.palette.background.paper,
  borderRadius: '2px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const WrapperAmount = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
  justifyContent: 'space-between',
  alignItems: 'end',
}));

export const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontFamily: INTER,
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '21px',
  letterSpacing: '-0.308px',
  textTransform: 'uppercase',
}));

export const StyledAmount = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    color: theme.palette.secondary.main,
    fontFamily: INTER,
    fontSize: '36px',
    fontWeight: 600,
    lineHeight: '36px',
    letterSpacing: '-0.792px',
  })
);
