import { Box, Grid } from '@mui/material';
import {
  TableBasis,
  ButtonOval,
  SelectAction,
  ButtonChange,
  IconButton,
} from 'ui';
import { DASHBOARD_USER_CONNECTED_USER_EDIT_HEAD_TABLE } from 'constants/tablesHead';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { StyledTypography } from 'ui/tables/TableBasis/TableBasis.styled';
import { StyledTextField } from 'ui/inputs/Input/Input.styled';
import { theme } from 'theme';
import SyncIcon from '@mui/icons-material/Sync';
import { ADMIN } from 'constants/organizationsType';

const EditUserView = () => {
  const initialValues = {
    userName: '',
    title: '',
    totalBandwidthUsed: '',
    email: '',
    department: '',
    securityGroup: '',
    notes: '',
    accountStatus: '',
  };

  const user = { firstName: 'James', lastName: 'Jones' };

  const tableData = [
    {
      'Device Name': 'AeroBlaze S1',
      Platform: 'PC',
      'Ip Address': '185.53.178.9',
      'Bandwidth Used': '112.1MB',
      Status: '',
      'Up-Time': '01/01/2023 11:12:00GMT',
    },
    {
      'Device Name': 'AeroGaze Ultra',
      Platform: 'PC',
      'Ip Address': '185.53.178.9',
      'Bandwidth Used': '112.1MB',
      Status: '',
      'Up-Time': '01/01/2023 11:12:00GMT',
    },
    {
      'Device Name': 'ElectraSync Proton',
      Platform: 'PC',
      'Ip Address': '185.53.178.9',
      'Bandwidth Used': '112.1MB',
      Status: '',
      'Up-Time': '01/01/2023 11:12:00GMT',
    },
    {
      'Device Name': 'EchoRift Vortex',
      Platform: 'PC',
      'Ip Address': '185.53.178.9',
      'Bandwidth Used': '112.1MB',
      Status: '',
      'Up-Time': '01/01/2023 11:12:00GMT',
    },
    {
      'Device Name': 'HyperWave Apex',
      Platform: 'PC',
      'Ip Address': '185.53.178.9',
      'Bandwidth Used': '112.1MB',
      Status: '',
      'Up-Time': '01/01/2023 11:12:00GMT',
    },
    {
      'Device Name': 'InfinityGlide 9',
      Platform: 'PC',
      'Ip Address': '185.53.178.9',
      'Bandwidth Used': '112.1MB',
      Status: '',
      'Up-Time': '01/01/2023 11:12:00GMT',
    },
    {
      'Device Name': 'NeoGlide Prism',
      Platform: 'PC',
      'Ip Address': '185.53.178.9',
      'Bandwidth Used': '112.1MB',
      Status: '',
      'Up-Time': '01/01/2023 11:12:00GMT',
    },
  ];

  const handleSubmit = () => {};

  return (
    <Box>
      <Box
        bgcolor={`${theme.palette.background.paper}`}
        border={'1px solid'}
        borderColor={`${theme.palette.silver.dark}`}
        marginTop="49px"
        marginBottom="21px"
        padding="20px 40px 0px 40px"
        width="calc(100% - 82px)"
        height="309px"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="31px"
        >
          <StyledTypography type={ADMIN}>
            Details {user.firstName} {user.lastName}
          </StyledTypography>
          <ButtonOval variant="outlined" buttoncolor="blue" isIcon>
            Save
          </ButtonOval>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box width="87%">
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              {() => (
                <Form>
                  <Grid container spacing="35px">
                    <Grid item xs={4}>
                      <Grid container flexDirection="column" spacing="35px">
                        <Grid item xs={12}>
                          <Field
                            as={StyledTextField}
                            type="secondary"
                            name="userName"
                            label="User Name"
                            helperText={<ErrorMessage name="userName" />}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            as={StyledTextField}
                            type="secondary"
                            name="title"
                            label="Title"
                            helperText={<ErrorMessage name="title" />}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            as={StyledTextField}
                            type="secondary"
                            name="totalBandwidthUsed"
                            label="Total Bandwidth Used"
                            helperText={
                              <ErrorMessage name="totalBandwidthUsed" />
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container flexDirection="column" spacing="35px">
                        <Grid item xs={12}>
                          <Field
                            as={StyledTextField}
                            type="secondary"
                            name="email"
                            label="Email"
                            helperText={<ErrorMessage name="email" />}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            as={StyledTextField}
                            type="secondary"
                            name="department"
                            label="Department"
                            helperText={<ErrorMessage name="department" />}
                          />
                        </Grid>
                        <Grid item xs={12} position="relative">
                          <Field
                            as={StyledTextField}
                            type="secondary"
                            name="securityGroup"
                            label="Security Group"
                            helperText={<ErrorMessage name="securityGroup" />}
                          />
                          <Box
                            position="absolute"
                            top="48px"
                            right="12px"
                            zIndex="1"
                          >
                            <ButtonChange />
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container flexDirection="column" spacing="35px">
                        <Grid item xs={12} position="relative">
                          <Field
                            height="123px"
                            as={StyledTextField}
                            type="secondary"
                            name="notes"
                            label="Notes"
                            helperText={<ErrorMessage name="notes" />}
                          />
                          <Box
                            position="absolute"
                            top="126px"
                            right="38px"
                            zIndex="1"
                          >
                            <ButtonOval
                              buttoncolor="black"
                              width="44px"
                              height="20px"
                            >
                              Cancle
                            </ButtonOval>
                          </Box>
                          <Box
                            position="absolute"
                            top="123px"
                            right="10px"
                            zIndex="1"
                          >
                            <IconButton width="20px" isUser="true">
                              <SyncIcon
                                fontSize="small"
                                stroke={`${theme.palette.common.white}`}
                              />
                            </IconButton>
                          </Box>
                        </Grid>
                        <Grid item xs={12} position="relative">
                          <Field
                            as={StyledTextField}
                            type="secondary"
                            name="accountStatus"
                            label="Account Status"
                            helperText={<ErrorMessage name="accountStatus" />}
                          />
                          <Box
                            position="absolute"
                            top="48px"
                            right="12px"
                            zIndex="1"
                          >
                            <ButtonChange />
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box marginTop="169px" marginRight="3%">
                    <ButtonOval variant="outlined" buttoncolor="black" isIcon>
                      Send Email
                    </ButtonOval>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Box>
      <Box>
        <TableBasis
          title="Client Device List"
          type={ADMIN}
          columns={DASHBOARD_USER_CONNECTED_USER_EDIT_HEAD_TABLE}
          tableData={tableData}
          action={<SelectAction version="actionUser" name="Action" />}
        />
      </Box>
    </Box>
  );
};

export default EditUserView;
