import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import InputLabel, { InputLabelProps } from '@mui/material/InputLabel';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { PaperProps } from '@mui/material';
import { INTER, ISTOK_WEB } from 'constants/fonts';

interface IBoxProps extends BoxProps {
  version?: 'newRule' | 'formSelect';
}

interface IMenuItemProps extends MenuItemProps {
  version?: 'newRule' | 'formSelect';
}

interface IInputLabelProps extends InputLabelProps {
  showasterisk?: string;
}

export const MenuProps: Partial<{ PaperProps: Partial<PaperProps> }> = {
  PaperProps: {
    style: {
      maxHeight: '448px',
      borderRadius: '9px',
      display: 'flex',
      flexDirection: 'column',
      boxSizing: 'border-box',
      padding: '10px 9px 0px 9px',
      boxShadow:
        '6px 2px 16px 0px rgba(136, 165, 191, 0.48), -6px -2px 16px 0px rgba(255, 255, 255, 0.80)',
      fontSize: '14px',
      fontFamily: INTER,
      fontWeight: 600,
      lineHeight: '21px',
      letterSpacing: '-0.308px',
    },
  },
};

export const StyledBox = styled(Box)<IBoxProps>(({ version }) => ({
  width: version === 'newRule' ? '335px' : '100%',
  height: '44px',
}));

export const StyledInputLabel = styled(InputLabel)<IInputLabelProps>(
  ({ theme, showasterisk }) => ({
    color: theme.palette.text.disabled,
    fontFamily: ISTOK_WEB,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21px',
    letterSpacing: '-0.308px',
    transformOrigin: 'top',
    transform: 'translateX(18px) translateY(12px)',
    '&.MuiInputLabel-shrink': {
      transform:
        showasterisk === 'true'
          ? 'translate(11px, -9px) scale(0.9)'
          : 'translate(14px, -9px) scale(0.9)',
    },
    '&::after':
      showasterisk === 'true'
        ? {
            content: '"*"',
            color: theme.palette.error.main,
            marginLeft: '4px',
          }
        : {},
  })
);

export const StyledSelect = styled(Select)(({ theme }) => ({
  width: '100%',
  justifyContent: 'center',
  height: 'fit-content',
  '& .MuiInputBase-input': {
    color: theme.palette.primary.dark,
    fontSize: '14px',
    fontFamily: INTER,
    fontWeight: 600,
    lineHeight: '21px',
    letterSpacing: '-0.308px',
    background: theme.palette.background.paper,
    '&.MuiOutlinedInput-input': {
      paddingTop: '12px',
      paddingBottom: '12px',
    },
  },
  '& .MuiSelect-icon': {
    fontSize: '24px',
  },
}));

export const StyledMenuItem = styled(MenuItem)<IMenuItemProps>(
  ({ theme, version }) => ({
    background: theme.palette.background.paper,
    color: theme.palette.primary.dark,
    fontSize: '14px',
    fontFamily: INTER,
    fontWeight: 600,
    lineHeight: '21px',
    letterSpacing: '-0.308px',
    justifyContent: version === 'newRule' ? 'center' : 'flex-start',
    alignItems: 'center',
    '& +.MuiDivider-root': {
      margin: 0,
    },
  })
);
