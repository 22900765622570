import { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import { SelectChangeEvent } from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import {
  StyledBox,
  StyledInputLabel,
  StyledSelect,
  StyledMenuItem,
  MenuProps,
} from './SelectMain.styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const variant = [
  'Advertising',
  'Blogging',
  'Business Management',
  'Email',
  'Gaming',
];

interface ISelectMainProps {
  version?: 'newRule' | 'formSelect';
  label?: string;
  placeholderStart?: string;
  placeholderEnd?: string;
  showAsterisk?: boolean;
  error?: string | boolean;
}

const SelectMain = ({
  version,
  label,
  placeholderStart,
  placeholderEnd,
  showAsterisk,
  error,
}: ISelectMainProps) => {
  const [selectedRule, setSelectedRule] = useState<string>('');

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setSelectedRule(event.target.value as string);
  };

  return (
    <StyledBox>
      <FormControl fullWidth>
        {version === 'newRule' ? (
          <StyledInputLabel
            htmlFor="new-rule"
            showasterisk={showAsterisk ? 'true' : 'false'}
          >
            {label}
          </StyledInputLabel>
        ) : (
          <StyledInputLabel
            shrink
            htmlFor="select-form"
            showasterisk={showAsterisk ? 'true' : 'false'}
          >
            {label}
          </StyledInputLabel>
        )}
        <StyledSelect
          displayEmpty
          value={selectedRule}
          label={label}
          error={!!error}
          onChange={handleChange}
          MenuProps={MenuProps}
          renderValue={() => {
            return selectedRule ? (
              <>{selectedRule}</>
            ) : (
              <Box display="flex" flexDirection="row">
                <Typography variant="subtitle2" marginRight="6px">
                  {placeholderStart}
                </Typography>
                <Typography>{placeholderEnd}</Typography>
              </Box>
            );
          }}
        >
          {variant.map((variantItem, index) => [
            <StyledMenuItem key={index} value={variantItem}>
              {variantItem}
            </StyledMenuItem>,
            index < variant.length - 1 && <Divider key={`divider-${index}`} />,
          ])}
        </StyledSelect>
      </FormControl>
    </StyledBox>
  );
};

export default SelectMain;
