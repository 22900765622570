const getDecodedValue = (value: string) => {
  const decodedValue = decodeURIComponent(value.trim());
  if (decodedValue.toLowerCase() === 'true') {
    return true;
  } else if (decodedValue.toLowerCase() === 'false') {
    return false;
  } else {
    return decodedValue;
  }
};

export default getDecodedValue;
