import { Typography, TypographyProps, styled } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import { POPPINS } from 'constants/fonts';

interface ITypographyProps extends TypographyProps {
  iscontrast?: 'true';
}

export const Wrapper = styled(Box)<BoxProps>(({ theme }) => ({
  boxSizing: 'border-box',
  width: '522px',
  height: '393px',
  borderRadius: '18px',
  background: theme.palette.background.paper,
  border: 'none',
  padding: '47px',
  display: 'flex',
  flexDirection: 'column',
  gap: '54px',
  alignItems: 'center',
}));

export const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontFamily: POPPINS,
  fontSize: '32px',
  fontWeight: 500,
  lineHeight: '48px',
  letterSpacing: '-0.704px',
}));

export const Content = styled(Typography)<ITypographyProps>(
  ({ theme, iscontrast }) => ({
    color: iscontrast
      ? theme.palette.primary.main
      : theme.palette.secondary.main,
    textAlign: 'center',
    fontFamily: POPPINS,
    fontSize: '32px',
    fontWeight: 400,
    lineHeight: '48px',
    display: 'inline-block',
  })
);
