import { useEffect, useState } from 'react';
import {
  IconGoogle,
  IconAmazon,
  IconOracle,
  IconAzure,
  IconIBMCloud,
  IconCloud,
  LoadingSign,
} from 'ui';
import {
  TabsWrapper,
  TabForCloud,
  CloudInfo,
  TabsTitle,
} from './SelectCloudProvider.styled';
import Box from '@mui/material/Box';
import CheckBoxGroup from './components/CheckBoxGroup';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getClouds } from 'reduxStore/createProfile/createProfileSelectors';
import { ICloudFromServerReformatedType } from 'types/organizations.type';
import { retrieveClouds } from 'reduxStore/createProfile/createProfileOperations';

interface ITabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = ({ children, index, value }: ITabPanelProps) => {
  return value === index ? <Box padding="0 26px">{children}</Box> : null;
};

const SelectCloudProvider = () => {
  const dispatch = useAppDispatch();
  const clouds = useAppSelector(getClouds);
  const isLoading = useAppSelector(state => state.createProfile.loading);

  useEffect(() => {
    dispatch(retrieveClouds(null));
  }, [dispatch]);

  const [value, setValue] = useState(0);

  const getIcon = (cloudName: String) => {
    switch (cloudName) {
      case 'Google Cloud':
        return <IconGoogle />;
      case 'Amazon Web Services':
        return <IconAmazon />;
      case 'Oracle Cloud':
        return <IconOracle />;
      case 'Microsoft Azure':
        return <IconAzure />;
      case 'IBM Cloud':
        return <IconIBMCloud />;
      default:
        return <IconCloud />;
    }
  };

  const options = clouds.map((cloud: ICloudFromServerReformatedType) => ({
    icon: getIcon(cloud.name),
    title: cloud.name,
    status: cloud.is_coming_soon ? '(Coming Soon)' : '',
    content: (
      <CheckBoxGroup
        cloudProvider={cloud.name}
        fontWeight="400"
        regions={cloud.regions}
        title="Select Regions"
      ></CheckBoxGroup>
    ),
  }));

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  if (isLoading) {
    return <LoadingSign />;
  }
  return (
    <>
      <TabsWrapper value={value} onChange={handleChange}>
        {options.map((item, index) => (
          <TabForCloud
            key={`select-cloud-provider-tab-${index}`}
            label={
              <CloudInfo>
                {item.icon}
                <TabsTitle>{item.title}</TabsTitle>
                {item.status && <TabsTitle>{item.status}</TabsTitle>}
              </CloudInfo>
            }
          />
        ))}
      </TabsWrapper>
      {options.map((item, index) => (
        <CustomTabPanel key={`tab-panel-${index}`} index={index} value={value}>
          {item.content}
        </CustomTabPanel>
      ))}
    </>
  );
};

export default SelectCloudProvider;
