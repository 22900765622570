import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import { CheckBox, InfoText, SectionTitle } from 'ui';
import { ButtonsContainer } from 'views';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { StyledTextField } from 'ui/inputs/Input/Input.styled';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router';
import { ROUTES } from 'routes/routes.const';

const Config = () => {
  const navigate = useNavigate();
  const initialValues = {
    securityEndpointName: '',
    saasProvider: '',
    serverType: '',
    serviceBlocking: '',
    transportProtocol: '',
    vpnPortNumber: '',
    dnsServers: '',
    vpnProtocol: '',
  };

  const handleSubmit = () => {
    navigate(ROUTES.SELECT_CLOUD);
  };

  return (
    <div>
      <SectionTitle>Configure your first Security Endpoint</SectionTitle>
      <Box marginBottom="25px" marginTop="46px">
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {() => (
            <Form>
              <Grid container flexDirection="row" spacing="82px">
                <Grid item xs={6}>
                  <Grid container flexDirection="column" spacing="38px">
                    <Grid item xs={12}>
                      <Field
                        as={StyledTextField}
                        type="secondary"
                        name="securityEndpointName"
                        label="Security Endpoint Name"
                        helperText={
                          <ErrorMessage name="securityEndpointName" />
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h2" marginBottom="33px">
                        Allowed Service (SAAS) Providers
                      </Typography>
                      <Field
                        as={StyledTextField}
                        type="secondary"
                        name="saasProvider"
                        label="SAAS Provider"
                        helperText={<ErrorMessage name="saasProvider" />}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={StyledTextField}
                        type="secondary"
                        name="serverType"
                        label="Server Type"
                        helperText={<ErrorMessage name="serverType" />}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={StyledTextField}
                        type="secondary"
                        name="serviceBlocking"
                        label="Service Blocking"
                        helperText={<ErrorMessage name="serviceBlocking" />}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="h2" marginBottom="33px">
                    Advanced Options
                  </Typography>
                  <Grid container flexDirection="column" spacing="38px">
                    <Grid item xs={12}>
                      <Field
                        as={StyledTextField}
                        type="secondary"
                        name="transportProtocol"
                        label="Transport Protocol"
                        helperText={<ErrorMessage name="transportProtocol" />}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={StyledTextField}
                        type="secondary"
                        name="vpnPortNumber"
                        label="VPN port Number"
                        helperText={<ErrorMessage name="vpnPortNumber" />}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={StyledTextField}
                        type="secondary"
                        name="dnsServers"
                        label="DNS Servers"
                        helperText={<ErrorMessage name="dnsServers" />}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={StyledTextField}
                        type="secondary"
                        name="vpnProtocol"
                        label="VPN Protocol"
                        helperText={<ErrorMessage name="vpnProtocol" />}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
      <Box marginBottom="38px">
        <Typography variant="h2" marginBottom="20px">
          Features
        </Typography>
        <Box display="flex" gap="40px" width="100%">
          <CheckBox label="Ad blocking" />
          <CheckBox label="Malware Blocking" />
          <CheckBox label="Intrusion Detection" />
        </Box>
        <Box display="flex" gap="40px">
          <CheckBox label="MFA" />
          <CheckBox label="Service Blocking" />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        gap="38px"
        alignItems="center"
        width="100%"
      >
        <InfoText text="Security Endpoint: Think of a Security Endpoint as an isolated firewall that allows you to organize your users by cloud, region, and group.  You can have one or many Security Endpoints configured that allow users to securely connect to your critical services, filter dangerous activity, and more. " />
        <ButtonsContainer titleButton="Next" onClick={handleSubmit} />
      </Box>
    </div>
  );
};

export default Config;
