import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

interface IStatusBoxProps extends BoxProps {
  status?:
    | 'healthy'
    | 'disable'
    | 'attention'
    | 'available'
    | 'offline'
    | 'creating'
    | 'high'
    | 'medium'
    | 'low'
    | 'yes'
    | 'no';
  isstarticon?: 'true';
}

export const StyledStatusBox = styled(Box)<IStatusBoxProps>(
  ({ theme, status, isstarticon }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    textAlign: 'center',
    width: 'fit-content',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '15px',
    letterSpacing: '-0.264px',
    textTransform:
      (status === 'healthy' && !isstarticon) ||
      (status === 'disable' && !isstarticon) ||
      (status === 'attention' && !isstarticon)
        ? 'capitalize'
        : 'uppercase',
    borderRadius: '33px',
    padding: '3px 10px',
    gap: '4px',
    color:
      (status === 'healthy' && isstarticon) || status === 'available'
        ? theme.palette.success.light
        : status === 'healthy'
          ? theme.palette.primary.dark
          : (status === 'disable' && isstarticon) || status === 'offline'
            ? '#5D5D5D'
            : (status === 'attention' && isstarticon) || status === 'creating'
              ? theme.palette.black.dark
              : status === 'medium'
                ? '#323029'
                : theme.palette.background.paper,
    background:
      (status === 'healthy' && isstarticon) ||
      status === 'available' ||
      status === 'yes'
        ? theme.palette.success.dark
        : status === 'healthy'
          ? theme.palette.success.light
          : (status === 'disable' && isstarticon) || status === 'offline'
            ? theme.palette.gray.contrastText
            : status === 'disable'
              ? theme.palette.silver.contrastText
              : (status === 'attention' && isstarticon) || status === 'creating'
                ? theme.palette.warning.light
                : status === 'attention'
                  ? '#FFCD29'
                  : status === 'medium'
                    ? theme.palette.warning.main
                    : status === 'high'
                      ? theme.palette.error.dark
                      : theme.palette.secondary.dark,
  })
);
