import { ReactNode } from 'react';
import ActionButton from '../ActionButton/ActionButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { COLORS } from 'theme/colors.const';

interface AddButtonProps {
  color?: 'white' | 'blue' | 'black';
  onClick?: () => void;
  disabled?: boolean;
  needsborder?: 'false' | 'true';
  children: ReactNode | string;
}

const AddButton = ({
  color,
  onClick,
  disabled,
  needsborder,
  children,
}: AddButtonProps) => {
  const styles = {
    white: {
      background: COLORS.white,
      color: COLORS.main,
      borderColor: COLORS.superLilac,
    },
    blue: {
      background: COLORS.main,
      color: COLORS.white,
      borderColor: COLORS.superLilac,
    },
    black: {
      background: COLORS.black,
      color: COLORS.white,
      borderColor: COLORS.grayDarkBorder,
    },
  };

  return (
    <ActionButton
      styles={color ? styles[color] : undefined}
      endIcon={<AddCircleIcon fontSize="large" />}
      disabled={disabled}
      needsborder={needsborder}
      onClick={onClick}
    >
      {children}
    </ActionButton>
  );
};

export default AddButton;
