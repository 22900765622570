import { Box, BoxProps, styled } from '@mui/material';

export const AmountWrapper = styled(Box)<BoxProps>(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '30px',
}));
