import { useRef, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useAppDispatch } from 'hooks';
import { FlatButton } from 'ui/buttons';
import { InputInvisible } from 'ui/inputs';
import { theme } from 'theme';
import { parseCSV, getDecodedValue } from 'utils';
import {
  UploadingPanelLink,
  UploadingPanelText,
  UploadingPanel,
} from '../../Import.styled';
import {
  closeModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';
import { setUsersList } from 'reduxStore/createProfile/createProfileSlice';
import { IUserType } from 'types';

const UploadFile = () => {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileUpload = (file: File) => {
    setUploadedFile(file);
  };

  const handleContinue = () => {
    // opening modal with uploading progress
    dispatch(setContent('ProgressImporting'));
    dispatch(setModalProps({ radius: '12px', progress: 0 }));

    //uploading file
    if (uploadedFile) {
      const reader = new FileReader();
      // getting uploading progress
      reader.onprogress = event => {
        if (event.lengthComputable) {
          const percentLoaded = Math.round((event.loaded / event.total) * 100);
          dispatch(setModalProps({ radius: '12px', progress: percentLoaded }));
        }
      };

      reader.onloadstart = () => {
        dispatch(setModalProps({ radius: '12px', progress: 0 }));
      };

      reader.onload = async e => {
        const text = e.target?.result as string;
        const { data, columns } = parseCSV(text);

        const uploadData = await Promise.all(
          data.map(row => {
            return columns.reduce((obj: IUserType, col: string) => {
              obj[col.trim()] = getDecodedValue(row[col] as string);
              return obj;
            }, {} as IUserType);
          })
        );
        dispatch(setUsersList(uploadData));
      };
      reader.readAsText(uploadedFile);

      reader.onloadend = () => {
        dispatch(setModalProps({ radius: '12px', progress: 100 }));
      };

      setTimeout(() => {
        dispatch(closeModal());
      }, 1000);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files && files.length > 0) {
      handleFileUpload(files[0]);
    }
  };

  return (
    <Box
      padding="118px 52px 42px"
      display="flex"
      flexDirection="column"
      gap="54px"
      alignItems="center"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <UploadingPanel>
        <Stack alignItems="center">
          <UploadingPanelText>
            Drop a file here to upload, or
          </UploadingPanelText>
          <UploadingPanelLink onClick={handleClick}>
            click here to browse
          </UploadingPanelLink>
          <InputInvisible
            ref={inputRef}
            accept=".csv"
            onUpload={handleFileUpload}
          />
          {uploadedFile && (
            <Box display="flex" flexDirection="row" gap="10px" mt="16px">
              <Typography variant="body1">Choosen file:</Typography>
              <Typography variant="body1" color={theme.palette.primary.main}>
                {uploadedFile.name}
              </Typography>
            </Box>
          )}
        </Stack>
      </UploadingPanel>
      <FlatButton
        variant="contained"
        onClick={handleContinue}
        isDisabled={!uploadedFile}
      >
        Continue
      </FlatButton>
    </Box>
  );
};

export default UploadFile;
