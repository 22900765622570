import { Box, Grid } from '@mui/material';
import { Formik, Form } from 'formik';
import { StyledTypography } from '../../../ui/tables/TableBasis/TableBasis.styled';
import { ButtonOval, IconButton, SelectMain } from 'ui';
import { theme } from 'theme';
import SyncIcon from '@mui/icons-material/Sync';
import { StyledTextField } from 'ui/inputs/Input/Input.styled';

const AddNewUserView = () => {
  const initialValues = {
    userName: '',
    title: '',
    description: '',
    email: '',
    department: '',
    securityGroup: '',
    accountStatus: '',
  };

  const handleSubmit = () => {};

  return (
    <Box
      bgcolor={theme.palette.background.paper}
      border="1px solid"
      borderColor={theme.palette.silver.dark}
      marginTop="49px"
      marginBottom="21px"
      padding="20px 40px 64px"
      width="57%"
    >
      <StyledTypography>Add New User</StyledTypography>
      <Box marginTop="30px">
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {() => (
            <Form>
              <Grid container flexDirection="row" spacing="35px">
                <Grid item xs={6}>
                  <Grid container flexDirection="column" spacing="35px">
                    <Grid item xs={12}>
                      <StyledTextField
                        label="User Name"
                        placeholder="Enter First and last name"
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <StyledTextField
                        label="Title"
                        placeholder="Enter you title"
                      />
                    </Grid>
                    <Grid item xs={12} position="relative">
                      <StyledTextField
                        height="130px"
                        label="Description"
                        placeholder="Can someone please provide me with access to the necessary tools/resources to complete this task?"
                      />
                      <Box
                        position="absolute"
                        bottom="12px"
                        right="38px"
                        zIndex="1"
                      >
                        <ButtonOval
                          buttoncolor="black"
                          width="44px"
                          height="20px"
                        >
                          Cancel
                        </ButtonOval>
                      </Box>
                      <Box
                        position="absolute"
                        bottom="12px"
                        right="10px"
                        zIndex="1"
                      >
                        <IconButton width="20px" isUser="true">
                          <SyncIcon
                            fontSize="small"
                            stroke={theme.palette.background.paper}
                          />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container flexDirection="column" spacing="35px">
                    <Grid item xs={12}>
                      <StyledTextField
                        label="Email"
                        placeholder="Enter Email address"
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <StyledTextField
                        label="Department"
                        placeholder="Enter your department"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SelectMain
                        version="formSelect"
                        label="Security Group"
                        showAsterisk
                        placeholderStart="Group A"
                        placeholderEnd="(by default)"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SelectMain
                        version="formSelect"
                        label="Account Status"
                        placeholderStart="Active"
                        placeholderEnd="(by default)"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Box
                display="flex"
                justifyContent="flex-end"
                gap="20px"
                marginTop="56px"
              >
                <ButtonOval variant="contained" buttoncolor="blackwhite" isIcon>
                  Add Bulk Users
                </ButtonOval>
                <ButtonOval variant="contained" buttoncolor="bluewhite" isIcon>
                  Add User
                </ButtonOval>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default AddNewUserView;
