import { LoaderContainer, LoaderWrapper } from './FullScreenLoader.styled';
import { CircularProgress } from '@mui/material';

const FullScreenLoader = () => {
  return (
    <LoaderContainer>
      <LoaderWrapper>
        <CircularProgress />
      </LoaderWrapper>
    </LoaderContainer>
  );
};

export default FullScreenLoader;
