import { Box } from '@mui/material';
import { ChartBars, Section, SelectAction, TableBasis } from 'ui';
import { DEVICE_LIST_HEAD_TABLE } from 'constants/tablesHead';
import { theme } from 'theme';
import { ADMIN } from 'constants/organizationsType';

const UsersBandwidthConsumedView = () => {
  const datasetChartBars = [
    { value: 2, label: 'Jan' },
    { value: 5, label: 'Fev' },
    { value: 1, label: 'Mar' },
    { value: 4, label: 'Apr' },
    { value: 2, label: 'May' },
    { value: 3, label: 'June' },
    { value: 5, label: 'July' },
    { value: 10, label: 'Aug' },
    { value: 12, label: 'Sept' },
    { value: 7, label: 'Oct' },
    { value: 9, label: 'Nov' },
    { value: 6, label: 'Dec' },
    { value: 2, label: 'Jan' },
    { value: 15, label: 'Fev' },
    { value: 1, label: 'Mar' },
    { value: 4, label: 'Apr' },
    { value: 21, label: 'May' },
    { value: 3, label: 'June' },
    { value: 55, label: 'July' },
    { value: 10, label: 'Aug' },
    { value: 12, label: 'Sept' },
    { value: 7, label: 'Oct' },
    { value: 29, label: 'Nov' },
    { value: 6, label: 'Dec' },
    { value: 25, label: 'July' },
    { value: 18, label: 'Aug' },
    { value: 12, label: 'Sept' },
    { value: 17, label: 'Oct' },
    { value: 39, label: 'Nov' },
    { value: 60, label: 'Dec' },
  ];

  const tableData = [
    {
      Device: 'Cameron Iphone',
      'First & Last name': 'Dianne Russell',
      Status: '',
      Bandwidth: '3.6 GB',
    },
    {
      Device: 'Cameron Iphone',
      'First & Last name': 'Dianne Russell',
      Status: '',
      Bandwidth: '3.6 GB',
    },
    {
      Device: 'Cameron Iphone',
      'First & Last name': 'Dianne Russell',
      Status: '',
      Bandwidth: '3.6 GB',
    },
    {
      Device: 'Cameron Iphone',
      'First & Last name': 'Dianne Russell',
      Status: '',
      Bandwidth: '3.6 GB',
    },
    {
      Device: 'Cameron Iphone',
      'First & Last name': 'Dianne Russell',
      Status: '',
      Bandwidth: '3.6 GB',
    },
  ];
  return (
    <Box position="relative">
      <Section
        title="Bandwidth Consumed"
        background={theme.palette.background.paper}
        paddingLeft="0px"
        paddingLeftTitle="40px"
      >
        <ChartBars
          dataset={datasetChartBars}
          textTooltip="users"
          textTooltipAction="bandwidth"
        />
      </Section>
      <Box position="absolute" top="20px" right="40px">
        <SelectAction version="periodOfTime" name="Last Month" />
      </Box>
      <Box marginTop="28px">
        <TableBasis
          type={ADMIN}
          title="Device List"
          columns={DEVICE_LIST_HEAD_TABLE}
          tableData={tableData}
          icon="remove"
        />
      </Box>
    </Box>
  );
};

export default UsersBandwidthConsumedView;
