import { Typography, styled } from '@mui/material';
import { POPPINS } from 'constants/fonts';

interface ITitleProps {
  texttransform?: 'lowercase' | 'capitalize' | 'uppercase' | 'none';
}

export const StyledTitle = styled(Typography)<ITitleProps>(
  ({ theme, texttransform = 'capitalize' }) => ({
    color: theme.palette.secondary.main,
    fontFamily: POPPINS,
    fontSize: '32px',
    fontWeight: 500,
    lineHeight: '48px',
    letterSpacing: '-0.704px',
    textTransform: texttransform,
    marginBottom: '7px',
  })
);
