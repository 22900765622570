import { styled } from '@mui/material/styles';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import InputLabel, { InputLabelProps } from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { theme } from 'theme';
import { INTER, ISTOK_WEB } from 'constants/fonts';

export const MenuProps = {
  PaperProps: {
    style: {
      padding: '10px 0px',
      borderRadius: '9px',
      background: theme.palette.common.white,
      boxShadow:
        '6px 2px 16px 0px rgba(136, 165, 191, 0.48), -6px -2px 16px 0px rgba(255, 255, 255, 0.80)',
    },
  },
};

export const StyledFormControl = styled(FormControl)(() => ({
  position: 'relative',
  width: '335px',
}));

export const StyledTypography = styled(Typography)<TypographyProps>(() => ({
  position: 'absolute',
  top: '12px',
  left: '21px',
  zIndex: '10',
}));

export const StyledInputLabel = styled(InputLabel)<InputLabelProps>(
  ({ theme }) => ({
    color: theme.palette.text.disabled,
    fontFamily: ISTOK_WEB,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21px',
    letterSpacing: '-0.308px',
    borderRadius: '4px',
    background: theme.palette.common.white,
    padding: '0px 10px',
    '&.MuiInputLabel-shrink': {
      transform: 'translate(11px, -11px) scale(1.1)',
    },
    '&.Mui-focused': {
      color: theme.palette.text.disabled,
      background: theme.palette.secondary.contrastText,
      borderTop: `1px solid ${theme.palette.text.disabled}`,
      borderRight: `1px solid ${theme.palette.text.disabled}`,
      borderLeft: `1px solid ${theme.palette.text.disabled}`,
    },
  })
);

export const StyledSelect = styled(Select)(({ theme }) => ({
  width: '100%',
  height: '44px',
  background: theme.palette.common.white,
  justifyContent: 'center',
  '& .MuiInputBase-input': {
    color: theme.palette.primary.dark,
    fontSize: '14px',
    fontFamily: INTER,
    fontWeight: 600,
    lineHeight: '21px',
    letterSpacing: '-0.308px',
    textTransform: 'capitalize',
  },
  '& .MuiOutlinedInput-root': {
    height: '44px',
  },
  '&.Mui-focused': {
    background: theme.palette.secondary.contrastText,
    '& .MuiSelect-icon': {
      color: theme.palette.text.disabled,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.text.disabled}`,
    },
  },
}));

export const StyledCheckbox = styled(Checkbox)(() => ({
  paddingLeft: '21px',
}));

export const StyledMenuItem = styled(MenuItem)<MenuItemProps>(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '14px',
  fontFamily: INTER,
  fontWeight: 600,
  lineHeight: '21px',
  letterSpacing: '-0.308px',
  textTransform: 'capitalize',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px',
}));
