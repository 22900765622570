import { useEffect, useState } from 'react';
import { StyledSwitch } from './Switch.styled';

interface ISwitchProps {
  enabled?: boolean;
  onChangeSwitch?: () => void;
}

const Switch = (props: ISwitchProps) => {
  const { enabled, onChangeSwitch } = props;

  const [checked, setChecked] = useState<boolean>(enabled ?? false);

  useEffect(() => {
    setChecked(enabled ?? false);
  }, [enabled]);

  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    onChangeSwitch && onChangeSwitch();
  };

  return <StyledSwitch checked={checked} onChange={handleChangeSwitch} />;
};

export default Switch;
