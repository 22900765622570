import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { formatTick } from 'utils';
import { LINES } from 'constants/lineGraph';
import {
  StyledBox,
  StyledWrapper,
  StyledLegendBox,
  StyledCircle,
  StyledTypography,
} from './LineGraph.styled';

interface ILineGraphProps {
  data: {
    time: string;
    AWS: number;
    'Google Cloud': number;
    Azure: number;
    Oracle: number;
  }[];
}

const RenderLegend = () => {
  return (
    <StyledWrapper>
      {LINES.map((data, index) => (
        <StyledLegendBox key={index}>
          <StyledCircle background={data.color} beforecolor={data.color} />
          <StyledTypography>{data.dataKey}</StyledTypography>
        </StyledLegendBox>
      ))}
    </StyledWrapper>
  );
};

const LineGraph = ({ data }: ILineGraphProps) => {
  const domainLineGraph = [0, 22]; // delete when there is an api
  const ticksLineGraph = [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22]; // delete when there is an api
  const width = `${data.length * 10}%`;
  return (
    <StyledBox>
      <ResponsiveContainer width={width} height={350}>
        <LineChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: -32,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="time" tickFormatter={formatTick} />
          <YAxis domain={domainLineGraph} ticks={ticksLineGraph} />
          <Legend verticalAlign="top" content={RenderLegend} height={19} />
          <Tooltip trigger="click" />
          {LINES.map((line, index) => (
            <Line
              key={index}
              type="linear"
              dataKey={line.dataKey}
              stroke={line.color}
              dot={{
                stroke: line.color,
                strokeWidth: '9px',
                fill: line.color,
              }}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </StyledBox>
  );
};

export default LineGraph;
