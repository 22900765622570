import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import { Typography } from '@mui/material';
import { INTER } from 'constants/fonts';

export const StyledWrapperText = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  padding: '12px 16px',
}));

export const StyledTypographyRed = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontFamily: INTER,
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '21px',
  letterSpacing: '-0.308px',
  textTransform: 'capitalize',
}));

export const StyledTypographyBlue = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontFamily: INTER,
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '21px',
  letterSpacing: '-0.308px',
  textTransform: 'capitalize',
}));
