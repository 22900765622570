import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import { POPPINS } from 'constants/fonts';

export const StyledButtonPaymentCard = styled(Button)<ButtonProps>(
  ({ theme }) => ({
    width: '186px',
    height: '38px',
    borderRadius: '76px',
    background: theme.palette.background.paper,
    color: theme.palette.secondary.main,
    fontFamily: POPPINS,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '21px',
    letterSpacing: '-0.308px',
    justifyContent: 'space-between',
    paddingLeft: '17px',
    paddingRight: '11px',
    '&:hover': {
      background: theme.palette.blue.contrastText,
    },
  })
);
