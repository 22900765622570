import { styled } from '@mui/material';
import { ReactComponent as IconGoogle } from '../../../assets/images/googleCloud.svg';
import Box from '@mui/material/Box';

export const StyledWrapperBox = styled(Box)(({ theme }) => ({
  height: '46px',
  width: '46px',
  borderRadius: '50%',
  background: theme.palette.common.white,
  alignContent: 'center',
}));

export const StyledIconGoogle = styled(IconGoogle)(() => ({
  height: '33px',
  width: '33px',
}));
