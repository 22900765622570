import { ButtonSetUpLater } from './ButtonsContainer.styled';
import { FlatButton } from 'ui';
import { useNavigate } from 'react-router';
import { Box } from '@mui/material';

interface IButtonsContainer {
  titleButton: string;
  onClick?: () => void;
  onClickSetUpLater?: () => void;
  setUpLater?: boolean;
  type?: 'submit' | 'button';
  disabled?: boolean;
}

const ButtonsContainerMSP = ({
  titleButton,
  setUpLater,
  onClick,
  onClickSetUpLater,
  type,
  disabled,
}: IButtonsContainer) => {
  const navigate = useNavigate();

  const handleClick = () => {
    onClick && onClick();
  };

  const handleClickSetUpLater = () => {
    onClickSetUpLater && onClickSetUpLater();
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      width="100%"
    >
      <FlatButton onClick={() => navigate(-1)}>Back</FlatButton>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        gap="12px"
      >
        {setUpLater && (
          <ButtonSetUpLater variant="text" onClick={handleClickSetUpLater}>
            Set up later
          </ButtonSetUpLater>
        )}
        <FlatButton
          variant="contained"
          onClick={handleClick}
          type={type ?? 'button'}
          isDisabled={disabled}
        >
          {titleButton}
        </FlatButton>
      </Box>
    </Box>
  );
};

export default ButtonsContainerMSP;
