import { styled } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import Typography from '@mui/material/Typography';

export const StyledDemoContainer = styled(DemoContainer)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '0px',
}));

export const StyledDateTimePicker = styled(DateTimePicker)({
  width: '100px',
  borderRadius: '8px',
  height: '34px',
  paddingBottom: '22px',
  paddingLeft: '0px',
  marginTop: '0px !important',
});

export const StyledTypography = styled(Typography)({
  display: 'flex',
  justifyContent: 'start',
  paddingBottom: '0px',
  fontSize: '16px',
});
