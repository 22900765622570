import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { ITabMenuProps } from 'types';

const useGetNavItem = (url: string, options: ITabMenuProps[]) => {
  const [value, setValue] = useState(0);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname !== url) {
      const parentPath = pathname.split('/').filter(String)[1];
      const parentIndex = options.findIndex(option => {
        return option.url.includes(parentPath);
      });

      setValue(parentIndex !== -1 ? parentIndex : 0);
    }
    if (pathname === url) {
      setValue(0);
    }
    // eslint-disable-next-line
  }, [pathname, url, options]);

  return value;
};

export default useGetNavItem;
