import { SyntheticEvent, useState } from 'react';
import {
  TabsForUserMenu,
  IconMonitor,
  IconBug,
  IconHorn,
  IconUser,
  IconCloud,
} from 'ui';
import { IMenuPropsDashboard, IOrganizationsType } from 'types';
import { ROUTES } from 'routes/routes.const';
import { Tab } from '@mui/material';
import { useGetNavItem } from 'hooks';
import { StyledLink, StyledTabs } from '../StatsTabsMenu.styled';

interface INavTabsProps extends IOrganizationsType {
  menuData?: IMenuPropsDashboard | undefined;
}

const DashboardStatsTabsMenu = ({ menuData, type }: INavTabsProps) => {
  const options = [
    {
      title: 'Connected Users',
      data: `${menuData?.connectedUsers}` || '-',
      icon: <IconUser />,
      url: ROUTES.DASHBOARD,
    },
    {
      title: 'Connected Devices',
      data: `${menuData?.connectedDevices}` || '-',
      icon: <IconMonitor />,
      url: ROUTES.CONNECTED_DEVICES,
    },
    {
      title: 'Malware blocked',
      data: `${menuData?.malwareBlocked}` || '-',
      icon: <IconBug />,
      url: ROUTES.MALWARE_BLOCKED,
    },
    {
      title: 'ADS BLOCKED',
      data: `${menuData?.adsBlocked}` || '-',
      icon: <IconHorn />,
      url: ROUTES.ADS_BLOCKED,
    },
    {
      title: 'CLOUD CENTER',
      data: `${menuData?.cloudCenter}` || '-',
      icon: <IconCloud />,
      url: ROUTES.CLOUDS_CENTER,
    },
  ];

  const activeIndex = useGetNavItem('/', options);
  const [value, setValue] = useState(activeIndex);

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <StyledTabs value={value} onChange={handleChange}>
      {options.map((option, index) => (
        <Tab
          key={`navigation-tab_user-${index}`}
          type={type}
          component={StyledLink}
          to={option.url}
          label={
            <TabsForUserMenu
              type={type}
              title={option.title}
              data={option.data}
              icon={option.icon}
            />
          }
        />
      ))}
    </StyledTabs>
  );
};

export default DashboardStatsTabsMenu;
