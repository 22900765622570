export const getBase64 = async (
  image: File,
  successCallback: (base64String: string) => void,
  errorCallback: () => void
) => {
  const reader = new FileReader();
  reader.onload = () => {
    const result = reader.result as string;
    successCallback(result);
  };
  reader.onerror = error => {
    console.error(error);
    errorCallback();
  };
  reader.readAsDataURL(image);
};
