import { styled } from '@mui/material';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import Tab, { TabProps } from '@mui/material/Tab';

export const StyledTabs = styled(Tabs)<TabsProps>(({ theme }) => ({
  background: 'transparent',
  '& .MuiTabs-flexContainer': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  '& .Mui-selected': {
    '& > div:first-of-type > div:first-of-type': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    '& > div:first-of-type > div:first-of-type(2)': {
      color: theme.palette.primary.main,
    },
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

export const StyledTab = styled(Tab)<TabProps>(() => ({
  '&.MuiButtonBase-root': {
    width: 'fit-content',
    height: 'fit-content',
    padding: 0,
    transition: '0.15s linear',
  },
}));
