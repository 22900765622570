import { styled } from '@mui/material/styles';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { INTER } from 'constants/fonts';
import Box, { BoxProps } from '@mui/material/Box';

export const Wrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '9px',
}));

export const ItemOfList = styled(ListItem)<ListItemProps>(() => ({
  gap: '9px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontFamily: INTER,
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '21px',
  letterSpacing: '-0.308px',
}));

export const DividerVariants = styled(Divider)(({ theme }) => ({
  width: '99%',
  height: '1px',
  borderColor: theme.palette.extra.dark,
  marginLeft: '20px',
}));
