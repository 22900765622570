import { StylesType } from 'types';
import { StyledIconEdit } from './IconEdit.styled';

interface IIconEdit {
  version?: 'action';
  styles?: StylesType;
}

const IconEdit = ({ version, styles }: IIconEdit) => {
  return <StyledIconEdit version={version} sx={styles} />;
};

export default IconEdit;
