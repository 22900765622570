import { styled } from '@mui/material';
import Stack, { StackProps } from '@mui/material/Stack';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { POPPINS, INTER } from 'constants/fonts';

export const StyledWrapperBox = styled(Stack)<StackProps>(({ theme }) => ({
  alignItems: 'center',
  boxSizing: 'border-box',
  width: '403px',
  height: '469px',
  padding: '85px 94px 35px',
  borderRadius: '9px',
  backgroundColor: theme.palette.background.paper,
  boxShadow:
    '0px 6px 24px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.08)',
}));

export const StyledTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  fontFamily: INTER,
  fontSize: '32px',
  fontWeight: 700,
  lineHeight: '48px',
  letterSpacing: '-0.704px',
  paddingTop: '14px',
  paddingBottom: '17px',
}));

export const StyledTextProgress = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    color: theme.palette.secondary.main,
    fontFamily: INTER,
    fontSize: '64px',
    fontWeight: 700,
    lineHeight: '96px',
    letterSpacing: '-1.408px',
    paddingBottom: '19px',
  })
);

export const StyledTextFooter = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    color: theme.palette.secondary.light,
    fontFamily: POPPINS,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '30.8px',
    textTransform: 'lowercase',
  })
);
