import { StyledWrapperBox, StyledText } from './ProgressImporting.styled';
import { ProgressCircle } from 'ui';

interface IProgressImporting {
  progress: number;
}

const ProgressImporting = (props: IProgressImporting) => {
  const { progress } = props;

  return (
    <StyledWrapperBox>
      <ProgressCircle value={progress} size="100px" isCaption />
      <StyledText>Importing...</StyledText>
    </StyledWrapperBox>
  );
};

export default ProgressImporting;
