export const datasetChartBarsMainView = [
  {
    value: 5,
    label: 'Jan',
  },
  {
    value: 3,
    label: 'Fev',
  },
  {
    value: 4,
    label: 'Mar',
  },
  {
    value: 19,
    label: 'Apr',
  },
  {
    value: 21,
    label: 'May',
  },
  {
    value: 9,
    label: 'June',
  },
  {
    value: 79,
    label: 'July',
  },
  {
    value: 7,
    label: 'Aug',
  },
  {
    value: 12,
    label: 'Sept',
  },
  {
    value: 2,
    label: 'Oct',
  },
  {
    value: 10,
    label: 'Nov',
  },
  {
    value: 5,
    label: 'Dec',
  },
  {
    value: 4,
    label: 'Mar',
  },
  {
    value: 19,
    label: 'Apr',
  },
  {
    value: 22,
    label: 'May',
  },
  {
    value: 9,
    label: 'June',
  },
  {
    value: 7,
    label: 'July',
  },
  {
    value: 9,
    label: 'Aug',
  },
  {
    value: 19,
    label: 'Sept',
  },
  {
    value: 20,
    label: 'Oct',
  },
  {
    value: 10,
    label: 'Nov',
  },
  {
    value: 9,
    label: 'Dec',
  },
  {
    value: 19,
    label: 'Sept',
  },
  {
    value: 20,
    label: 'Oct',
  },
  {
    value: 10,
    label: 'Nov',
  },
  {
    value: 5,
    label: 'Dec',
  },
  {
    value: 10,
    label: 'Nov',
  },
  {
    value: 9,
    label: 'Dec',
  },
  {
    value: 19,
    label: 'Sept',
  },
  {
    value: 20,
    label: 'Oct',
  },
  {
    value: 10,
    label: 'Nov',
  },
  {
    value: 5,
    label: 'Dec',
  },
];

export const tableDataMainView = [
  {
    FirstName: 'TeroBlaze S1',
    LastName: 'PC',
    Email: '185.53.178.9',
    Status: '112.1MB',
    Group: 'Healthy',
    Device: '01/01/2023 11:12:00GMT',
  },
  {
    FirstName: 'TeroBlaze S1',
    LastName: 'PC',
    Email: '185.53.178.9',
    Status: '112.1MB',
    Group: 'Healthy',
    Device: '01/01/2023 11:12:00GMT',
  },
  {
    FirstName: 'TeroBlaze S1',
    LastName: 'PC',
    Email: '185.53.178.9',
    Status: '112.1MB',
    Group: 'Healthy',
    Device: '01/01/2023 11:12:00GMT',
  },
  {
    FirstName: 'TeroBlaze S1',
    LastName: 'PC',
    Email: '185.53.178.9',
    Status: '112.1MB',
    Group: 'Healthy',
    Device: '01/01/2023 11:12:00GMT',
  },
  {
    FirstName: 'TeroBlaze S1',
    LastName: 'PC',
    Email: '185.53.178.9',
    Status: '112.1MB',
    Group: 'Healthy',
    Device: '01/01/2023 11:12:00GMT',
  },
  {
    FirstName: 'TeroBlaze S1',
    LastName: 'PC',
    Email: '185.53.178.9',
    Status: '112.1MB',
    Group: 'Healthy',
    Device: '01/01/2023 11:12:00GMT',
  },
];
