import {
  StyledWrapperIcon,
  StyledIconWarning,
  StyledIconWindow,
} from './IconBlocked.styled';

const IconBlocked = () => {
  return (
    <StyledWrapperIcon>
      <StyledIconWindow />
      <StyledIconWarning />
    </StyledWrapperIcon>
  );
};

export default IconBlocked;
