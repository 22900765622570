import { styled } from '@mui/material/styles';
import Paper, { PaperProps } from '@mui/material/Paper';
import Box, { BoxProps } from '@mui/material/Box';

interface ISectionProps extends PaperProps {
  height?: string;
  type?: 'graph' | 'table' | 'form';
  paddingleft?: string;
  paddinglefttitle?: string;
  background?: string;
}

interface IBoxProps extends BoxProps {
  paddinglefttitle?: string;
}

export const SectionWrapper = styled((props: ISectionProps) => (
  <Paper {...props} component="section" />
))(({ theme, height, type, paddingleft, background }) => ({
  height: height,
  background: background ? background : 'transparent',
  paddingTop: type === 'table' ? 0 : '20px',
  paddingBottom: type === 'table' ? 0 : '20px',
  paddingLeft: paddingleft
    ? paddingleft
    : type === 'graph'
      ? '20px'
      : type === 'form'
        ? '36px'
        : type === 'table'
          ? 0
          : '40px',
  paddingRight: type === 'form' ? '54px' : type === 'table' ? 0 : '40px',
  border: `1px solid ${theme.palette.silver.dark}`,
  boxShadow: 'none',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  gap: '20px',
}));

export const SectionsTitle = styled(Box)<IBoxProps>(({ paddinglefttitle }) => ({
  position: 'relative',
  paddingLeft: paddinglefttitle ? paddinglefttitle : 0,
}));

export const ActionBlock = styled(Box)<BoxProps>(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  width: 'fit-content',
  height: 'fit-content',
}));
