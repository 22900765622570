import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import { ISTOK_WEB } from 'constants/fonts';

export const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
  width: 'fit-content',
  padding: '7px 34px 6px 35px',
  color: theme.palette.primary.dark,
  fontFamily: ISTOK_WEB,
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '-0.352px',
  borderRadius: '17px',
  border: '1px solid #B5B5B5',
  background: theme.palette.action.disabled,
}));
