import { IconLamp } from 'ui/icons';
import {
  StyledWrapperBox,
  StyledTitle,
  StyledTextProgress,
  StyledTextFooter,
} from './CreatingNewServices.styled';

interface INewServicesProps {
  progress: number;
  protocol: string;
}

const CreatingNewServices = ({ progress, protocol }: INewServicesProps) => {
  return (
    <StyledWrapperBox>
      <IconLamp />
      <StyledTitle>Creating...</StyledTitle>
      <StyledTextProgress>{progress}%</StyledTextProgress>
      <StyledTextFooter>{`{checking protocol : ${protocol}}`}</StyledTextFooter>
    </StyledWrapperBox>
  );
};

export default CreatingNewServices;
