import { StyledLink } from './KyladaLink.styled';

const KyladaLink = () => {
  return (
    <StyledLink
      href="https://www.kylada.com/"
      target="_blank"
      rel="noopener"
      underline="none"
    >
      www.kylada.com
    </StyledLink>
  );
};

export default KyladaLink;
