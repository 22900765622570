import { Box, BoxProps, styled } from '@mui/material';

interface IBoxProps extends BoxProps {
  size?: 'large';
}

export const StyledWrapperIcon = styled(Box)<IBoxProps>(({ size }) => ({
  width: size === 'large' ? '40px' : '20px',
  height: size === 'large' ? '40px' : '20px',
  borderRadius: '50%',
  border:
    size === 'large' ? '2px solid currentColor' : '1px solid currentColor',
}));
