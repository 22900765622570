import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from 'reduxStore/store';
import './index.css';
import { injectStore } from './services/api';
import { Suspense } from 'react';
import { ThemeProvider } from '@mui/material';
import { theme } from 'theme';
import AppRoutes from './routes/AppRoutes';
injectStore(store);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<div>...Loading</div>}>
          <AppRoutes />
        </Suspense>
      </ThemeProvider>
    </Provider>
  </StrictMode>
);
