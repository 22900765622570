import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ROUTES } from './routes.const';
import {
  SignOn,
  ImportConfirmation,
  OrgProfile,
  Billing,
  Import,
  Config,
  Clouds,
  OrganizationMainView,
  OrganizationClientsView,
  OrganizationCostsView,
  DashboardConnectedUsersView,
  DashboardConnectedDevicesView,
  UsersMainView,
  UsersConnectedDevicesView,
  UsersBandwidthConsumedView,
  EditUserView,
  AddNewUserView,
} from 'views';
import {
  AuthLayoutPage,
  DashboardPage,
  OrganizationPage,
  MainLayoutPage,
  CreateProfileLayoutPage,
  UsersPage,
  SecurityGroupPage,
  SecurityEndpointsPage,
} from 'pages';
import { RedirectPage, AuthHandler } from 'components';
import ProtectedProfileRoute from './ProtectedProfileRoute/ProtectedProfileRoute';
import ProtectedRoute from './ProtectedRoute/ProtectedRoute';
import PublicRoute from './PublicRoute/PublicRoute';
import App from 'App';
import TestPage from 'components/TestPage';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <div>Not Found Page</div>,
    children: [
      {
        path: ROUTES.REDIRECT,
        element: <RedirectPage />,
      },
      {
        path: ROUTES.PROCESS_AUTH,
        element: <AuthHandler />,
      },
      {
        element: (
          <PublicRoute>
            <AuthLayoutPage />
          </PublicRoute>
        ),
        children: [{ path: ROUTES.SIGNIN, element: <SignOn /> }],
      },
      // --- create organization routes ---
      {
        element: (
          <ProtectedProfileRoute>
            <CreateProfileLayoutPage />
          </ProtectedProfileRoute>
        ),
        children: [
          { path: ROUTES.ORGANIZATION_PROFILE, element: <OrgProfile /> },
          { path: ROUTES.BILLING, element: <Billing /> },
          {
            path: ROUTES.IMPORT,
            children: [
              { index: true, element: <Import /> },
              {
                path: ROUTES.IMPORT_CONFIRMATION,
                element: <ImportConfirmation />,
              },
            ],
          },
          { path: ROUTES.CONFIGURE_FIRST_ENDPOINT, element: <Config /> },
          { path: ROUTES.SELECT_CLOUD, element: <Clouds /> },
        ],
      },
      // --- dashboard routes ---
      {
        element: (
          <ProtectedRoute>
            <MainLayoutPage />
          </ProtectedRoute>
        ),
        children: [
          // --- DASHBOARD ---
          {
            path: ROUTES.DASHBOARD,
            element: <DashboardPage />,
            children: [
              { index: true, element: <DashboardConnectedUsersView /> },
              {
                path: ROUTES.CONNECTED_DEVICES,
                element: <DashboardConnectedDevicesView />,
              },
              {
                path: ROUTES.MALWARE_BLOCKED,
                element: <div>MALWARE_BLOCKED</div>,
              },
              { path: ROUTES.ADS_BLOCKED, element: <div>ADS_BLOCKED</div> },
              {
                path: ROUTES.CLOUDS_CENTER,
                element: <div>CLOUDS_CENTER</div>,
              },
            ],
          },
          // --- USERS ---
          {
            path: ROUTES.USERS,
            children: [
              {
                element: <UsersPage />,
                children: [
                  { index: true, element: <UsersMainView /> },
                  {
                    path: ROUTES.USERS_CONNECTED_DEVICES,
                    element: <UsersConnectedDevicesView />,
                  },
                  {
                    path: ROUTES.USERS_BANDWIDTH_CONSUMED,
                    element: <UsersBandwidthConsumedView />,
                  },
                  {
                    path: ROUTES.USERS_THREATS_BLOCKED,
                    element: <div>ads_blocked</div>,
                  },
                  {
                    path: ROUTES.USERS_QUARANTINE,
                    element: <div>clouds_center</div>,
                  },
                ],
              },
              {
                element: <Outlet />,
                children: [
                  {
                    path: `${ROUTES.USERS_EDIT_USER}/:userID`,
                    element: <EditUserView />,
                  },
                  {
                    path: ROUTES.USERS_ADD_USER,
                    element: <AddNewUserView />,
                  },
                ],
              },
            ],
          },
          // --- SECURITY_GROUP ---
          {
            path: ROUTES.SECURITY_GROUP,
            element: <SecurityGroupPage />,
          },
          // --- SECURITY_ENDPOINTS ---
          {
            path: ROUTES.SECURITY_ENDPOINTS,
            element: <SecurityEndpointsPage />,
          },
          // --- SERVICES ---
          {
            path: ROUTES.SERVICES,
            element: <div>services</div>,
          },

          // --- NETWORK ---
          {
            path: ROUTES.NETWORK,
            element: <TestPage />,
          },
          // --- ORGANIZATION ---
          {
            path: ROUTES.ORGANIZATION,
            element: <OrganizationPage />,
            children: [
              {
                index: true,
                element: <OrganizationMainView />,
              },
              {
                path: ROUTES.ORGANIZATION_CLIENTS,
                element: <OrganizationClientsView />,
              },
              {
                path: ROUTES.ORGANIZATION_COSTS,
                element: <OrganizationCostsView />,
              },
              {
                path: ROUTES.ORGANIZATION_REVENUE,
                element: <OrganizationMainView />, // todo: add OrganizationRevenueView
              },
              // {
              //   path: ROUTES.ORGANIZATION_CONNECTED_DEVICES,
              //   element: <TestPage />,
              // },
              // {
              //   path: ROUTES.ORGANIZATION_BANDWIDTH_CONSUMED,
              //   element: <div>bandwidth_consumed</div>,
              // },
              // {
              //   path: ROUTES.ORGANIZATION_THREATS_BLOCKED,
              //   element: <div>threats_blocked</div>,
              // },
              // {
              //   path: ROUTES.ORGANIZATION_QUARANTINE,
              //   element: <div>quarantine</div>,
              // },
            ],
          },
          // --- THREAT_MANAGER ---
          {
            path: ROUTES.THREAT_MANAGER,
            element: <div>threat_manage</div>,
          },
        ],
      },
    ],
  },
]);

const AppRoutes = () => {
  return <RouterProvider router={router} />;
};

export default AppRoutes;
