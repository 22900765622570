import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ISTOK_WEB } from 'constants/fonts';

interface ICircleProps extends BoxProps {
  beforecolor?: string;
  background?: string;
}

export const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: '100%',
  height: '351px',
  maxWidth: '1366px',
  overflowX: 'auto',
  overflowY: 'hidden',
  marginBottom: '4px',
  marginLeft: '25px',
  cursor: 'pointer',
  '&::-webkit-scrollbar': {
    width: '100%',
    height: '9px',
    borderRadius: '17px',
    background: theme.palette.silver.dark,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '17px',
    height: '9px',
    width: '258px',
  },
}));

export const StyledWrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '18px',
  marginLeft: '70%',
  transform: 'translateX(-50%)',
}));

export const StyledLegendBox = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StyledCircle = styled(Box)<ICircleProps>(
  ({ beforecolor, background }) => ({
    width: '9px',
    height: '9px',
    borderRadius: '50%',
    position: 'relative',
    background: background,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '::before': {
      content: '""',
      position: 'absolute',
      width: '22px',
      height: '1px',
      background: beforecolor || 'transparent',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  })
);

export const StyledTypography = styled(Typography)(() => ({
  color: '#939393',
  fontFamily: ISTOK_WEB,
  fontSize: '10px',
  fontWeight: 400,
  lineHeight: '15px',
  letterSpacing: '-0.22px',
}));
