import { createTheme } from '@mui/material/styles';
import { COLORS } from './colors.const';
import { ISTOK_WEB, INTER, POPPINS } from '../constants/fonts';

export const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.main,
      light: COLORS.light,
      dark: COLORS.black,
      contrastText: COLORS.pantone,
    },
    secondary: {
      main: COLORS.secondary,
      light: COLORS.secondaryGray,
      dark: COLORS.secondaryBlack,
      contrastText: COLORS.contrastGray,
    },
    common: {
      white: COLORS.white,
    },
    gray: {
      main: COLORS.gray,
      light: COLORS.grafit,
      dark: COLORS.grayText,
      contrastText: COLORS.grayBackgroundDark,
    },
    silver: {
      main: COLORS.yellowSilver,
      light: COLORS.silver,
      dark: COLORS.superSilver,
      contrastText: COLORS.grayBorder,
    },
    info: {
      main: COLORS.paradise,
      light: COLORS.lightBlue,
      dark: COLORS.purple,
      contrastText: COLORS.grayDarkBorder,
    },
    blue: {
      main: COLORS.skyBlue,
      light: COLORS.blue,
      contrastText: COLORS.superLightBlue,
      dark: COLORS.lightSkyBlue,
    },
    warning: {
      main: COLORS.orangeMain,
      light: COLORS.yellowLight,
      dark: COLORS.yellowDark,
      contrastText: COLORS.orange,
    },
    success: {
      main: COLORS.greenMain,
      light: COLORS.greenLight,
      dark: COLORS.greenDark,
      contrastText: COLORS.teal,
    },
    black: {
      main: COLORS.grayText,
      light: COLORS.grafit,
      dark: COLORS.brown,
      contrastText: COLORS.darkGray,
    },
    error: {
      main: COLORS.redLight,
      light: COLORS.redMain,
      dark: COLORS.redDark,
      contrastText: COLORS.error,
    },
    red: {
      main: COLORS.redBackground,
      dark: COLORS.tileRed,
      light: COLORS.darkOrange,
    },
    background: {
      paper: COLORS.white,
      default: COLORS.lightBlue,
    },
    text: {
      primary: COLORS.secondary,
      secondary: COLORS.secondaryGray,
      disabled: COLORS.disabled,
    },
    extra: {
      main: COLORS.superBlue,
      light: COLORS.superLightGreen,
      dark: COLORS.grayBlue,
      contrastText: COLORS.superLilac,
    },
    shades: {
      main: COLORS.silverLight,
      light: COLORS.superYellow,
      dark: COLORS.darkBlack,
    },
    action: {
      active: COLORS.main,
      hover: COLORS.hover,
      focus: COLORS.main,
      disabled: COLORS.disableBackground,
    },
    divider: COLORS.divider,
  },
  typography: {
    fontFamily: ISTOK_WEB,
  },
  breakpoints: {
    values: {
      xs: 420,
      xl: 1728,
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          color: COLORS.main,
          fontSize: '18px',
          fontWeight: '700',
          lineHeight: '20px',
        },
        h2: {
          color: COLORS.main,
          fontFamily: INTER,
          fontSize: '16px',
          fontWeight: '500',
          lineHeight: '24px',
          letterSpacing: '-0.352px',
        },
        h5: {
          color: COLORS.secondary,
          fontFamily: INTER,
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: '18px',
          letterSpacing: '-0.264px',
          textTransform: 'uppercase',
        },
        subtitle1: {
          color: COLORS.secondary,
          fontFamily: INTER,
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '24px',
          letterSpacing: '-0.352px',
        },
        subtitle2: {
          color: COLORS.black,
          fontFamily: INTER,
          fontSize: '14px',
          fontWeight: 600,
          lineHeight: '21px',
          letterSpacing: '-0.308px',
          textTransform: 'uppercase',
        },
        body1: {
          color: COLORS.secondary,
          fontFamily: INTER,
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '21px',
          letterSpacing: '-0.308px',
        },
        body2: {
          color: COLORS.secondaryGray,
          textAlign: 'center',
          fontFamily: ISTOK_WEB,
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '24px',
          letterSpacing: '-0.352px',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTabs: {
      styleOverrides: {
        scroller: {
          overflow: 'visible !important',
          marginBottom: '0px',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: '0px',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: '10px',
          padding: '4px 10px',
          borderRadius: '22px',
          background: '#C5EAFF',
          minWidth: '59px',
          color: COLORS.main,
          fontFamily: POPPINS,
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: '23.1px',
          textAlign: 'center',
        },
        arrow: {
          color: '#C5EAFF',
        },
      },
    },
  },
});

export default theme;
