import { FlatButton, CloudList } from 'ui';
import {
  WrapperBox,
  Title,
  WrapperColorBox,
  ColorBoxTitle,
  ColorBox,
  DividerMain,
} from './ConfirmCloudsAndRegion.styled';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ROUTES } from 'routes/routes.const';
import {
  closeModal,
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';
import { CHILD } from 'constants/organizationsType';
import {
  getOrganizationId,
  getOrganizationLevel,
  getOrganizationValues,
  getClouds,
} from 'reduxStore/createProfile/createProfileSelectors';
import {
  ICloudFromServerReformatedType,
  IRegionFromServerReformatedType,
} from 'types/organizations.type';
import { ICloudSetUpType, ISetUpSelectedCloudsBody } from 'types/api.type';
import { useState } from 'react';
import { postSetUpClouds } from 'services/api/createProfile';
import { deepClone } from 'utils';
import { Box, Typography } from '@mui/material';
import { COLORS } from 'theme/colors.const';
import { INTER } from 'constants/fonts';
const ConfirmCloudsAndRegion = () => {
  const [errorSetRegions, setErrorSetRegions] = useState('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const organizationLevel = useAppSelector(getOrganizationLevel);
  const orgName = useAppSelector(getOrganizationValues)?.name;
  const orgId = useAppSelector(getOrganizationId);
  const initialClouds = useAppSelector(getClouds);

  const initializeClouds = (clouds: ICloudFromServerReformatedType[]) => {
    const checkedClouds = clouds.map(
      (cloud: ICloudFromServerReformatedType) => {
        const regions = cloud.regions.filter(
          (region: IRegionFromServerReformatedType) => region.checked === true
        );
        return {
          ...cloud,
          regions: regions,
        };
      }
    );
    return checkedClouds;
  };

  const [clouds] = useState(() => initializeClouds(initialClouds));

  const retrieveRegionsFromCloud = (cloudProvider: string) => {
    const neededCloud = clouds.find(
      (cloud: ICloudFromServerReformatedType) => cloud.name === cloudProvider
    );
    if (!neededCloud) return [];
    return deepClone(neededCloud.regions);
  };

  const cloudServices = clouds.map((cloud: ICloudFromServerReformatedType) => ({
    name: cloud.name,
    status: cloud.is_coming_soon ? '(Coming Soon)' : '',
    elementsMap: retrieveRegionsFromCloud(cloud.name),
  }));
  const handleClick = async () => {
    const cloudsWithSelectedRegions = initializeClouds(initialClouds);
    const cloudsSetUp: ICloudSetUpType[] = cloudsWithSelectedRegions.map(
      (cloud: ICloudFromServerReformatedType) => {
        const regionsIdsToPut = cloud.regions.map(
          (region: IRegionFromServerReformatedType) => {
            return region.defaultRegion.id;
          }
        );
        return {
          id: cloud.id,
          region_ids: regionsIdsToPut,
        };
      }
    );
    const setUpCloudsForOrg: ISetUpSelectedCloudsBody = {
      org_id: orgId,
      clouds: cloudsSetUp,
    };
    try {
      await postSetUpClouds(setUpCloudsForOrg);
      dispatch(closeModal());
      const savedState = localStorage.getItem('DoNotShowModal');
      if (organizationLevel === CHILD) {
        dispatch(setModalProps({ radius: '18px', title: orgName }));
        navigate(ROUTES.ORGANIZATION);
        dispatch(openModal());
        dispatch(setContent('AddClientConfirm'));
      } else {
        navigate(ROUTES.DASHBOARD, {
          state: { from: 'createMainOrganization' },
        });
        if (!savedState || JSON.parse(savedState) === false) {
          dispatch(openModal());
          dispatch(setContent('WelcomeToKylada'));
          dispatch(setModalProps({ radius: '18px' }));
        }
      }
    } catch (error: any) {
      setErrorSetRegions(error.response.data.message);
      console.error(error.message);
    }
  };

  return (
    <>
      {!!errorSetRegions && (
        <Box
          marginTop="10px"
          display="flex"
          justifyContent="center"
          width="629px"
        >
          <Typography
            sx={{
              paddingBottom: '10px',
              color: COLORS.authError,
              fontFamily: INTER,
              fontSize: '16px',
              fontWeight: 800,
              lineHeight: '150%',
              letterSpacing: '-0.352px',
            }}
          >
            {errorSetRegions}
          </Typography>
        </Box>
      )}
      <WrapperBox>
        <Title>Confirm Clouds & Regions</Title>
        <FlatButton
          variant="contained"
          width="307px"
          height="50px"
          onClick={handleClick}
        >
          Confirm
        </FlatButton>
        <DividerMain />
        <WrapperColorBox>
          {cloudServices.map((service, index) => (
            <ColorBox key={index} status={service.status}>
              <ColorBoxTitle>
                {service.name}&nbsp;{service.status}
              </ColorBoxTitle>
              <CloudList
                regionsList={service.elementsMap}
                status={service.status}
                cloudProvider={service.name}
              />
            </ColorBox>
          ))}
        </WrapperColorBox>
      </WrapperBox>
    </>
  );
};

export default ConfirmCloudsAndRegion;
