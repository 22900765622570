import { useState, useEffect, ChangeEvent } from 'react';
import {
  WrapperCheckBoxGroup,
  DividerLine,
  Title,
  WrapperNoOptions,
  TitleNoOptions,
} from './CheckBoxGroup.styled';
import { Box } from '@mui/material';
import CheckBox from '../../../ui/CheckBox/CheckBox';
import {
  addOption,
  removeOption,
  selectAllOptions,
  deselectAllOptions,
} from 'reduxStore/createProfile/createProfileSlice';
import { useDispatch } from 'react-redux';
import { IRegionFromServerReformatedType } from 'types/organizations.type';

interface ICheckBoxGroup {
  title?: string;
  regions: IRegionFromServerReformatedType[];
  checkboxSize?: 'small' | 'large';
  textTransform?: 'lowercase' | 'capitalize' | 'uppercase' | 'none';
  fontWeight?: '400' | '500' | '600';
  cloudProvider: string;
}

const CheckBoxGroup: React.FC<ICheckBoxGroup> = ({
  regions,
  checkboxSize,
  title,
  fontWeight,
  textTransform,
  cloudProvider,
}) => {
  const dispatch = useDispatch();
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    const allChecked = regions.every(region => region.checked);
    setSelectAll(allChecked);
  }, [regions]);

  const handleSelectAllChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      dispatch(selectAllOptions({ cloudProvider }));
    } else {
      dispatch(deselectAllOptions({ cloudProvider }));
    }
    setSelectAll(event.target.checked);
  };

  const handleOptionChange =
    (regionId: number) => (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        dispatch(addOption({ cloudProvider, regionId }));
      } else {
        dispatch(removeOption({ cloudProvider, regionId }));
      }
    };
  if (regions.length === 0)
    return (
      <WrapperNoOptions>
        <TitleNoOptions>No options available</TitleNoOptions>
      </WrapperNoOptions>
    );
  return (
    <WrapperCheckBoxGroup>
      {title && <Title>{title}</Title>}
      <CheckBox
        label="Select All"
        fontWeight={fontWeight}
        onChange={handleSelectAllChange}
        checked={selectAll}
        checkboxSize={checkboxSize}
        textTransform={textTransform}
        checkboxColor="dark"
      />
      <DividerLine />
      {regions.map((region, index) => (
        <Box key={`${index}_${region.defaultRegion.id}`}>
          <CheckBox
            keyForControl={index}
            label={`${region.defaultRegion.name} (${region.defaultRegion.locale})
              ${region.defaultRegion.is_coming_soon ? ' - Coming Soon' : ''}`}
            fontWeight={fontWeight}
            onChange={handleOptionChange(region.defaultRegion.id)}
            checked={region.checked}
            checkboxSize={checkboxSize}
            checkboxColor="dark"
          ></CheckBox>
          <DividerLine />
        </Box>
      ))}
    </WrapperCheckBoxGroup>
  );
};

export default CheckBoxGroup;
