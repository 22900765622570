import { useState } from 'react';
import {
  TabsContainer,
  TabLink,
  WrapperContent,
} from './OrganizationTabsMenu.styled';
import { ITabMenuProps } from 'types';
import { Tab } from '@mui/material';
import { theme } from 'theme';
import { useLocation } from 'react-router';
import { initiateTabValueForNavTabs } from 'utils';

interface IMenuOrganization {
  options: ITabMenuProps[];
}

const OrganizationTabsMenu = ({ options }: IMenuOrganization) => {
  const { pathname } = useLocation();

  const initiateValue = () => initiateTabValueForNavTabs(options, pathname);

  const [value, setValue] = useState(initiateValue);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const tabProps = {
    sx: {
      '&.Mui-selected': {
        background: theme.palette.primary.main,
        color: theme.palette.background.paper,
        borderColor: 'none',
      },
    },
  };

  return (
    <TabsContainer value={value} onChange={handleChange}>
      {options.map(option => (
        <Tab
          key={`${option.title}_${option.url}`}
          component={TabLink}
          to={option.url}
          {...tabProps}
          label={
            <WrapperContent>
              {option.icon}
              {option.title}
            </WrapperContent>
          }
        />
      ))}
    </TabsContainer>
  );
};

export default OrganizationTabsMenu;
