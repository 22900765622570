import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { POPPINS } from 'constants/fonts';

export const WrapperWelcome = styled(Box)<BoxProps>(({ theme }) => ({
  height: '388px',
  width: '629px',
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.common.white,
  borderRadius: '18px',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '73px 0px 28px',
  maxHeight: '90vh',
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontFamily: POPPINS,
  fontSize: '32px',
  fontWeight: 500,
  lineHeight: '48px',
  letterSpacing: '-0.704px',
}));

export const MainText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontFamily: POPPINS,
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '150%',
  letterSpacing: '-0.352px',
}));

export const ListTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontFamily: POPPINS,
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '150%',
  letterSpacing: '-0.352px',
  textDecorationLine: 'underline',
  padding: '14px 0px 0px',
  textAlign: 'center',
}));

export const ListoOfSteps = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontFamily: POPPINS,
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '150%',
  letterSpacing: '-0.352px',
}));
