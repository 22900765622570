const getFormatCellValue = (value: string, format: string | undefined) => {
  switch (format) {
    case 'uppercase':
      return value.toUpperCase();
    case 'lowercase':
      return value.toLowerCase();
    case 'capitalize':
      return value.charAt(0).toUpperCase() + value.slice(1);
    case '$':
      return '$' + value;
    default:
      return value;
  }
};

export default getFormatCellValue;
