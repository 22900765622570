import { ActionBlock, SectionWrapper, SectionsTitle } from './Section.styled';
import { Typography } from '@mui/material';

interface ISectionProps {
  children: React.ReactNode | JSX.Element;
  title?: string;
  height?: string;
  type?: 'graph' | 'table' | 'form';
  paddingLeft?: string;
  paddingLeftTitle?: string;
  action?: React.ReactNode | JSX.Element;
  background?: string;
}

const Section = ({
  children,
  title,
  height,
  type,
  paddingLeft,
  paddingLeftTitle,
  action,
  background,
}: ISectionProps) => {
  return (
    <SectionWrapper
      height={height}
      type={type}
      paddingleft={paddingLeft}
      background={background}
    >
      {(title || action) && (
        <SectionsTitle paddinglefttitle={paddingLeftTitle}>
          <Typography variant="h2">{title}</Typography>
          <ActionBlock>{action}</ActionBlock>
        </SectionsTitle>
      )}

      {children}
    </SectionWrapper>
  );
};

export default Section;
