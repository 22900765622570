import {
  StyledWrapperBox,
  StyledTitle,
  StyledWrapperTitle,
  StyledWrapperActions,
  StyledWrapperTextFields,
} from './Vulnerability.styled';
import { FlatButton, CheckBox } from 'ui';
import { IconBug } from 'ui/icons';
import { Input } from 'ui/inputs';

interface IVulnerabilityProps {
  onClick: () => void;
}

const Vulnerability = ({ onClick }: IVulnerabilityProps) => {
  const handleCloseModal = () => {
    onClick && onClick();
  };

  return (
    <StyledWrapperBox>
      <StyledWrapperTitle>
        <IconBug width="46px" />
        <StyledTitle>Vulnerability Details</StyledTitle>
      </StyledWrapperTitle>
      <StyledWrapperTextFields>
        <Input label="Vulnerability Name" />
        <Input label="Description" height="123px" />
      </StyledWrapperTextFields>
      <CheckBox label="Same address as Company" textTransform="lowercase" />
      <StyledWrapperActions>
        <FlatButton variant="outlined" onClick={handleCloseModal}>
          Cancel
        </FlatButton>
        <FlatButton variant="contained">Save</FlatButton>
      </StyledWrapperActions>
    </StyledWrapperBox>
  );
};

export default Vulnerability;
