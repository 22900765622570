import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { POPPINS } from 'constants/fonts';
import Divider from '@mui/material/Divider';
import { COLORS } from 'theme/colors.const';

interface IColorBoxProps extends BoxProps {
  status?: string;
}

export const WrapperBox = styled(Box)<BoxProps>(({ theme }) => ({
  height: 'fit-content',
  display: 'flex',
  width: '629px',
  flexDirection: 'column',
  gap: '18px',
  background: theme.palette.common.white,
  borderRadius: '18px',
  alignItems: 'center',
  maxHeight: '90vh',
  '& .MuiPaper-root': {
    border: 'none',
    boxShadow: 'none',
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontFamily: POPPINS,
  fontSize: '32px',
  fontWeight: 500,
  lineHeight: '48px',
  letterSpacing: '-0.704px',
  padding: '10px 112px 0px 111px',
}));

export const WrapperColorBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: '629px',
  background: theme.palette.common.white,
  height: '616px',
  maxHeight: '73vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '21px',
  overflow: 'auto',
  padding: '21px 47px 21px 47px',
  '&::-webkit-scrollbar': {
    width: '10px',
    background: theme.palette.blue.light,
    borderBottomRightRadius: '18px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#85A7BA',
  },
}));

export const ColorBox = styled(Box)<IColorBoxProps>(({ theme, status }) => ({
  width: '523px',
  display: 'flex',
  flexDirection: 'column',
  background:
    status === '(Coming Soon)'
      ? COLORS.contrastGray
      : theme.palette.blue.contrastText,
  borderRadius: '24px',
  justifyContent: 'start',
  padding: '29px 0px 0px 24px',
}));

export const ColorBoxTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontFamily: POPPINS,
  fontSize: '24px',
  fontWeight: 500,
  lineHeight: '36px',
  letterSpacing: '-0.528px',
}));

export const DividerMain = styled(Divider)(({ theme }) => ({
  width: '107%',
  height: '1px',
  borderColor: theme.palette.extra.dark,
  marginLeft: '-47px',
  marginBottom: '-18px',
}));
