import { ButtonLogout, Logo, NavigationUser } from 'ui';
import { Container, WrapperSidebar, NavContainer } from './SideBar.styled';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getIsAuth } from 'reduxStore/auth/authSelectors';
import { resetAuth } from 'reduxStore/auth/authSlice';
import { resetOrganization } from 'reduxStore/organization/organizationSlice';
import { resetUsers } from 'reduxStore/users/usersSlice';

const SideBar = () => {
  const dispatch = useAppDispatch();
  const isAuth = useAppSelector(getIsAuth);

  const handleLogout = () => {
    if (isAuth) {
      dispatch(resetAuth());
      dispatch(resetOrganization());
      dispatch(resetUsers());
    }
  };

  return (
    <Container>
      <WrapperSidebar>
        <NavContainer>
          <Logo />
          <NavigationUser />
        </NavContainer>
        <ButtonLogout onClick={handleLogout} />
      </WrapperSidebar>
    </Container>
  );
};

export default SideBar;
