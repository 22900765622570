import { CircularProgress } from '@mui/material';
import { Loading } from './LoadingSign.styled';

const LoadingSign = () => {
  return (
    <Loading>
      <CircularProgress />
    </Loading>
  );
};

export default LoadingSign;
