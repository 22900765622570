import { Box, BoxProps, styled } from '@mui/material';

export const LoaderContainer = styled(Box)<BoxProps>(() => ({
  position: 'relative',
  width: '100%',
  height: '100vh',
}));

export const LoaderWrapper = styled(Box)<BoxProps>(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));
