import { styled } from '@mui/material';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Button, { ButtonProps } from '@mui/material/Button';
import { ISTOK_WEB } from 'constants/fonts';

export const StyledButton = styled(Button)<ButtonProps>(() => ({
  display: 'flex',
  padding: '0px',
}));

export const StyledTypography = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    color: theme.palette.primary.main,
    fontFamily: ISTOK_WEB,
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '18px',
    letterSpacing: '-0.264px',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  })
);
