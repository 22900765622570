import { useAppDispatch, useAppSelector } from 'hooks';
import { closeModal } from 'reduxStore/modal/modalSlice';
import {
  getIsOpen,
  getContent,
  getModalProps,
} from 'reduxStore/modal/modalSelectors';
import { StyledDialog, StyledWrapperDialog, StyledLine } from './Modal.styled';
import {
  Confirmation,
  ErrorCardModal,
  ProgressImporting,
  DeleteGroupConfirm,
  Vulnerability,
  ConfirmCloudsAndRegion,
  BackupCard,
  WelcomeToKylada,
  UsersShowAllData,
} from './components';
import { UploadFile } from 'views';
import { IAdminType, ITableColumn, ITableRow } from 'types';
import AddClientConfirm from 'ui/AddClientConfirm/AddClientConfirm';

export interface IModalProps extends IAdminType {
  status?: 'error' | 'success';
  isAdmin?: boolean;
  position?: 'top' | 'right';
  top?: string;
  radius?: string;
  isNextClick?: boolean;
  background?: string;
  zIndex?: number;
  title?: string;
  icon?: 'edit' | 'remove' | 'check' | 'switch' | 'view';
  columns?: ITableColumn[];
  tableData?: ITableRow[];
  isCheckbox?: 'true';
  progress?: number;
}

const Modal = () => {
  const dispatch = useAppDispatch();

  const isOpen = useAppSelector(getIsOpen);
  const content = useAppSelector(getContent);
  const modalProps = useAppSelector(getModalProps);
  const modalContent = () => {
    switch (content) {
      case 'UsersShowAllData':
        return (
          modalProps?.columns &&
          modalProps?.tableData && (
            <UsersShowAllData
              title={modalProps?.title}
              type={modalProps?.type}
              icon={modalProps?.icon}
              isCheckbox={modalProps?.isCheckbox}
              columns={modalProps.columns}
              tableData={modalProps.tableData}
            />
          )
        );
      case 'ErrorCardModal':
        return <ErrorCardModal />;
      case 'Confirmation':
        return <Confirmation />;
      case 'ProgressImporting':
        return <ProgressImporting progress={modalProps?.progress ?? 0} />;
      case 'DeleteUserConfirm':
        return (
          <DeleteGroupConfirm
            status="error"
            titleConfirm="Confirm deletion ?"
            textConfirm="Confirm deletion of selected field?"
            textButton="Delete"
          />
        );
      case 'DeletedSuccess':
        return <DeleteGroupConfirm status="success" textButton="Go back" />;
      case 'Vulnerability':
        return <Vulnerability onClick={handleCloseModal} />;
      case 'ConfirmCloudsAndRegion':
        return <ConfirmCloudsAndRegion />;
      case 'BackupCard':
        return <BackupCard />;
      case 'UploadFile':
        return <UploadFile />;
      case 'WelcomeToKylada':
        return <WelcomeToKylada />;
      case 'AddClientConfirm':
        return <AddClientConfirm nameOfOrg={modalProps?.title} />;
      default:
        return null;
    }
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <>
      {isOpen && (
        <StyledWrapperDialog>
          <StyledDialog
            open={isOpen}
            onClose={handleCloseModal}
            status={modalProps?.status}
            isadmin={modalProps?.isAdmin}
            position={modalProps?.position}
            top={modalProps?.top}
            radius={modalProps?.radius}
            background={modalProps?.background}
            zindex={modalProps?.zIndex}
          >
            <StyledLine status={modalProps?.status} />
            {modalContent()}
          </StyledDialog>
        </StyledWrapperDialog>
      )}
    </>
  );
};

export default Modal;
