import { styled } from '@mui/material';
import { ReactComponent as IconEdit } from '../../../assets/images/edit.svg';

interface IIconEdit {
  version?: 'action';
}

export const StyledIconEdit = styled(IconEdit)<IIconEdit>(
  ({ theme, version }) => ({
    cursor: 'pointer',
    height: version === 'action' ? '20px' : '16px',
    width: version === 'action' ? '20px' : '16px',
    fill:
      version === 'action'
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
  })
);
