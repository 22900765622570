import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { SecuredByStripe, CheckBox, PaymentCard, SectionTitle } from 'ui';
import { ButtonsContainerMSP } from 'views';
import { StyledTextField } from 'ui/inputs/Input/Input.styled';
import { useAppDispatch } from 'hooks';
import {
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';
import { useNavigate } from 'react-router';
import { ROUTES } from 'routes/routes.const';

const Billing = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const initialValues = {
    cardHolderName: '',
    address: '',
    ccNumber: '',
    city: '',
    expiryDate: '',
    cvv: '',
    state: '',
    zipCode: '',
  };

  const handleSubmit = () => {
    dispatch(openModal());
    dispatch(setContent('ErrorCardModal'));
    dispatch(setModalProps({ radius: '44px', position: 'top', top: '84px' }));
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      gap="20px"
    >
      <Box>
        <Box display="flex">
          <SectionTitle>Billing Information</SectionTitle>
        </Box>
        <SecuredByStripe />
        <Box width="100%" marginTop="47px">
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {() => (
              <Form>
                <Grid container flexDirection="row" spacing="4vw">
                  <Grid item xs={6}>
                    <Grid container flexDirection="column" spacing="38px">
                      <Grid item xs={12}>
                        <Field
                          as={StyledTextField}
                          type="secondary"
                          name="cardHolderName"
                          label="Card Holder Name"
                          helperText={<ErrorMessage name="cardHolderName" />}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          as={StyledTextField}
                          type="secondary"
                          name="ccNumber"
                          label="CC Number"
                          helperText={<ErrorMessage name="ccNumber" />}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container flexDirection="row" spacing="3vw">
                          <Grid item xs={6}>
                            <Field
                              as={StyledTextField}
                              type="secondary"
                              name="expiryDate"
                              label="Expiry Date"
                              helperText={<ErrorMessage name="expiryDate" />}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Field
                              as={StyledTextField}
                              type="secondary"
                              name="cvv"
                              label="CVV"
                              helperText={<ErrorMessage name="cvv" />}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={6}>
                    <Grid container flexDirection="column" spacing="38px">
                      <Grid item xl={12}>
                        <Field
                          as={StyledTextField}
                          type="secondary"
                          name="address"
                          label="Address"
                          helperText={<ErrorMessage name="address" />}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          as={StyledTextField}
                          type="secondary"
                          name="city"
                          label="City"
                          helperText={<ErrorMessage name="city" />}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container flexDirection="row" spacing="3vw">
                          <Grid item xs={6}>
                            <Field
                              as={StyledTextField}
                              type="secondary"
                              name="state"
                              label="State"
                              helperText={<ErrorMessage name="state" />}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Field
                              as={StyledTextField}
                              type="secondary"
                              name="zipCode"
                              label="Zip code"
                              helperText={<ErrorMessage name="zipCode" />}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Box marginTop="40px" marginBottom="40px">
                  <CheckBox label="Same address as Company" />
                </Box>
                <Box marginBottom="45px">
                  <PaymentCard />
                </Box>
                <ButtonsContainerMSP
                  titleButton="Next"
                  onClick={handleSubmit}
                  onClickSetUpLater={() => navigate(ROUTES.IMPORT)}
                  setUpLater
                />
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
};

export default Billing;
