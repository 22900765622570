import { StyledWrapperBox, StyledIconGoogle } from './IconGoogle.styled';

const IconGoogle = () => {
  return (
    <StyledWrapperBox>
      <StyledIconGoogle />
    </StyledWrapperBox>
  );
};

export default IconGoogle;
