import { StyledButtonOval } from './ButtonOval.styled';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SaveIcon from '@mui/icons-material/Save';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface IButtonOvalProps {
  variant?: 'contained' | 'outlined' | 'text';
  type?: 'button' | 'submit' | 'reset';
  buttoncolor?: 'blue' | 'black' | 'red' | 'white' | 'bluewhite' | 'blackwhite';
  width?: string;
  height?: string;
  isIcon?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
  children: React.ReactNode | string;
}

const ButtonOval = ({
  variant,
  type,
  buttoncolor,
  isIcon,
  isDisabled,
  width,
  height,
  onClick,
  children,
}: IButtonOvalProps) => {
  return (
    <StyledButtonOval
      variant={variant}
      type={type}
      buttoncolor={buttoncolor}
      isicon={isIcon ? 'true' : 'false'}
      disabled={isDisabled}
      onClick={onClick}
      width={width}
      height={height}
      endIcon={
        isIcon && buttoncolor === 'blue' && variant === 'outlined' ? (
          <SaveIcon fontSize="large" />
        ) : isIcon && buttoncolor === 'blue' && variant === 'contained' ? (
          <AddCircleOutlineIcon fontSize="large" />
        ) : isIcon && buttoncolor === 'black' && variant === 'outlined' ? (
          <MarkEmailReadIcon fontSize="large" />
        ) : isIcon && buttoncolor === 'black' && variant === 'contained' ? (
          <AddCircleOutlineIcon fontSize="large" />
        ) : isIcon && buttoncolor === 'red' && variant === 'outlined' ? (
          <DeleteForeverIcon fontSize="large" />
        ) : isIcon && buttoncolor === 'red' && variant === 'contained' ? (
          <DeleteIcon fontSize="large" />
        ) : isIcon && buttoncolor === 'white' && variant === 'contained' ? (
          <AddCircleIcon fontSize="large" />
        ) : isIcon && buttoncolor === 'white' && variant === 'outlined' ? (
          <ArrowDropDownIcon fontSize="large" />
        ) : isIcon && buttoncolor === 'bluewhite' && variant === 'contained' ? (
          <AddCircleIcon fontSize="large" />
        ) : isIcon &&
          buttoncolor === 'blackwhite' &&
          variant === 'contained' ? (
          <AddCircleIcon fontSize="large" />
        ) : undefined
      }
      startIcon={
        isIcon &&
        buttoncolor === 'blue' &&
        variant === 'text' && <ArrowBackIcon fontSize="large" />
      }
    >
      {children}
    </StyledButtonOval>
  );
};

export default ButtonOval;
