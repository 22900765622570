import { styled } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { ISTOK_WEB } from 'constants/fonts';

export const StyledWrapperTab = styled(Box)<BoxProps>(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  gap: 0,
  padding: 0,
  width: '249px',
  height: '174px',
  borderRadius: '20px',
  border: `1px solid ${theme.palette.silver.dark}`,
  background: theme.palette.common.white,
  boxShadow: '4px 4px 4px 0px rgba(0, 0, 0, 0.05)',
  // overflow: 'hidden',
  overflow: 'visible',
  '&:hover': {
    '& .css-9mvguz': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    '& .css-13mu8rn': {
      color: theme.palette.primary.main,
    },
  },
}));

export const StyledWrapperTitle = styled(Box)<BoxProps>(({ theme }) => ({
  boxSizing: 'border-box',
  width: '100%',
  height: '73px',
  backgroundColor: '#E6F2F9',
  color: theme.palette.primary.main,
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledText = styled(Typography)<TypographyProps>(() => ({
  color: 'inherit',
  fontFamily: ISTOK_WEB,
  fontSize: '18px',
  fontWeight: 700,
  lineHeight: '20px',
  letterSpacing: '-0.396px',
  textTransform: 'capitalize',
}));

export const StyledData = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  color: '#D4D4D4',
  textAlign: 'center',
  fontFamily: ISTOK_WEB,
  fontSize: '45px',
  fontWeight: 700,
  lineHeight: '67.5px',
  letterSpacing: '-0.99px',
  flexGrow: 1,
}));

export const StyledWrapperIcon = styled(Box)<BoxProps>(() => ({
  width: '40px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
