import { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import {
  CONNECTED_USER_HEAD_TABLE,
  DEVICE_LIST_HEAD_TABLE,
} from 'constants/tablesHead';
import { theme } from 'theme';
import {
  Amount,
  ChartBars,
  CompanyIdentity,
  Section,
  SelectAction,
  TableBasis,
} from 'ui';
import { useAppSelector } from 'hooks';
import { getIsOpen } from 'reduxStore/modal/modalSelectors';
import { ADMIN } from 'constants/organizationsType';
import {
  datasetChartBarsMainView,
  tableDataMainView,
} from 'constants/temporaryMocks/organization';
import { AmountWrapper } from './OrganizationMainView.styled';
import { getMainOrganizationID } from 'reduxStore/organization/organizationSelectors';
import useGetAPI from 'hooks/useGetApi';
import { IOrganizationByID } from 'types/organizations.type';

const OrganizationMainView = () => {
  const [isWarning, setIsWarning] = useState(false);

  const isOpenModal = useAppSelector(getIsOpen);
  const mainOrganizationId = useAppSelector(getMainOrganizationID);

  const [mainOrganization] = useGetAPI<IOrganizationByID | null>(
    'organization',
    {
      id: mainOrganizationId,
    }
  );

  useEffect(() => {
    if (!isOpenModal) {
      setIsWarning(false);
    }
  }, [isOpenModal]);

  const handleDeleteUser = () => {
    setIsWarning(true);
  };

  return (
    <>
      <Stack spacing="28px">
        <AmountWrapper>
          <Amount amount="0" text="Period costs"></Amount>
          <Amount amount="0" text="Period revenue"></Amount>
        </AmountWrapper>
        <Box>
          <CompanyIdentity
            avatarUrl={mainOrganization?.logo}
            companyName={mainOrganization?.name}
            subtitle="my msp"
            onEdit={() => {}}
          />
        </Box>
        <Section
          title="Connected Users"
          type="graph"
          action={<SelectAction version="periodOfTime" name="Last Month" />}
          paddingLeftTitle="20px"
          background={theme.palette.background.paper}
        >
          <ChartBars
            dataset={datasetChartBarsMainView}
            textTooltip="users"
            textTooltipAction="connected"
          />
        </Section>
        <TableBasis
          title="User List"
          action={
            <SelectAction
              version="actionUser"
              name="Action"
              onDelete={handleDeleteUser}
            />
          }
          type={ADMIN}
          isCheckbox="true"
          icon="edit"
          isWarning={isWarning}
          columns={CONNECTED_USER_HEAD_TABLE}
          tableData={tableDataMainView}
        />
        <TableBasis
          title="Device List"
          type={ADMIN}
          icon="remove"
          columns={DEVICE_LIST_HEAD_TABLE}
          tableData={tableDataMainView}
        />
      </Stack>
    </>
  );
};

export default OrganizationMainView;
