import { styled } from '@mui/material/styles';
import Link, { LinkProps } from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';
import { Typography } from '@mui/material';

export const WrapperLink = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  marginRight: '0px',
}));

export const LinkSignIn = styled(Link)<LinkProps>(({ theme }) => ({
  color: theme.palette.extra.main,
  fontSize: '22px',
  fontWeight: 500,
  lineHeight: '33px',
  letterSpacing: '-0.484px',
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.info.dark,
  },
}));

export const TextToLink = styled(Typography)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '7px',
  color: theme.palette.shades.dark,
  fontSize: '22px',
  fontWeight: 300,
  lineHeight: '33px',
  letterSpacing: '-0.484px',
}));
