import {
  StyledWrapperText,
  StyledTypographyRed,
  StyledTypographyBlue,
} from './ErrorCardModal.styled';

const ErrorCardModal = () => {
  return (
    <StyledWrapperText>
      <StyledTypographyRed>
        Error 54: this card is already in use,
      </StyledTypographyRed>
      <StyledTypographyBlue>retry in 09 sec...</StyledTypographyBlue>
    </StyledWrapperText>
  );
};

export default ErrorCardModal;
