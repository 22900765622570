import { Box } from '@mui/material';
import { Outlet } from 'react-router';
import { OrganizationMspMenu } from 'views';

const OrganizationPage = () => {
  return (
    <Box
      component="section"
      sx={{ position: 'relative', mb: '37px', mt: '72px' }}
    >
      <OrganizationMspMenu />
      <Outlet />
    </Box>
  );
};

export default OrganizationPage;
