import { StyledTypography, StyledButton } from './ButtonChange.styled';

const ButtonChange = () => {
  return (
    <StyledButton>
      <StyledTypography>Change</StyledTypography>
    </StyledButton>
  );
};

export default ButtonChange;
