import { OrgTypeOption } from 'types/organizations.type';

// types of parent organizations
export const MSP = 'msp';
export const COMPANY = 'company';

// levels of organizations
export const PARENT = 'parent';
export const CHILD = 'child';

export const ADMIN = 'admin';
export const SUPER_ADMIN = 'superAdmin';

export const ORG_TYPE_OPTIONS: OrgTypeOption[] = [MSP, COMPANY];
