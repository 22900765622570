import { styled } from '@mui/material';
import { ReactComponent as IconBug } from '../../../assets/images/bug.svg';

interface IIconBugProps {
  width?: string;
}

export const StyledIconBug = styled(IconBug)<IIconBugProps>(({ width }) => ({
  cursor: 'pointer',
  height: width ? width : '24px',
  width: width ? width : '24px',
  fill: 'currentColor',
}));
