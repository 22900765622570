import { FlatButton } from 'ui';
import { closeModal } from 'reduxStore/modal/modalSlice';
import { useAppDispatch } from 'hooks';
import {
  WrapperWelcome,
  Title,
  MainText,
  ListTitle,
  ListoOfSteps,
} from './WelcomeToKylada.styled';
import { CheckBox } from 'ui';
import { Box } from '@mui/material';

const WelcomeToKylada = () => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(closeModal());
  };
  return (
    <WrapperWelcome>
      <Title>Welcome to Kylada</Title>

      <Box
        paddingBottom="18px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="33px"
      >
        <MainText>Centralized monitoring, management and compliance</MainText>
        <Box>
          <ListTitle>Recommended Steps:</ListTitle>
          <ListoOfSteps>
            1. Add Clients to your in Organization &gt; My Clients <br /> 2.
            Deploy Kylada Agent to your users and clients
          </ListoOfSteps>
        </Box>

        <CheckBox
          label="Don&#39;t show me this again"
          checkboxSize="small"
          fontWeight="500"
          padding="15px"
          localStorageKey="DoNotShowModal"
        ></CheckBox>
      </Box>

      <FlatButton
        variant="contained"
        width="307px"
        height="50px"
        onClick={handleClick}
      >
        Get Started
      </FlatButton>
    </WrapperWelcome>
  );
};

export default WelcomeToKylada;
