import { Outlet } from 'react-router';

const SecurityEndpointsPage = () => {
  return (
    <>
      <div>Security Endpoints Page</div>
      <Outlet />
    </>
  );
};

export default SecurityEndpointsPage;
