import { FlatButton } from 'ui';
import useAppDispatch from 'hooks/useAppDispatch';
import { closeModal } from 'reduxStore/modal/modalSlice';
import { WrapperConfirmation, MainText } from './AddClientConfirm.styled';
import { Box } from '@mui/material';

interface IAddClientConfirmProps {
  nameOfOrg: string | undefined;
}

const AddClientConfirm = ({ nameOfOrg }: IAddClientConfirmProps) => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(closeModal());
  };

  return (
    <WrapperConfirmation>
      <Box width="500px" height="96px">
        <MainText>
          {nameOfOrg}
          <br /> has been added as a client
        </MainText>
      </Box>

      <FlatButton
        onClick={handleClick}
        variant="contained"
        width="134px"
        height="50px"
      >
        OK
      </FlatButton>
    </WrapperConfirmation>
  );
};
export default AddClientConfirm;
