import { StyledButton } from './ButtonUpload.styled';
import { ButtonProps } from '@mui/material';

interface IButtonUploadProps extends ButtonProps {
  onClick?: () => void;
}

const ButtonUpload = ({ onClick }: IButtonUploadProps) => {
  return <StyledButton onClick={onClick}>Upload new</StyledButton>;
};

export default ButtonUpload;
