import { useState, SyntheticEvent, useEffect } from 'react';
import { useLocation } from 'react-router';
import Tab from '@mui/material/Tab';
import { StyledTabs, StyledLink, StyledBox } from './MainNavigationMenu.styled';
import { IAdminType, ITabMenuProps } from 'types';
import { initiateTabValueForNavTabs } from 'utils';

interface INavTabsProps extends IAdminType {
  options: ITabMenuProps[];
}

const MainNavigationMenu = ({ options, type }: INavTabsProps) => {
  const { pathname } = useLocation();

  const initiateValue = () =>
    initiateTabValueForNavTabs(options, pathname, true);

  const [value, setValue] = useState(initiateValue);

  useEffect(() => {
    if (pathname !== '/') {
      const parentPath = pathname.split('/').filter(String)[0];
      const parentIndex = options.findIndex(i => i.url === `/${parentPath}`);
      setValue(parentIndex !== -1 ? parentIndex : 0);
    }
    if (pathname === '/') {
      setValue(0);
    }
    // eslint-disable-next-line
  }, [pathname]);

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <StyledBox>
      <StyledTabs type={type} value={value} onChange={handleChange}>
        {options.map(({ title, url, icon }, index) => (
          <Tab
            key={`navigation-tab-${index}`}
            type={type}
            iconPosition="start"
            icon={icon}
            label={title}
            component={StyledLink}
            to={url}
          />
        ))}
      </StyledTabs>
    </StyledBox>
  );
};

export default MainNavigationMenu;
