import { styled } from '@mui/material/styles';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import InputLabel, { InputLabelProps } from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import Box, { BoxProps } from '@mui/material/Box';
import ListSubheader, { ListSubheaderProps } from '@mui/material/ListSubheader';
import { INTER } from 'constants/fonts';
import { COLORS } from 'theme/colors.const';

interface IBoxProps extends BoxProps {
  isactive?: 'true' | 'false';
  isselected?: 'true';
}

interface ISelectFieldProps {
  hideicon: 'true' | 'false';
}

interface IFormControlProps extends FormControlProps {
  isactive?: 'true' | 'false';
}

export const WrapperSelect = styled(Box)<BoxProps>(() => ({
  borderBottomLeftRadius: '26px',
  borderBottomRightRadius: '26px',
}));

export const SelectContainer = styled(FormControl)<IFormControlProps>(
  ({ theme, isactive }) => ({
    width: '291px',
    borderTopLeftRadius: '43px',
    borderTopRightRadius: '43px',
    borderBottomLeftRadius: isactive === 'true' ? 'none' : '43px',
    borderBottomRightRadius: isactive === 'true' ? 'none' : '43px',
    background: isactive === 'true' ? theme.palette.blue.light : 'transparent',
    transition: 'background 0.4s ease',
    '& .MuiInputLabel-root.MuiInputLabel-shrink': {
      transform: 'translate(16px, -9px) scale(0.9)',
    },
  })
);

export const LabelSelect = styled(InputLabel)<InputLabelProps>(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '21px',
  letterSpacing: '-0.308px',
  transformOrigin: 'top',
  transform: 'translateX(12px) translateY(15px)',
  borderRadius: '17px',
  background: theme.palette.background.paper,
  padding: '0 10px',
  '&.MuiInputLabel-shrink': {
    transform: 'translate(16px, -9px) scale(0.9)',
  },
}));

export const SelectField = styled(Select)<ISelectFieldProps>(
  ({ theme, hideicon }) => ({
    width: '100%',
    height: '54px',
    borderRadius: '43px',
    border: `1px solid ${COLORS.oceanBlue}`,
    boxSizing: 'border-box',
    overflow: 'hidden',
    '& .MuiInputBase-input': {
      display: 'flex',
      flexDirection: 'row',
      color: theme.palette.text.secondary,
      fontSize: '14px',
      fontFamily: INTER,
      fontWeight: 400,
      lineHeight: '21px',
      letterSpacing: '-0.308px',
      background: theme.palette.background.paper,
      '&.MuiOutlinedInput-input': {
        borderBottomLeftRadius: '28px',
        borderBottomRightRadius: '28px',
        border: `1px solid ${COLORS.oceanBlue}`,
      },
    },
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${COLORS.oceanBlue}`,
      },
    },
    '&:hover': {
      border: `1px solid ${COLORS.oceanBlue}`,
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${COLORS.oceanBlue}`,
      },
    },
    '& .MuiSelect-icon': {
      display: hideicon === 'true' ? 'none' : 'block',
    },
  })
);

export const WrapperListItem = styled(Box)<BoxProps>(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: '21px',
  margin: '6px',
  paddingTop: '8px',
  paddingBottom: '2px',
  overflow: 'hidden',
}));

export const WrapperItem = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
  alignItems: 'center',
  marginLeft: '6px',
  marginRight: '6px',
}));

export const ItemListMenu = styled(MenuItem)<MenuItemProps>(({ theme }) => ({
  height: '40px',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  borderRadius: '21px',
  paddingLeft: '11px',
  paddingTop: '0px',
  paddingBottom: '0px',
  background: theme.palette.background.paper,
  color: theme.palette.secondary.light,
  fontSize: '14px',
  fontWeight: 400,
  letterSpacing: '-0.308px',
  alignItems: 'center',
  '& .Mui-selected': {
    color: theme.palette.primary.main,
  },
}));

export const IconItemListMenu = styled(Box)<IBoxProps>(
  ({ theme, isactive, isselected }) => ({
    position: 'relative',
    width: isselected === 'true' ? '20px' : '17px',
    height: isselected === 'true' ? '20px' : '17px',
    borderRadius: '50%',
    border: '5px solid',
    borderColor: isactive === 'true' ? theme.palette.extra.main : '#878E9C',
    marginRight: '6px',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: '50%',
      top: '50%',
      width: '100%',
      height: '100%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '50%',
      backgroundColor:
        isactive === 'true' ? theme.palette.common.white : '#C8C8C8',
      zIndex: 1,
    },
  })
);

export const SubHeaderList = styled(ListSubheader)<ListSubheaderProps>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '40px',
    width: '100%',
    borderRadius: '21px',
    paddingLeft: '11px',
    background: theme.palette.background.paper,
    color: theme.palette.secondary.main,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21px',
    letterSpacing: '-0.308px',
    cursor: 'pointer',
  })
);
