import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { setError, setLoading } from '../extraReducersHelpers';
import { IGetUser, IGetUsersList } from 'types/organizations.type';
import { ILoadableState } from 'types/store.type';
import { getUsersList } from './usersOperations';

interface IUsersState extends ILoadableState {
  users: IGetUser[] | null;
}

const initialState: IUsersState = {
  users: null,
  loading: false,
  error: null,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUsers: () => initialState,
  },
  extraReducers: builder => {
    builder
      // getUsersList
      .addCase(getUsersList.pending, setLoading)
      .addCase(
        getUsersList.fulfilled,
        (state, { payload }: PayloadAction<IGetUsersList>) => {
          state.loading = false;
          state.users = payload.users;
        }
      )
      .addCase(getUsersList.rejected, setError);
  },
});

export const { resetUsers } = usersSlice.actions;

export default usersSlice.reducer;
