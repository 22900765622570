import { StyledIconBug } from './IconBug.styled';

interface IIconBugProps {
  width?: string;
}

const IconBug = ({ width }: IIconBugProps) => {
  return <StyledIconBug width={width} />;
};

export default IconBug;
