import { createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/api/users';

const getUsersList = createAsyncThunk(
  'users/getUsersList',
  async (idOrganization: number, { rejectWithValue }) => {
    try {
      const result = await api.postGetUsers(idOrganization);
      return result;
    } catch (error: any) {
      console.error('Error: ', error);
      return rejectWithValue(error.message);
    }
  }
);

export { getUsersList };
